export default interface AccountDomain {
    code: string,
    name: string,
    accountNumber: string,
    accountHolder: string,
    bank: string,
    currency: string,
    note: string,
    type: string,
    availableBalance?: number,
    status: string,
    accountOwner: string
    projects: Array<string>|null
    acceptDebitTransaction: boolean
    description:string,
    transactionExpire?:number,
    lastSettlementAt?:string,
}

export const AccountModel: AccountDomain = {
    accountHolder: "",
    accountNumber: "",
    bank: "",
    code: "",
    currency: "",
    name: "",
    note: "",
    type: "",
    availableBalance: 0,
    status: "",
    accountOwner: "",
    projects: null,
    acceptDebitTransaction: true,
    description:'',
}
