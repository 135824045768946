import React, {createRef} from 'react';
import {Alert, Button, Card, Form, InputNumber, Switch} from "antd";
import lodash from "lodash";
import AccountDomain from "../../../Domain/AccountDomain";
import {LogModal} from '../../../Component/LogModal';
import {AccountEvent, LogResrouceType} from '../../../Interface/LogInterface';
import {ListItem} from './LogListItem';
import BankDomain from "../../../Domain/BankDomain";
import BankApi from "../../../Api/BankApi";
import ProjectApi from "../../../Api/ProjectApi";
import ProjectDomain from "../../../Domain/ProjectDomain";
import RoleApi from "../../../Api/RoleApi";
import RoleDomain from "../../../Domain/RoleDomain";
import AccountApi from "../../../Api/AccountApi";
import {NotificationCommon} from "../../../Component/Notification";
import {LogFilterInterface, LogModalRef} from "../../../Component/LogModal/type";
import localStore from "../../../Util/LocalStore";
import moment from "moment";

interface State {
    intervalTime: number,
    configuring: boolean,
    enableSaveConfig: boolean,
    bankList: BankDomain[]
    projectList: ProjectDomain[]
    roleList: RoleDomain[]
    alertVisible:boolean,
}

interface Props {
    account: AccountDomain,
    onChangeDebitTransaction: (enabled: boolean) => void,
    getAccountDetail: () => void,
    loading: boolean,
    showModalUpdate: () => void
}

interface State {
    bankList: BankDomain[]
    projectList: ProjectDomain[]
    roleList: RoleDomain[]
}

class AccountInformation extends React.Component<Props, State> {
    logRef = createRef<LogModalRef>()

    state = {
        intervalTime: 0,
        configuring: false,
        bankList: [],
        projectList: [],
        roleList: [],
        enableSaveConfig: false,
        alertVisible:true,
    }

    fetchBanks = () => {
        BankApi.filter({})
            .then((response: any) => {
                this.setState({
                    bankList: response.data,
                })
            })
    }

    fetchProjects = () => {
        ProjectApi.filter({limit: 100})
            .then(response => {
                this.setState({
                    projectList: response.data,
                })
            })
    }

    fetchRoles = (query: any = {}) => {
        let temp = {
            ...query,
            limit: 1000,
            offset: 0,
        }
        RoleApi.filter(temp)
            .then(response => {
                this.setState({
                    roleList: response.data
                })
            })
    };

    openLogModal = () => {
        const {account} = this.props

        const {bankList, projectList, roleList} = this.state
        if (bankList.length < 1) {
            this.fetchBanks()
        }

        if (projectList.length < 1) {
            this.fetchProjects()
        }

        if (roleList.length < 1) {
            this.fetchRoles()
        }

        this.logRef.current?.setLogQuery({
            account: account.code,
            resourceType: LogResrouceType.ACOUNT,
            resourceCode: account.code,
        })
    }

    handleChangeIntervalInput = (value: any) => {
        this.setState({
            intervalTime: Number(value),
        })
    }

    handleTransactionExpireConfigure = () => {
        const {account, getAccountDetail} = this.props;
        const {intervalTime} = this.state;
        this.setState({configuring: true});
        AccountApi.transactionExpireConfigure(account.code, intervalTime)
            .then(() => {
                NotificationCommon.success({
                    message: 'Cấu hình thành công!',
                })
                getAccountDetail();
            })
            .catch(() => {
                NotificationCommon.error({
                    message: 'Cấu hình thất bại! Vui lòng thử lại',
                })
            })
            .finally(() => this.setState({configuring: false, enableSaveConfig: false}))
    }

    handleCloseSettlementAlert = () => {
        this.setState({alertVisible:false})
    }

    render() {
        const {account, loading, showModalUpdate} = this.props;
        const {intervalTime, bankList, projectList, roleList,alertVisible} = this.state;
        const settlementDuration = lodash.get(localStore.getJson('tenant'),'config.settlementDuration')
        const timeToShowAlert = moment().subtract(settlementDuration + 1,'days') > moment(account.lastSettlementAt)

        return (
            <>
                <Card
                    loading={loading}
                    className={'account-info'}
                    title={
                        <>
                            <span>Chi tiết tài khoản</span>
                            <Switch size={"small"} className={"mg-l-6"} checked={account.status === "ACTIVE"}/>
                        </>
                    }
                    extra={
                        <div>
                            <Button
                                type="link"
                                className="text-blue underline pd-r-6"
                                onClick={this.openLogModal.bind(this)}
                            >
                                <i className="fa-regular fa-file-lines mg-r-4"/> Log
                            </Button>
                            <Button
                                type="link"
                                className="pd-l-6"
                                onClick={showModalUpdate}
                            >
                                <i className="fa-regular fa-gear mg-r-4"/> Chỉnh sửa
                            </Button>
                        </div>
                    }
                >
                    <Form layout={'horizontal'} className={'account-info-form horizontal-form'} labelAlign={'left'}>
                        <Form.Item label={<span>Loại tài khoản</span>}>
                            <div className={'flex space-between'}>
                                <span>{account.type === 'BANK' ? 'Ngân hàng' : 'Tiền mặt'}</span>
                            </div>
                        </Form.Item>
                        <Form.Item label={<span>Tên tài khoản</span>}>
                            <span>{account.name}</span>
                        </Form.Item>
                        <Form.Item label={<span>Đơn vị tiền tệ</span>}>
                            <span>{lodash.get(account.currency, 'code')}</span>
                        </Form.Item>
                        <Form.Item label={<span>Ghi chú</span>}>
                            <span>{account.description || '---'}</span>
                        </Form.Item>
                        <Form.Item label={<span>Tạo giao dịch trừ tiền từ SMS</span>}>
                            <Switch checked={account.acceptDebitTransaction}
                                    onChange={(checked: boolean) => this.props.onChangeDebitTransaction(checked)}
                                    size={'small'}/>
                        </Form.Item>
                        {account.type === 'BANK' && (
                            <>
                                <Form.Item label={<span>Ngân hàng</span>}>
                                    <span>{lodash.get(account.bank, 'name')}</span>
                                </Form.Item>
                                <Form.Item label={<span>Số tài khoản</span>}>
                                    <span>{account.accountNumber}</span>
                                </Form.Item>
                                <Form.Item label={<span>Chủ tài khoản</span>}>
                                    <span>{account.accountHolder}</span>
                                </Form.Item>
                            </>
                        )}
                        <Form.Item label={'Thời gian check trùng bút toán'}>
                            <InputNumber
                                style={{width: 100}}
                                defaultValue={account.transactionExpire}
                                onChange={(e) => {
                                    this.handleChangeIntervalInput(e)
                                }}
                                addonAfter={'ngày'}
                                controls={false}
                                formatter={(value: any) => value!.replace(new RegExp(/\D/g), 0)}
                                parser={(value: any) => value!.replace(new RegExp(/\D/g), 0)}
                                min={1}
                            />
                            <Button
                                className={'txt-blue btn-code-check'}
                                type={'primary'} onClick={this.handleTransactionExpireConfigure}
                                disabled={intervalTime === 0 || account.transactionExpire === intervalTime}
                            >
                                <i className="fa-solid fa-check mg-r-6"/>Lưu
                            </Button>
                        </Form.Item>
                    </Form>
                    {alertVisible && (<div>
                        {timeToShowAlert &&
                        <Alert message={<div className={'font-size-14'}><i className="fa-solid fa-triangle-exclamation text-yellow"/> Hệ thống sẽ tự động chốt sổ vào thời điểm
                            <span className={'mg-l-4'}>{moment().subtract(settlementDuration,'days').format('DD/MM/YYYY')}!</span>
                        </div>}
                               type="warning"
                               className={'mg-bt-4'}
                               closable
                               afterClose={this.handleCloseSettlementAlert}/>
                        }
                    </div>)}
                </Card>

                <LogModal
                    ref={this.logRef}
                    title="Log tài khoản quỹ"
                    ListItemContent={(props) => <ListItem {...props} bankList={bankList} projectList={projectList}
                                                          roleList={roleList} account={account}/>}
                    filters={logFilter}
                    initialFilter={logFilter[0]}
                />
            </>
        );
    }
}

const logFilter: LogFilterInterface[] = [
    {
        activitiesExclude: [AccountEvent.SETTLEMENT],
        title: 'Tất cả'
    },
    {
        activitiesInclude: [AccountEvent.SETTLEMENT],
        title: 'Chốt sổ',
    }]

export default AccountInformation;
