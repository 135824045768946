import {ExclamationCircleOutlined} from "@ant-design/icons"
import {
    AutoComplete,
    Button,
    Card,
    Col,
    DatePicker,
    Form,
    FormInstance,
    Input,
    InputNumber,
    Modal,
    Radio,
    Row,
    Select,
    Spin
} from "antd"
import {AxiosError} from "axios"
import lodash, {get} from "lodash"
import moment from "moment"
import React from "react"
import CustomerApi from "../../../Api/CustomerApi"
import ProjectApi from "../../../Api/ProjectApi"
import ReceiptVoucherApi from "../../../Api/ReceiptVoucher"
import ReceiptVoucherUpdateCommand from "../../../Command/ReceiptVoucherUpdateCommand"
import DefaultLayout from "../../../Component/Layout/Default"
import CustomerDomain from "../../../Domain/CustomerDomain"
import ProjectDomain from "../../../Domain/ProjectDomain"
import ReceiptVoucherDomain, {ReceiptVoucherModel} from "../../../Domain/ReceiptVoucherDomain"
import DefaultPropsInterface from "../../../Interface/DefaultPropsInterface"
import SuggestCustomerQueryCriteria from "../../../QueryCriteria/SuggestCustomerQueryCriteria"
import ReceiptVoucherReadOnly from "./Readonly"
import VoucherBarcode from "./VoucherBarcode"
import VoucherQrCode from "./VoucherQrCode"
import {AppContext} from "../../../Context/AppContext"
import AccountDomain from "../../../Domain/AccountDomain"
import Utils from "../../../Util/Utils"
import {LogResrouceType} from "../../../QueryCriteria/LogQueryCriterial"
import {LogModal} from "../../../Component/LogModal"
import {ListItem} from "./LogListItem"
import {NotificationCommon} from "../../../Component/Notification"
import {REVIEW_STATUSES_CODE} from "../../../Util/Constants";
import {LogModalRef} from "../../../Component/LogModal/type";

interface Props extends DefaultPropsInterface {
    history: any
}

interface State {
    account: AccountDomain
    loading: boolean
    receiptVoucher: any
    update: boolean
    form: ReceiptVoucherUpdateCommand
    projects: Array<ProjectDomain>
    customers: Array<CustomerDomain>
    payerType: string
    project: any
    customerLoading: boolean
    isChange: boolean
}

class ReceiptVoucherDetail extends React.Component<Props, State> {
    static contextType = AppContext
    formRef = React.createRef<FormInstance>()
    logRef = React.createRef<LogModalRef>()

    state: State = {
        account: Utils.getAppContext(this).state.account,
        loading: false,
        receiptVoucher: {...ReceiptVoucherModel},
        update: false,
        form: {ref: "", timestamp: "", memo: "", remark: "", payerRef: "", payer: "", amount: 0, project: ''},
        projects: [],
        customers: [],
        payerType: "system",
        project: undefined,
        customerLoading: false,
        isChange: false,
    }

    componentDidMount() {
        this.fetchReceiptVoucher()
        this.fetchProjects({})
    }

    fetchProjects = (filter = {}) => {
        this.setState({loading: true})
        filter = {
            ...filter,
            offset: 0,
            limit: 1000,
        }
        ProjectApi.filter(filter)
            .then((response) => {
                this.setState({
                    projects: response.data,
                })
            })
            .finally(() => {
                this.setState({loading: false})
            })
    }

    getCode = () => {
        return this.props.match.params.code
    }

    fetchReceiptVoucher = () => {
        this.setState({loading: true})
        ReceiptVoucherApi.getByCode(this.getCode())
            .then((response) => {
                const data = response.data
                const project = data.payerRef ? get(data, "payerRef.project") : data.project ? get(data, 'project') : undefined
                const username = get(data, "payerRef.username")
                this.setState({
                    receiptVoucher: data,
                    form: {
                        ref: data.ref,
                        timestamp: data.timestamp,
                        memo: data.memo,
                        remark: data.remark,
                        payerRef: get(data.payerRef, "username"),
                        payer: data.payer,
                        amount: data.amount,
                    },
                    payerType: project ? "system" : "person",
                    project,
                    isChange: false
                })
                this.formRef.current?.setFieldsValue({memo: data.memo, amount: data.amount})

                if (project && data.payerRef) {
                    this.fetchCustomers({project, query: username})
                }
            })
            .catch((err) => {
                if (get(err, "response.status") === 404) {
                    NotificationCommon.error({message: "Không tìm thấy phiếu thu!"})
                } else {
                    NotificationCommon.error({message: `Lỗi: ${err.message}`})
                }
            })
            .finally(() => {
                this.setState({loading: false})
            })
    }

    onReject = () => {
        this.setState({loading: true})
        ReceiptVoucherApi.reject(this.getCode())
            .then(() => {
                NotificationCommon.success({message: "Hủy phiếu thu thành công!"})
                this.fetchReceiptVoucher()
            })
            .catch(() => {
                NotificationCommon.error({message: "Hủy phiếu thu thất bại!"})
                this.setState({loading: false})
            })
    }

    onApprove = () => {
        this.setState({loading: true})
        ReceiptVoucherApi.approve(this.getCode())
            .then(() => {
                NotificationCommon.success({message: "Xác nhận giao dịch thành công!"})
                this.fetchReceiptVoucher()
            })
            .catch((error: AxiosError) => {
                let message: any = "Xác nhận giao dịch thất bại!"
                if (lodash.get(error.response, "status") === 400) {
                    if (lodash.get(error.response, "data.title") === "ref_exist") {
                        const transactionCode = lodash.get(error.response, "data.transactionCode")
                        message = (
                            <span>
                                Mã bút toán đã tồn tại trong giao dịch <b>{transactionCode}</b>
                            </span>
                        )
                        NotificationCommon.error({
                            message: message,
                        })
                    } else if (get(error.response, "data.title") === "source_exist") {
                        NotificationCommon.error({
                            message: "Không thể xác nhận vì đã tồn tại giao dịch",
                        })
                    } else {
                        NotificationCommon.error({
                            message: message,
                        })
                    }
                } else if (lodash.get(error.response, "status") === 403) {
                    NotificationCommon.error({
                        message: "Bạn không có quyền thực hiện thao tác này",
                    })
                } else {
                    NotificationCommon.error({message})
                }
                this.setState({loading: false})
            })
    }

    onAcceptReview = () => {
        this.setState({ loading: true })
        ReceiptVoucherApi.review(this.getCode()).then(() => {
            NotificationCommon.success({
                message: "Duyệt phiếu thành công!",
            })
            this.fetchReceiptVoucher()
        }).catch((err)=> {
            let message: string;
            let error = err.response.data;
            if (error.title === 'receipt_voucher_not_found') {
                message = 'Phiếu thu không tồn tại!'
            } else if (error.status === 403) {
                message = 'Bạn không có quyền thực hiện thao tác này'
            } else {
                message = error.title
            }
            NotificationCommon.error({
                message
            })
        }).finally(()=> {
            this.setState({loading:false})
        })
    }

    confirmReject = () => {
        Modal.confirm({
            title: "Cảnh báo",
            icon: <ExclamationCircleOutlined/>,
            content: "Bạn chắc chắn muốn hủy phiếu?",
            okText: "Đồng ý",
            cancelText: "Hủy bỏ",
            onOk: this.onReject,
            okButtonProps: {
                icon: <i className="fa-solid fa-check pd-r-8"/>,
            },
            cancelButtonProps: {
                icon: <i className="fa-solid fa-xmark pd-r-8"/>,
                type: "ghost",
            },
        })
    }

    handlePrint = () => {
        window.open(`/receipt-vouchers/${this.getCode()}/print`, "_blank")
    }

    renderAction = () => {
        const receiptVoucher: ReceiptVoucherDomain = this.state.receiptVoucher
        const {isChange, loading, form} = this.state
        const disabled: boolean = get(receiptVoucher, "reviewStatus") !== "PENDING" || loading
        const disableSubmit: boolean = form.amount === undefined || form.amount === "" || !form.memo

        return (
            <Row gutter={12} onLoad={()=><div/>}>
                <Col>
                    {!loading && get(receiptVoucher, "account.type") !== "BANK" && (
                        <Col>
                            <Button type={"link"} onClick={this.handlePrint}>
                                In phiếu
                            </Button>
                        </Col>
                    )}
                </Col>
                <Col>
                    <Button
                        disabled={!isChange || disableSubmit}
                        onClick={this.handleSubmit}
                        icon={<i className="fa-solid fa-pen-to-square mg-r-8"/>}
                    >
                        Cập nhật
                    </Button>
                </Col>
                <Col>
                    <Button
                        onClick={this.onApprove}
                        disabled={get(receiptVoucher,'status.code') !== 'PENDING'}
                        icon={<i className="fa-solid fa-file-check mg-r-8"/>}
                    >
                        Xác nhận giao dịch
                    </Button>
                </Col>
                <Col>
                    <Button
                        onClick={this.onAcceptReview}
                        disabled={disabled || get(receiptVoucher,'status.code') === 'REJECTED'}
                        icon={<i className="fa-regular fa-file-check mg-r-8" />}
                    >
                        Duyệt phiếu
                    </Button>
                </Col>
                <Col>
                    <Button
                        danger
                        onClick={this.confirmReject}
                        disabled={get(receiptVoucher, 'status.code') === 'APPROVED' || get(receiptVoucher,'status.code') === 'REJECTED'}
                        icon={<i className="fa-solid fa-xmark mg-r-8"/>}
                    >
                        Hủy phiếu
                    </Button>
                </Col>
            </Row>
        )
    }

    handleChangeInput = (field: string, e: any) => {
        this.setState({
            form: {
                ...this.state.form,
                [field]: e.target.value,
            },
            isChange: true,
        })
    }

    handleChangeInputNumber = (field: string, value: number) => {
        this.setState({
            form: {
                ...this.state.form,
                [field]: value,
            },
            isChange: true,
        })
    }

    handleChangeSelect = (field: string, value: any) => {
        this.setState({
            form: {
                ...this.state.form,
                [field]: value,
            },
            isChange: true,
        })
    }

    handleChangeDatePicker = (field: string, value: any) => {
        this.setState({
            form: {
                ...this.state.form,
                [field]: value ? value.toISOString() : null,
            },
            isChange: true,
        })
    }

    handleSubmit = () => {
        const {form} = this.state
        this.handleUpdateReceiptVoucher(form)
    }

    handleUpdateReceiptVoucher = (command: ReceiptVoucherUpdateCommand) => {
        const form = {...command}
        const {payerType, receiptVoucher, project} = this.state
        const code = get(receiptVoucher, "code")
        if (payerType === "person") {
            lodash.unset(form, "payerRef")
            lodash.unset(form, 'project')
        } else {
            lodash.unset(form, "payer")
            if (project && form.payerRef) {
                form.payerRef = `c:${project}:${form.payerRef}`
                form.project = project
            } else {
                form.project = project;
            }
        }
        this.setState({loading: true})
        ReceiptVoucherApi.update(code!, form)
            .then(() => {
                NotificationCommon.success({message: "Cập nhật phiếu thành công"})
                this.fetchReceiptVoucher()
            })
            .catch((error: AxiosError) => {
                let message: any = "Có lỗi. Vui lòng kiểm tra lại thông tin trên form"
                if (lodash.get(error.response, "status") === 400) {
                    if (lodash.get(error.response, "data.title") === "ref_exist") {
                        const transactionCode = lodash.get(error.response, "data.transactionCode")
                        message = (
                            <span>
                                Mã bút toán đã tồn tại trong giao dịch <b>{transactionCode}</b>
                            </span>
                        )
                        NotificationCommon.error({message})
                    } else if (lodash.get(error, "response.data.title") === "ref_exist_pending_voucher") {
                        return this.confirmForceUpdateVoucher(form, error)
                    } else if (lodash.get(error.response, "data.title") === "receipt_ineligible") {
                        message = "Không thể cập nhật thông tin phiếu trước thời gian chốt sổ gần nhất"
                        NotificationCommon.error({message})
                    } else {
                        NotificationCommon.error({
                            message: "Lỗi: " + error.message,
                        })
                    }
                } else {
                    NotificationCommon.error({message})
                }
                this.setState({loading: false})
            })
    }

    confirmForceUpdateVoucher = (form: ReceiptVoucherUpdateCommand, error: AxiosError) => {
        const voucherCodes = lodash.get(error.response, "data.voucherCodes")
        const renderVoucherCodes = voucherCodes.map((_code: any, _index: any) => (
            <>
                <b className={_index < lodash.size(voucherCodes) - 1 ? "has-comma" : ""}>{_code}</b>
            </>
        ))
        const message = (
            <span>Mã bút toán đã nằm trong phiếu thu {renderVoucherCodes}. Bạn có muốn tiếp tục cập nhật không?</span>
        )
        Modal.confirm({
            title: message,
            okText: "Xác nhận",
            cancelText: "Hủy bỏ",
            onOk: () => {
                this.handleUpdateReceiptVoucher({
                    ...form,
                    force: true,
                })
            },
            centered: true,
            okButtonProps: {
                icon: <i className="fa-solid fa-check pd-r-8"/>,
            },
            cancelButtonProps: {
                icon: <i className="fa-solid fa-xmark pd-r-8"/>,
                type: "ghost",
            },
        })
    }

    fetchCustomers = (filter: SuggestCustomerQueryCriteria = {}) => {
        this.setState({customerLoading: true})
        CustomerApi.getSuggestCustomers({limit: "300", ...filter})
            .then((response) => {
                this.setState({
                    customers: response.data,
                })
            })
            .finally(() => {
                this.setState({customerLoading: false})
            })
    }

    handleChangeProject = (value: any) => {
        this.setState({
            project: value || undefined,
            form: {...this.state.form, project: value, payerRef: undefined},
            isChange: true,
            customers: [],
        })
    }

    handleSearchStaff = (value: any) => {
        if (this.state.project) {
            if (value && value.toString().length >= 3) {
                this.fetchCustomers({
                    query: value,
                    project: this.state.project,
                })
            } else if (!value) {
                this.fetchCustomers({
                    project: this.state.project,
                    query: "---",
                })
            }
        }
    }

    handleChangePayerType = (e: any) => {
        this.setState({
            payerType: e.target.value,
            isChange: true,
            form: {...this.state.form, payer: '', payerRef: '', project: undefined},
            project: undefined
        })
    }

    openLogModal = () => {
        const {account, receiptVoucher} = this.state

        this.logRef.current?.setLogQuery({
            account: account.code,
            resourceType: LogResrouceType.RECEIPT_VOUCHER,
            resourceCode: receiptVoucher.code,
        })
    }

    render() {
        const receiptVoucher: any = this.state.receiptVoucher
        const {form, projects, customers, payerType, project, loading, account} = this.state
        const accountProjects = account.projects || []
        return (
            <DefaultLayout
                {...this.props}
                title={"Chi tiết phiếu thu"}
                breadcrumb={[
                    {title: "Danh Sách Phiếu Thu", href: "/receipt-vouchers"},
                    {title: `Phiếu thu ${receiptVoucher.code}`},
                ]}
            >
                <Spin spinning={loading}>
                    <div className="main-content payment-details mx-12 my-12">
                        <Row gutter={12}>
                            <Col xs={24} lg={18}>
                                <Card
                                    title={
                                        <span className="capitalize">
                                            <Button
                                                type="text"
                                                onClick={() => this.props.history.goBack()}
                                                className="pd-l-0 pd-r-4"
                                            >
                                                <i className="fa-solid fa-angle-left"/>
                                            </Button>
                                            Thông tin phiếu thu
                                            <Button
                                                type="link"
                                                className="text-blue pd-l-7 underline  mg-l-4"
                                                onClick={this.openLogModal.bind(this)}
                                            >
                                                <i className="fa-regular fa-file-lines dpl-il mg-r-4" />Log
                                            </Button>
                                        </span>
                                    }
                                    className={"mg-bt-20"}
                                    extra={!loading ? this.renderAction() :null}
                                >
                                    {get(receiptVoucher, "status.code") !== 'PENDING' ? (
                                        <ReceiptVoucherReadOnly receiptVoucher={receiptVoucher}/>
                                    ) : (
                                        <Form labelCol={{xs: 6, sm: 8}} labelAlign={"left"} ref={this.formRef} className={'payment-infos horizontal-form'}>
                                            <Form.Item label={"Tài khoản quỹ"}>
                                                <span>
                                                    {get(receiptVoucher, "account.name")} (
                                                    {get(receiptVoucher, "account.code")})
                                                </span>
                                            </Form.Item>

                                            <Form.Item label={"Thời gian tạo"}>
                                                <span>
                                                    {get(receiptVoucher, "preparedAt")
                                                        ? moment(get(receiptVoucher, "preparedAt")).format(
                                                            "DD/MM/YYYY HH:mm:ss"
                                                        )
                                                        : "--"}
                                                </span>
                                            </Form.Item>

                                            <Form.Item label={"Người tạo"}>
                                                <span>
                                                    {get(receiptVoucher, "preparedBy.username")
                                                        ? get(receiptVoucher, "preparedBy.username")
                                                        : "--"}
                                                </span>
                                            </Form.Item>

                                            {get(receiptVoucher,'reviewStatus') === 'REVIEWED' && <>
                                                <Form.Item label={"Thời gian duyệt"}>
                                                <span>
                                                    {get(receiptVoucher, "reviewedAt")
                                                        ? moment(get(receiptVoucher, "reviewedAt")).format(
                                                            "DD/MM/YYYY HH:mm:ss"
                                                        )
                                                        : "--"}
                                                </span>
                                                </Form.Item>

                                                <Form.Item label={"Người duyệt"}>
                                                    <span>
                                                        {get(receiptVoucher, "reviewedBy.username")
                                                            ? get(receiptVoucher, "reviewedBy.username")
                                                            : "--"}
                                                    </span>
                                                </Form.Item>
                                            </>}

                                            <Form.Item label={"Trạng thái phiếu"}>
                                                <span>{receiptVoucher && receiptVoucher.reviewStatus ?
                                                    REVIEW_STATUSES_CODE.find((x) => receiptVoucher.reviewStatus === x.code)?.name : "--"}</span>
                                            </Form.Item>

                                            <Form.Item label={"Mã giao dịch"}>
                                                <span>{get(receiptVoucher, "transaction") || "--"}</span>
                                            </Form.Item>

                                            <Form.Item label={"Người nộp tiền"}>
                                                <div className="mg-bt-10 flex">
                                                    <Radio
                                                        disabled={!accountProjects.length}
                                                        value={"system"}
                                                        checked={payerType === "system"}
                                                        onChange={this.handleChangePayerType}
                                                        className="pd-t-5"
                                                    />

                                                    <div className="flex1 pd-l-16">
                                                        <Row gutter={12}>
                                                            <Col span={12}>
                                                                <Select
                                                                    disabled={
                                                                        !accountProjects.length ||
                                                                        payerType !== "system"
                                                                    }
                                                                    className={"width-100pc"}
                                                                    placeholder={"Hệ thống"}
                                                                    value={loading ? undefined : project}
                                                                    allowClear
                                                                    onChange={this.handleChangeProject}
                                                                >
                                                                    {projects
                                                                        .filter((item) =>
                                                                            accountProjects.includes(item.code)
                                                                        )
                                                                        .map((item: ProjectDomain) => (
                                                                            <Select.Option
                                                                                key={item.code}
                                                                                value={item.code}
                                                                            >
                                                                                {item.name}
                                                                            </Select.Option>
                                                                        ))}
                                                                </Select>
                                                            </Col>

                                                            <Col span={12}>
                                                                <AutoComplete
                                                                    disabled={
                                                                        !accountProjects.length ||
                                                                        payerType !== "system"
                                                                    }
                                                                    className={"width-100pc"}
                                                                    placeholder={"Username"}
                                                                    value={form.payerRef}
                                                                    showSearch={true}
                                                                    filterOption={false}
                                                                    allowClear
                                                                    onChange={this.handleChangeSelect.bind(
                                                                        this,
                                                                        "payerRef"
                                                                    )}
                                                                    onSearch={lodash.debounce(
                                                                        this.handleSearchStaff,
                                                                        500
                                                                    )}
                                                                >
                                                                    {customers.map((item: CustomerDomain) => (
                                                                        <AutoComplete.Option
                                                                            key={item.username}
                                                                            value={item.username}
                                                                        >
                                                                            {item.fullname} ({item.username})
                                                                        </AutoComplete.Option>
                                                                    ))}
                                                                </AutoComplete>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>

                                                <div className="flex">
                                                    <Radio
                                                        value={"person"}
                                                        checked={payerType === "person"}
                                                        onChange={this.handleChangePayerType}
                                                    />

                                                    <div className="flex1 pd-l-16">
                                                        <Input
                                                            disabled={payerType !== "person"}
                                                            placeholder={"Người nộp tiền"}
                                                            value={project ? undefined : form.payer}
                                                            onChange={this.handleChangeInput.bind(this, "payer")}
                                                            onPressEnter={() => this.handleSubmit()}
                                                        />
                                                    </div>
                                                </div>
                                            </Form.Item>

                                            <Form.Item
                                                label={"Số tiền"}
                                                name="amount"
                                                rules={[{required: true, message: "Số tiền không được để trống"}]}
                                            >
                                                <InputNumber
                                                    placeholder={"Vui lòng nhập số tiền"}
                                                    value={form.amount}
                                                    onChange={this.handleChangeInputNumber.bind(this, "amount")}
                                                    formatter={(value) => {
                                                        if (!value) {
                                                            return ''
                                                        }
                                                        return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                    }}
                                                    parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
                                                    className={"width-100pc"}
                                                />
                                            </Form.Item>

                                            <Form.Item
                                                label={"Nội dung giao dịch"}
                                                name="memo"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Nội dung giao dịch không được để trống",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    value={form.memo}
                                                    onChange={this.handleChangeInput.bind(this, "memo")}
                                                    onPressEnter={() => this.handleSubmit()}
                                                    placeholder="Vui lòng nhập nội dung giao dịch"
                                                />
                                            </Form.Item>

                                            <Form.Item label={"Ghi chú"}>
                                                <Input
                                                    value={form.remark}
                                                    onChange={this.handleChangeInput.bind(this, "remark")}
                                                    placeholder="Vui lòng nhập ghi chú"
                                                />
                                            </Form.Item>

                                            <Form.Item label={"Thời gian giao dịch"}>
                                                <DatePicker
                                                    className={"width-100pc"}
                                                    format={"DD/MM/YYYY HH:mm:ss"}
                                                    value={form.timestamp ? moment(form.timestamp) : null}
                                                    onChange={this.handleChangeDatePicker.bind(this, "timestamp")}
                                                    disabledDate={(current) => current && current > moment()}
                                                    showTime
                                                    placeholder="Vui lòng chọn thời gian giao dịch"
                                                />
                                            </Form.Item>

                                            <Form.Item label={"Mã bút toán"}>
                                                <Input
                                                    disabled={get(receiptVoucher, "account.type") !== "BANK"}
                                                    min={0}
                                                    className={"width-100pc"}
                                                    value={form.ref}
                                                    placeholder="Vui lòng nhập mã bút toán"
                                                    onChange={this.handleChangeInput.bind(this, "ref")}
                                                />
                                            </Form.Item>
                                        </Form>
                                    )}
                                </Card>
                            </Col>
                            <Col xs={24} lg={6}>
                                <VoucherQrCode receiptVoucher={receiptVoucher}/>
                                <VoucherBarcode receiptVoucher={receiptVoucher}/>
                                 {/*<ActionLog /> */}
                            </Col>
                        </Row>
                    </div>
                </Spin>

                <LogModal
                    ref={this.logRef}
                    title="Log phiếu thu"
                    ListItemContent={(props) => <ListItem {...props} account={account} />}
                />
            </DefaultLayout>
        )
    }
}

export default ReceiptVoucherDetail
