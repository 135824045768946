import React from "react";
import {Select, SelectProps} from "antd";
import RoleDomain from "../../../Domain/RoleDomain";

interface Props {
    items: Array<RoleDomain>
    value?: any
    onChange?: (value: any) => void
    onSearch?: (value: any) => void
    selectProps?: SelectProps<any>
}

interface State {

}

class RoleSelector extends React.Component<Props, State> {

    handleSearch = (value: any) => {
        if (value && value.toString().length >= 3) {
            this.props.onSearch?.(value);
        }
        else if (!value) {
            this.props.onSearch?.("");
        }
    };

    render() {
        const {onChange, selectProps, value, items} = this.props;
        return (
            <Select
                allowClear
                className={'width-100pc pointer'}
                value={value}
                showSearch={true}
                onChange={onChange}
                onSearch={this.handleSearch}
                placeholder={"Chọn vai trò"}
                optionFilterProp="children"
                filterOption={(input, option) => (option!.children as unknown as string).includes(input)}
                // filterSort={(optionA, optionB) =>
                //     (optionA!.children as unknown as string).toLowerCase().localeCompare((optionB!.children as unknown as string).toLowerCase())
                // }
                {...selectProps}
            >
                {items.map((item: RoleDomain) => (
                    <Select.Option key={item.code} value={item.code}>{item.name}</Select.Option>
                ))}
            </Select>
        );
    }
}

export default RoleSelector;
