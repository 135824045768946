export default interface ReceiptVoucherDomain {
    code: string,
    createdAt: string,
    timestamp: string,
    amount: number,
    memo: string,
    approvedBy: string,
    preparedBy: string,
    payer: string,
    payerRef?: string,
    approvedAt: string,
    transactionAt: string
}

export const ReceiptVoucherModel: ReceiptVoucherDomain = {
    code: "",
    timestamp: "",
    createdAt: "",
    amount: 0,
    memo: "",
    approvedBy: "",
    preparedBy: "",
    payer: "",
    payerRef: "",
    approvedAt: "",
    transactionAt: ""
}