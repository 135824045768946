import {Button, Form, Modal} from "antd"
import TextArea from "antd/lib/input/TextArea"
import lodash from "lodash"
import React, {useState} from "react"
import AccountApi from "../../Api/AccountApi"
import TransactionsDomain from "../../Domain/TransactionDomain"
import {NotificationCommon} from "../Notification"

const {Item} = Form

interface UpdateTransactionProps {
    transaction: TransactionsDomain
    accountCode: string
    onSuccess: () => void
    onFailed?: (error: any) => void
    onVisibleChange: (visible: boolean) => void
}

const ModalEditTransactionNote: React.FC<UpdateTransactionProps> = (props) => {
    const [form] = Form.useForm()

    const [loading, setLoading] = useState(false)

    const handleUpdateTransaction = async () => {
        const {remark} = form.getFieldsValue(true)
        try {
            setLoading(true)
            await AccountApi.updateTransactionRemark(props.accountCode, lodash.get(props.transaction, 'code'), {
                remark
            })
            NotificationCommon.success({message: "Cập nhật thành công!"})
            props.onSuccess()
        } catch (err: any) {
            setLoading(false)
            if (lodash.get(err, 'response.status') === 400) {
                if (lodash.get(err, 'response.data.title') === 'synchonized_M2') {
                    NotificationCommon.error({
                        message: "Giao dịch đã được đồng bộ sang M2 hoặc Legacy"
                    })
                } else if (lodash.get(err, 'response.data.title') === 'transaction_initial') {
                    NotificationCommon.error({
                        message: "Giao dịch khởi tạo. Không cho phép chỉnh sửa"
                    })
                } else {
                    NotificationCommon.error({message: lodash.get(err, 'response.detail') || err.message})
                }
            } else {
                NotificationCommon.error({message: err.message})
            }
            props.onFailed && props.onFailed(err)
        }
    }

    return (
        <Modal
            visible={true}
            title={`Cập nhật ghi chú ${lodash.get(props.transaction, 'code')}`}
            okText="Cập Nhật"
            cancelText={"Hủy Bỏ"}
            closeIcon={<i className="fa-solid fa-xmark"/>}
            onCancel={() => props.onVisibleChange(false)}
            width={660}
            centered
            footer={null}
        >
            <Form
                layout="vertical"
                name="form_edit_transaction"
                initialValues={{remark: lodash.get(props.transaction, 'remark')}}
                form={form}
            >
                <Item label={<span className="font-medium fsz-16px">Ghi chú</span>} name="remark" className="mg-bt-12">
                    <TextArea rows={3} placeholder="Vui lòng nhập ghi chú" autoFocus
                              onKeyDown={(e) => {
                                  if (e.code === "Enter" && !e.shiftKey) {
                                      handleUpdateTransaction().then()
                                      e.preventDefault();
                                  }
                              }}
                    />
                </Item>
                <Item shouldUpdate className="mg-bt-0">
                    {() => (
                        <div className={'width-100pc flex justify-content-flex-end'}>
                            <Button type={'ghost'} className={'pd-14'} disabled={loading}
                                    onClick={() => props.onVisibleChange(false)}>
                                <i className="fa-solid fa-xmark pd-r-8"/> Hủy bỏ
                            </Button>
                            <Button
                                className={'mg-l-12'}
                                type={'primary'} htmlType={'submit'}
                                disabled={
                                    !form.isFieldsTouched(true)
                                }
                                onClick={handleUpdateTransaction}>
                                <i className="fa-solid fa-check pd-r-8"/> Cập nhật
                            </Button>
                        </div>
                    )}
                </Item>
            </Form>
        </Modal>
    )
}

export default ModalEditTransactionNote
