import React from "react";
import {Badge, Card, Divider, Modal, Tooltip} from "antd";
import {CrownFilled, DeleteOutlined} from '@ant-design/icons'
import AccountDomain from "../../../Domain/AccountDomain";
import StaffApi from "../../../Api/StaffApi";
import RoleSelector from "./SelectRole";
import lodash from "lodash";
import AccountApi from "../../../Api/AccountApi";
import {AxiosError} from "axios";
import RoleApi from "../../../Api/RoleApi";
import RoleDomain from "../../../Domain/RoleDomain";
import SecurityService from "../../../Util/SecurityService";
import CommonTable from "../../../Component/CommonTable";
import {NotificationCommon} from "../../../Component/Notification";

interface Props {
    username: string
}

interface State {
    loading: boolean
    accounts: Array<AccountDomain>
    columns: Array<any>
    roles: Array<RoleDomain>
}

class AccountList extends React.Component<Props, State> {

    state: State = {
        loading: false,
        accounts: [],
        roles: [],
        columns: [
            {
                title: 'Tài khoản Quỹ',
                key: 'account',
                width: '40%',
                render: (row: any) => {
                    return (
                        <span>
                            {row.owner && <Tooltip title={'Chủ tài khoản'}>
                                <CrownFilled className={'text-danger'}/></Tooltip>} {lodash.get(row.account, 'name')}
                            <small>({lodash.get(row.account, 'code')})</small>
                        </span>
                    )
                }
            },
            {
                title: 'Vai trò',
                key: 'role',
                width: '40%',
                render: (row: any) => {
                    if (this.props.username !== SecurityService.getUser().username) {
                        const {roles} = this.state;
                        return (
                            <div className={'width100pc'}>
                                <RoleSelector
                                    items={roles}
                                    value={lodash.get(row, 'privilege.role.code')}
                                    onChange={this.handleChangeRole.bind(this, lodash.get(row.account, 'code'), lodash.get(row, 'privilege.id'))}
                                    selectProps={{
                                        allowClear: false,
                                        size: 'small',
                                        disabled: this.props.username === SecurityService.getUser().username
                                    }}
                                />
                            </div>
                        )
                    }
                    return lodash.get(row, 'privilege.role.name') || '--';
                }
            },
            {
                title: <div className={'justify-content-center'}>Thao tác</div>,
                key: 'actions',
                width: '20%',
                render: (row: any) => {
                    if (this.props.username !== SecurityService.getUser().username && !row.owner) {
                        return (
                            <div className={'justify-content-center'}>
                                {lodash.get(row, 'privilege.role.code') ? <Tooltip title={'Xoá'}>
                                        <DeleteOutlined
                                            className={'pointer text-danger'}
                                            onClick={this.handleRemoveRole.bind(this, lodash.get(row.account, 'code'), lodash.get(row, 'privilege.id'))}/>
                                    </Tooltip>
                                    :
                                    <DeleteOutlined style={{cursor: 'default', color: '#C0C0C0'}}/>
                                }
                            </div>
                        )
                    }
                    return '--'
                }
            }
        ]
    }

    componentDidMount() {
        this.fetchRoles();
        this.fetchAccounts();
    }

    fetchAccounts() {
        StaffApi.getAccountsByUsername(this.props.username)
            .then(response => {
                this.setState({accounts: response.data.accounts})
            })
    }

    fetchRoles = (query: any = {}) => {
        let temp = {
            ...query,
            offset: 0, limit: 1000,
        }
        RoleApi.filter(temp)
            .then(response => {
                this.setState({
                    roles: response.data
                })
            })
    };

    handleChangeRole = (accountCode: any, privilegeId: any, roleCode: any) => {
        this.fetchRoles();
        if (privilegeId) {
            this.handleUpdateRole(accountCode, privilegeId, roleCode);
        } else {
            this.handleAddRole(accountCode, roleCode)
        }
    }

    handleUpdateRole = (accountCode: string, privilegeId: string, roleCode: string) => {
        this.setState({loading: true});
        AccountApi.updatePrivilege(accountCode, privilegeId, {role: roleCode, staff: this.props.username})
            .then(() => {
                NotificationCommon.success({
                    message: `Cập nhật thành công`,
                    icon: <i className="fa-solid fa-circle-check"/>,
                });
                this.fetchAccounts();
            })
            .catch((error: AxiosError) => {
                if (lodash.get(error.response, 'status') === 400) {
                    if (lodash.get(error.response, 'data.title') === 'privilege_existed') {
                        NotificationCommon.error({
                            message: 'Nhân viên này đã tồn tại',
                            icon: <i className="fa-solid fa-triangle-exclamation"/>,
                        })
                    } else {
                        NotificationCommon.error({
                            message: 'Cập nhật không thành công',
                            icon: <i className="fa-solid fa-triangle-exclamation"/>,
                        })
                    }
                } else if (lodash.get(error.response, 'status') === 404) {
                    if (lodash.get(error.response, 'data.title') === 'privilege_not_found') {
                        NotificationCommon.error({
                            message: 'Mã vai trò không tồn tại',
                            icon: <i className="fa-solid fa-triangle-exclamation"/>,
                        })
                    }
                } else {
                    NotificationCommon.error({
                        message: 'Cập nhật không thành công',
                        icon: <i className="fa-solid fa-triangle-exclamation"/>,
                    })
                }
            })
            .finally(() => {
                this.setState({loading: false});
            })
    }

    handleAddRole = (accountCode: string, roleCode: string) => {
        this.setState({loading: true});
        AccountApi.addPrivilege(accountCode, {staffs: [this.props.username], role: roleCode})
            .then(() => {
                NotificationCommon.success({
                    message: `Cập nhật thành công`,
                    icon: <i className="fa-solid fa-circle-check"/>,

                });

                this.fetchAccounts();
            })
            .catch((error: AxiosError) => {
                if (lodash.get(error.response, 'status') === 400) {
                    if (lodash.get(error.response, 'data.title') === 'privilege_existed') {
                        NotificationCommon.error({
                            message: 'Vai trò đã tồn tại',
                            icon: <i className="fa-solid fa-triangle-exclamation"/>,
                        })
                    } else {
                        NotificationCommon.error({
                            message: 'Cập nhật thất bại',
                            icon: <i className="fa-solid fa-triangle-exclamation"/>,
                        })
                    }
                } else {
                    NotificationCommon.error({
                        message: 'Cập nhật thất bại',
                        icon: <i className="fa-solid fa-triangle-exclamation"/>,
                    })
                }
            })
            .finally(() => {
                this.setState({loading: false});
            })
    }

    handleRemoveRole = (accountCode: string, privilegeId: string) => {
        Modal.confirm({
            className: 'confirm-delete-role',
            title: <span>Bạn có chắc chắn muốn xoá vai trò này?</span>,
            content: <div><Divider/></div>,
            cancelButtonProps: {type: 'ghost'},
            onOk: () => {
                this.setState({loading: true});
                AccountApi.removePrivilege(accountCode, privilegeId)
                    .then(() => {
                        NotificationCommon.success({
                            message: 'Xoá vai trò thành công',
                            icon: <i className="fa-solid fa-circle-check"/>,
                        });

                        this.fetchAccounts();
                    })
                    .catch(() => {
                        NotificationCommon.error({
                            message: 'Xoá vai trò thất bại',
                            icon: <i className="fa-solid fa-triangle-exclamation"/>,
                        })
                    })
                    .finally(() => {
                        this.setState({loading: false});
                    })
            },
            okText: <span><i className="fa-regular fa-trash mg-r-8"/>Xóa</span>,
            cancelText: <span><i className="fa-solid fa-xmark mg-r-8"/>Hủy</span>
        })
    }

    render() {
        const {columns, accounts, loading} = this.state;
        return (<>
            <Card
                title={<div>
                    <span className={'capitalize mg-r-5'}>{`Danh sách tài khoản quỹ`}</span>
                    <Badge count={accounts.length} overflowCount={99}/>
                </div>}>
                <CommonTable
                    loading={loading}
                    columns={columns}
                    dataSource={lodash.sortBy(accounts, ['account.name','account.code'])}
                    rowKey={'id'}
                    pagination={false}
                    scroll={{y: window.innerHeight - 200}}
                    rowClassName={'account-record'}
                />
            </Card>
        </>);
    }
}

export default AccountList;
