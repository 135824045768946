import {Form, Input, Modal} from 'antd';
import {AxiosError} from "axios";
import {omitBy} from 'lodash';
import React from 'react';
import RoleApi from "../../Api/RoleApi";
import RoleCreateCommand from "../../Command/RoleCreateCommand";
import { NotificationCommon } from '../../Component/Notification';

interface State {
    form: RoleCreateCommand,
    loading: boolean
}

interface Props {
    onVisibleChange: (visible: boolean) => void,
    fetchRoles: () => void
}

class RoleCreate extends React.Component<Props, State> {

    state: State = {
        form: {
            name: "",
            code: ""
        },
        loading: false
    }

    handleChangeInput = (field: string, e: any) => {
        this.setState({
            form: {
                ...this.state.form,
                [field]: e.target.value
            }
        })
    };

    handleSubmit = () => {
        this.setState({loading: true});
        const data = omitBy(this.state.form, (value: any) => (value === "" || value === undefined || value === null));
        RoleApi.create(data)
            .then((response) => {
                this.handleCreateSuccess();
            })
            .catch((error: AxiosError) => {
                this.handleCreateFail(error);
            })
            .finally(() => {
                this.setState({loading: false});
            })
    }

    handleCreateSuccess = () => {
        NotificationCommon.success({
            message: "Tạo vai trò thành công",
            icon: <i className="fa-solid fa-circle-check"/>,
        })
        this.props.onVisibleChange(false);
        this.props.fetchRoles();
    }

    handleCreateFail = (error: AxiosError) => {
        if (error.response?.status === 400) {
            if (error.response.data.title === 'role_exist') {
                NotificationCommon.error({
                    message: "Vai trò đã tồn tại"
                })
            }
            else {
                NotificationCommon.error({
                    message: "Tạo vai trò không thành công"
                })
            }
        }
        else {
            NotificationCommon.error({
                message: "Tạo vai trò không thành công. Lỗi: " + error.message
            })
        }
    }

    handleEnableSubmit = () => {
        let validatedForm = this.state.form;
        if(validatedForm) {
            if((validatedForm.name !== '' || null || undefined) && (validatedForm.code !== '' || null || undefined)) return true;
            else return false;
        }
        else return false;
    }

    render() {
        const {form, loading} = this.state;
        return (
            <Modal
                className={''}
                visible={true}
                title={'Tạo vai trò'}
                onOk={this.handleSubmit}
                onCancel={() => this.props.onVisibleChange(false)}
                okButtonProps={{
                    loading: loading,
                    disabled: !this.handleEnableSubmit(),
                    tabIndex: 2,
                    icon: <i className="fa-solid fa-check mg-r-8"/>
                }}
                cancelButtonProps={{
                    type:"ghost",
                    disabled: loading,
                    tabIndex: 3,
                    icon: <i className="fa-solid mg-r-8 fa-xmark"/>
                }}
                okText="Tạo vai trò"
                cancelText="Bỏ qua"
				centered
                closeIcon={<i className="fa-solid fa-xmark" />}
                >
                <Form layout={'horizontal'} labelCol={{xs:24,lg:4}} labelAlign={'left'}>
                    <Form.Item
                        label="Mã vai trò"
                        name="code"
                        rules={[{required: true, message: 'Mã vai trò không được để trống'}]}
                    >
                        <Input
                            autoFocus={true}
                            tabIndex={1}
                            className={'width-100pc'}
                            value={form.code}
                            disabled={loading}
                            onChange={this.handleChangeInput.bind(this, 'code')}
                            onPressEnter={() => this.handleSubmit()}
                            placeholder={'Vui lòng nhập mã vai trò'}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Tên vai trò"
                        name="name"
                        rules={[{required: true, message: 'Tên vai trò không được để trống'}]}
                        className="mg-bt-0"
                    >
                        <Input
                            tabIndex={1}
                            className={'width-100pc'}
                            value={form.name}
                            disabled={loading}
                            onChange={this.handleChangeInput.bind(this, 'name')}
                            onPressEnter={() => this.handleSubmit()}
                            placeholder={'Vui lòng nhập tên vai trò'}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

export default RoleCreate;
