import React from 'react';
import AccountApi from "../../../Api/AccountApi";
import {AccountModel} from "../../../Domain/AccountDomain";
import DefaultLayout from "../../../Component/Layout/Default";
import {Col, Row} from "antd";
import AccountInformation from './AccountInformation';
import AccountUser from "./AccountUser";
import AccountOwner from "./AccountOwner";
import AccountBoxStatistic from "../../../Component/AccountBoxStatistic";
import {ConfigWebhook} from "./ConfigWebhook";
import {Project} from "./Project";
import {events} from "../../../Util/Event";
import {ACCOUNT_EVENT} from "../../../Util/Constants";
import {NotificationCommon} from '../../../Component/Notification';
import Update from "../Update";

class AccountDetail extends React.Component<any, any> {

    state = {
        loading: false,
        isShowModalUpdate: false,
        account: {...AccountModel}
    }

    componentDidMount() {
        this.fetchAccountDetail();
        this.eventListeners()
    }

    handleModalUpdateVisibleChange = (visible: boolean) => {
        this.setState({
            isShowModalUpdate: visible
        });
    }

    onUpdateSuccess = () => {
        this.fetchAccountDetail()
        this.handleModalUpdateVisibleChange(false);
    }

    fetchAccountDetail = () => {
        this.setState({loading: true});
        AccountApi.findByCode(this.getCode())
            .then(response => {
                this.setState({account: response.data})
            })
            .catch(() => {
                NotificationCommon.error({
                    message: 'Không thể tải được thông tin chi tiết tài khoản'
                })
            })
            .finally(() => {
                this.setState({loading: false});
            })
    }

    eventListeners = () => {
        events.on(ACCOUNT_EVENT.ADD_PROJECT, () => {
            this.fetchAccountDetail()
        })
        events.on(ACCOUNT_EVENT.REMOVE_PROJECT, () => {
            this.fetchAccountDetail()
        })
    }

    getCode = () => {
        return this.props.match.params.code;
    }

    handleChangeDebitTransaction = (enabled: boolean) => {
        this.setState({loading: true})
        AccountApi.debitTransactionConfigure(this.getCode(), {enabled})
            .then(() => {
                NotificationCommon.success({
                    message: "Cấu hình thành công"
                })
                this.fetchAccountDetail()
            })
            .catch(() => {
                NotificationCommon.error({
                    message: "Cấu hình thất bại"
                })
            })
            .finally(() => this.setState({loading: false}))
    }

    render() {
        const {account, loading, isShowModalUpdate} = this.state;
        return (
            <DefaultLayout
                {...this.props}
                title={`Chi tiết tài khoản ${account.name} - ${account.code}`}
                breadcrumb={[{title:'Danh sách tài khoản',href:'/accounts'},{title:`Tài khoản ${account.name} - ${account.code}`}]}
            >
                <div className="main-content">
                    <div className="white-box px-12 py-12">
                        <AccountBoxStatistic
                            account={account}
                        />
                    </div>
                    <div className="mg-t-12 mg-l-12 mg-r-12 mg-bt-12">
                        <Row gutter={12}>
                            <Col xs={24} lg={15}>
                                <AccountInformation
                                    loading={loading}
                                    account={account}
                                    onChangeDebitTransaction={this.handleChangeDebitTransaction}
                                    getAccountDetail={this.fetchAccountDetail}
                                    showModalUpdate={() => this.handleModalUpdateVisibleChange(true)}
                                />
                            </Col>
                            <Col xs={24} lg={9}>
                                <div className="mg-bt-12">
                                    <AccountUser accountCode={this.getCode()} account={account} />
                                </div>

                                <div className="mg-bt-12">
                                    <AccountOwner account={account} accountCode={this.getCode()} />
                                </div>
                                {account.type === 'BANK' && (
                                    <div className="mg-bt-12">
                                        <ConfigWebhook accountCode={this.getCode()} />
                                    </div>
                                )}
                                <div className="mg-bt-12">
                                    <Project account={account} />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>

                {isShowModalUpdate && (
                    <Update
                        code={account.code}
                        onUpdateSuccess={this.onUpdateSuccess}
                        onVisibleChange={this.handleModalUpdateVisibleChange}
                    />
                )}
            </DefaultLayout>
        );
    }
}

export default AccountDetail;
