import {Modal, ModalFuncProps} from "antd"
import React from "react";

export class ModalCommon {
    static confirm({
                       okText = 'Xác nhận',
                       cancelText = 'Hủy bỏ',
                       okButtonProps,
                       cancelButtonProps,
                       ...props
                   }: ModalFuncProps) {
        Modal.confirm({
            okText,
            cancelText,
            okButtonProps: {
                tabIndex: 2,
                icon: <i className="fa-solid fa-check mg-r-8"/>,
                ...okButtonProps
            },
            cancelButtonProps: {
                tabIndex: 3,
                icon: <i className="fa-solid mg-r-8 fa-xmark"/>,
                type: 'ghost',
                ...cancelButtonProps
            },
            ...props
        })
    }
}
