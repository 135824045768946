import {Badge, Button, Modal} from "antd"
import {AxiosError, AxiosResponse} from "axios"
import lodash, {get} from "lodash"
import React, {createRef, RefObject} from "react"
import AccountApi from "../../../Api/AccountApi"
import PaymentVoucherApi from "../../../Api/PaymentVoucherApi"
import ReceiptVoucherApi from "../../../Api/ReceiptVoucher"
import PaymentVoucherCreateCommand from "../../../Command/PaymentVoucherCreateCommand"
import ReceiptVoucherCreateCommand from "../../../Command/ReceiptVoucherCreateCommand"
import DefaultComponent from "../../../Component/DefaultComponent"
import DefaultLayout from "../../../Component/Layout/Default"
import ModalEditTransaction from "../../../Component/ModalEditTransaction"
import ModalPaymentVoucherCreate from "../../../Component/ModalPaymentVoucherCreate"
import ModalReceiptVoucherCreate from "../../../Component/ModalReceiptVoucherCreate"
import {AppContext} from "../../../Context/AppContext"
import AccountDomain from "../../../Domain/AccountDomain"
import TransactionsDomain, {TransactionDomainDefault} from "../../../Domain/TransactionDomain"
import Utils from "../../../Util/Utils"
import DataList from "./DataList"
import FilterForm from "./FilterForm"
import AccountBoxStatisticConsumer from "../../../Consumer/AccountBoxStatisticConsumer";
import ModalEditTransactionNote from "../../../Component/ModalEditTransactionNote";
import ProjectDomain from "../../../Domain/ProjectDomain";
import ProjectApi from "../../../Api/ProjectApi";
import { NotificationCommon } from "../../../Component/Notification"
import SubTransactionCreate from "../../../Component/SubTransactionCreate";

import './style.scss';
import {DraggableModalRef} from "../../../Component/DraggableModal";

interface State {
	account: AccountDomain
	loading: boolean
	transactions: Array<TransactionsDomain>
	total: number
	pageSize: number
	currentPage: number
	showCreateReceiptVoucherModal: boolean
	showCreatePaymentVoucherModal: boolean
	visibleEditTransactionModal: boolean
	visibleEditTransactionNoteModal: boolean
	visibleCreateSubTransactionModal:boolean
	currentTransactionEdit: TransactionsDomain
	projects: Array<ProjectDomain>
}

interface Props {}

class TransactionsList extends DefaultComponent<Props, State> {
	static contextType = AppContext
	createPaymentVoucherRef: RefObject<DraggableModalRef> = createRef()
	createReceiptVoucherRef: RefObject<DraggableModalRef> = createRef()

	state: State = {
		account: Utils.getAppContext(this).state.account,
		loading: false,
		transactions: [],
		pageSize: 25,
		total: 0,
		currentPage: 1,
		showCreatePaymentVoucherModal: false,
		showCreateReceiptVoucherModal: false,
		visibleEditTransactionModal: false,
		visibleEditTransactionNoteModal: false,
		visibleCreateSubTransactionModal: false,
		currentTransactionEdit: TransactionDomainDefault,
		projects: []
	}

	componentDidMount() {
		this.fetchTransactions({
			...this.getQueryFromLocation(),
		})

		this.fetchProjects()
	}

	componentDidUpdate(prevProps: any) {
		if (
			JSON.stringify(this.props.location.search) !== JSON.stringify(prevProps.location.search) ||
			JSON.stringify(this.props.match.params) !== JSON.stringify(prevProps.match.params)
		) {
			this.fetchTransactions({
				...this.getQueryFromLocation(),
			})
		}
	}

	fetchProjects = () => {
		ProjectApi.filter({offset: 0, limit: 1000})
			.then(response => {
				this.setState({ projects: response.data} )
			})
	}

	fetchTransactions = (filter: any = {}) => {
		const { account } = this.state
		if (account.code) {
			this.setState({ loading: true })
			filter = {
				offset: 0,
				limit: this.state.pageSize,
				sort: "timestamp:desc",
				...filter,
			}
			AccountApi.getTransactions(lodash.get(account, "code"), filter)
				.then((response: any) => {
					this.setState({
						transactions: response.data,
						currentPage: parseInt(lodash.get(response, "headers.x-page-number")) + 1,
						pageSize: parseInt(lodash.get(response, "headers.x-page-size")),
						total: parseInt(lodash.get(response, "headers.x-total-count")),
					})
				})
				.finally(() => {
					this.setState({ loading: false })
				})
		}
	}

	onChangePage = (page: number, pageSize: any) => {
		this.pushCleanQueryToHistory({
			...this.getQueryFromLocation(),
			page: page,
			limit: pageSize,
			offset: (page - 1) * pageSize,
		})
	}

	handleExport = () => {
		const { account } = this.state
		this.setState({ loading: true })
		AccountApi.exportTransactions(account.code, {
			sort: 'timestamp:desc',
			fileType: 'excel',
			timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
			onlyParent:false,
			...this.getQueryFromLocation(),
		})
			.then((response: any) => {
				if (response.data.url) {
					window.open(response.data.url, "_blank")
				} else {
					NotificationCommon.error({
						message: "Không tìm thấy link download",
					})
				}
			})
			.catch((error: any) => {
				NotificationCommon.error({
					message: error.message,
				})
			})
			.finally(() => {
				this.setState({ loading: false })
			})
	}

	handleRetrySynchronize = (transactionCode: string) => {
		const {account} = this.state
		this.setState({loading: true})
		AccountApi.retrySynchronizeTransaction(account.code, transactionCode)
			.then((response: AxiosResponse) => {
				if (response.status === 204) {
					NotificationCommon.success({
						message: "Giao dịch đã được đồng bộ lại",
					})
					this.fetchTransactions({
						...this.getQueryFromLocation(),
					})
				} else {
					NotificationCommon.success({
						message: "Giao dịch đang được xử lý, vui lòng chờ vài phút sau xem lại",
					})
				}
			})
			.catch((error: AxiosError) => {
				if (error.response && error.response.status === 400) {
					if (get(error.response, "data.title") === "retry_not_allowed") {
						NotificationCommon.error({
							message: "Không cho phép đồng bộ lại",
						})
					} else if (get(error.response, "data.title") === "retry_synchronize_failed") {
						NotificationCommon.error({
							message: "Đồng bộ thất bại",
						})
					} else {
						NotificationCommon.error({
							message: "Có lỗi xảy ra: " + error.message,
						})
					}
				} else {
					NotificationCommon.error({
						message: error.message,
					})
				}
			})
			.finally(() => {
				this.setState({loading: false})
			})
	}

	handleModalCreatePaymentVoucherVisibleChange = (visible: boolean) => {
		if (this.createPaymentVoucherRef.current && visible){
			this.createPaymentVoucherRef.current?.setVisible(true)
			return
		}

		this.setState({ showCreatePaymentVoucherModal: visible, showCreateReceiptVoucherModal: false })
	}

	handleModalCreateReceiptVoucherVisibleChange = (visible: boolean) => {
		if (this.createReceiptVoucherRef.current && visible){
			this.createReceiptVoucherRef.current?.setVisible(true)
			return
		}

		this.setState({ showCreateReceiptVoucherModal: visible, showCreatePaymentVoucherModal: false })
	}

	handleModalEditTransactionVisibleChange = (visible: boolean) => {
		this.setState({ visibleEditTransactionModal: visible })
	}

	handleModalEditTransactionNoteVisibleChange = (visible: boolean) => {
		this.setState({ visibleEditTransactionNoteModal: visible})
	}

	handleModalCreateSubTransactionVisibleChange = (visible: boolean) => {
		this.setState({ visibleCreateSubTransactionModal: visible})
	}

	handleCurrentTransactionEdit = (record: TransactionsDomain) => {
		this.setState({ currentTransactionEdit: record })
	}

	handleSubmitCreatePaymentVoucher = (form: PaymentVoucherCreateCommand) => {
		const { account } = this.state
		this.setState({ loading: true })
		PaymentVoucherApi.create(form)
			.then((response) => {
				NotificationCommon.success({
					message: "Tạo phiếu chi thành công",
				})
				Utils.getAppContext(this).func.fetchAccountAndUpdateState(account.code)
				this.setState({ showCreatePaymentVoucherModal: false })
				window.open(`/payment-vouchers/${response.data.code}`)
			})
			.catch((error: AxiosError) => {
				if (lodash.get(error, "response.status") === 400) {
					if (lodash.get(error, "response.data.title") === "payment_ineligible") {
						NotificationCommon.error({
							message: "Thời gian giao dịch không hợp lệ do đã chốt sổ",
						})
					} else if (lodash.get(error, "response.data.title") === "ref_exist") {
						NotificationCommon.error({
							message: `Mã bút toán đã tồn tại trong giao dịch ${lodash.get(
								error.response,
								"data.transactionCode"
							)}`,
						})
					} else if (lodash.get(error, "response.data.title") === "ref_exist_pending_voucher") {
						this.confirmForceCreatePaymentVoucher(form, error)
					} else {
						NotificationCommon.error({
							message: "Có lỗi. Vui lòng kiểm tra lại thông tin trên form",
						})
					}
				} else {
					NotificationCommon.error({
						message: "Lỗi: " + error.message,
					})
				}
			})
			.finally(() => {
				this.setState({ loading: false })
			})
	}

	confirmForceCreatePaymentVoucher = (form: PaymentVoucherCreateCommand, error: AxiosError) => {
		const voucherCodes = lodash.get(error.response, "data.voucherCodes")
		Modal.confirm({
			title: `Mã bút toán đã nằm trong phiếu chi ${voucherCodes.join(",")}. Bạn có muốn tiếp tục tạo phiếu không?`,
			okText: "Xác nhận",
			cancelText: "Bỏ qua",
			onOk: () => {
				this.handleSubmitCreatePaymentVoucher({
					...form,
					force: true,
				})
			},
		})
	}

	handleSubmitCreateReceiptVoucher = (form: ReceiptVoucherCreateCommand) => {
		const { account } = this.state
		this.setState({ loading: true })
		ReceiptVoucherApi.create(form)
			.then((response) => {
				NotificationCommon.success({
					message: "Tạo phiếu thu thành công",
				})
				Utils.getAppContext(this).func.fetchAccountAndUpdateState(account.code)
				this.setState({ showCreateReceiptVoucherModal: false })
				window.open(`/receipt-vouchers/${response.data.code}`)
			})
			.catch((error: AxiosError) => {
				if (lodash.get(error, "response.status") === 400) {
					if (lodash.get(error, "response.data.title") === "receipt_ineligible") {
						NotificationCommon.error({
							message: "Thời gian giao dịch không hợp lệ do đã chốt sổ",
						})
					} else if (lodash.get(error, "response.data.title") === "ref_exist") {
						NotificationCommon.error({
							message: `Mã bút toán đã tồn tại trong giao dịch ${lodash.get(
								error.response,
								"data.transactionCode"
							)}`,
						})
					} else if (lodash.get(error, "response.data.title") === "ref_exist_pending_voucher") {
						this.confirmForceCreateReceiptVoucher(form, error)
					} else {
						NotificationCommon.error({
							message: "Có lỗi. Vui lòng kiểm tra lại thông tin trên form",
						})
					}
				}
			})
			.finally(() => {
				this.setState({ loading: false })
			})
	}

	confirmForceCreateReceiptVoucher = (form: ReceiptVoucherCreateCommand, error: AxiosError) => {
		const voucherCodes = lodash.get(error.response, "data.voucherCodes")
		Modal.confirm({
			title: `Mã bút toán đã nằm trong phiếu thu ${voucherCodes.join(",")}. Bạn có muốn tiếp tục tạo phiếu không?`,
			okText: "Xác nhận",
			cancelText: "Bỏ qua",
			onOk: () => {
				this.handleSubmitCreateReceiptVoucher({
					...form,
					force: true,
				})
			},
		})
	}

	handleSuccessUpdateTransaction = () => {
		this.pushCleanQueryToHistory({...this.getQueryFromLocation(), t: Math.floor(Math.random() * 1000).toString()})
	}

	updateAccount = () => {
		const {account} = this.state;
		setTimeout(() => {
			Utils.getAppContext(this).func.fetchAccountAndUpdateState(account.code);
		}, 2000)
		this.pushCleanQueryToHistory({
			...this.getQueryFromLocation(),
			t: Date.now()
		})
	}

	handleReviewTransaction = (row:TransactionsDomain) => {
		const {account} = this.state;
		this.setState({loading:true})
		AccountApi.reviewTransaction(account.code,row.code).then(()=> {
			NotificationCommon.success({
				message:'Duyệt phiếu thành công'
			})
			this.updateAccount()
		}).then(()=> {
			this.fetchTransactions({...this.getQueryFromLocation()});
		}).catch((err) => {
			let message:string = 'Duyệt phiếu thất bại'
			if(err.response.data) {
				if (err.response.data.status === 403) {
					message = 'Bạn không có quyền thực hiện thao tác này'
				} else {
					message = err.response.data.title;
				}
			}
			NotificationCommon.error({
				message
			})
		}).finally(()=> {
			this.setState({loading:false})
		})
	}

	handleMarkRechargeTransaction = (row:TransactionsDomain) => {
		const {account} = this.state;
		AccountApi.markRechargedTransaction(account.code,row.code).then(() => {
			NotificationCommon.success({
				message:'Đánh dấu giao dịch đã nạp thành công'
			})
		}).then(()=> {
			this.fetchTransactions({...this.getQueryFromLocation()});
		}).catch((err) => {
			let message:string = 'Đánh dấu giao dịch đã nạp thất bại'
			if(err.response.data) {
				if (err.response.data.status === 403) {
					message = 'Bạn không có quyền thực hiện thao tác này'
				} else {
					message = err.response.data.title;
				}
			}
			NotificationCommon.error({
				message
			})
		})
	}

	render() {
		const {
			transactions,
			currentPage,
			pageSize,
			total,
			loading,
			account,
			showCreatePaymentVoucherModal,
			showCreateReceiptVoucherModal,
			currentTransactionEdit,
			projects
		} = this.state
		return (
			<DefaultLayout
				{...this.props}
				title={`${account.name} - Lịch sử giao dịch`}
				breadcrumb={[
					{title: 'Danh sách lịch sử giao dịch'},
					{
						title:<div className={'only-show-from-lg'}>
								<span>
									Tài khoản quỹ: <span><a style={{color:'#148F14',fontWeight:500}} href={`/accounts/${account.code}`} target={'_blank'} rel={'noreferrer'}>{account.name} ({account.code})</a></span>
								</span>
								{account.type === 'BANK' && (<span className={'mg-l-4 txt-maxW-100'}>- <span style={{color:'#148F14',fontWeight:500}}>{lodash.get(account, 'bank.name')}</span></span>)}
						</div>,
						jsxPassing:true
					}
				]}
			>
				<div className="main-content transaction-history-page">
					<div className="white-box pd-t-12 pd-bt-12 pd-l-12 pd-r-12">
						<AccountBoxStatisticConsumer />
					</div>

					<div className="white-box">
							<FilterForm {...this.props} />
					</div>

					<div className="white-box pd-12 ">
						<div className="table-container">
						<div className="table-header space-between mg-bt-12">
							<div className="align-center font-medium fsz-16px capitalize">
									Danh sách lịch sử giao dịch <Badge count={total} showZero overflowCount={999999} className="pd-l-8" />
							</div>

							<div>
								<Button
									disabled={!account.code}
									className={"mg-r-10"}
									onClick={() => this.handleModalCreateReceiptVoucherVisibleChange(true)}
								>
									<i className="fa-regular fa-memo pd-r-10" /> Tạo phiếu thu
								</Button>

								<Button
									disabled={!account.code}
									className={"mg-r-10"}
									onClick={() => this.handleModalCreatePaymentVoucherVisibleChange(true)}
								>
									<i className="fa-regular fa-memo pd-r-10" /> Tạo phiếu chi
								</Button>

								<Button
									disabled={!account.code}
									type="primary"
									icon={<i className="fa-regular fa-file-export pd-r-10" />}
									onClick={this.handleExport}
								>
									Xuất file
								</Button>
							</div>
						</div>

						<div className="clearfix" />
							<DataList
								projects={projects}
								loading={loading}
								items={transactions}
								total={total}
								pageSize={pageSize}
								currentPage={currentPage}
								onChangePage={this.onChangePage}
								onRetrySynchronize={this.handleRetrySynchronize}
								handleCurrentTransactionEdit={this.handleCurrentTransactionEdit}
								handleModalEditTransactionVisibleChange={this.handleModalEditTransactionVisibleChange}
								handleModalEditTransactionNoteVisibleChange={this.handleModalEditTransactionNoteVisibleChange}
								handleReviewTransaction={this.handleReviewTransaction}
								handleModalCreateSubTransactionVisibleChange={this.handleModalCreateSubTransactionVisibleChange}
								handleMarkRechargeTransaction={this.handleMarkRechargeTransaction}
								fetchTransactions={this.fetchTransactions}
							/>
						</div>
					</div>
				</div>

				{showCreatePaymentVoucherModal && (
					<ModalPaymentVoucherCreate
						modalRef={this.createPaymentVoucherRef}
						loading={loading}
						onVisibleChange={this.handleModalCreatePaymentVoucherVisibleChange}
						onSubmit={this.handleSubmitCreatePaymentVoucher}
					/>
				)}

				{showCreateReceiptVoucherModal && (
					<ModalReceiptVoucherCreate
						modalRef={this.createReceiptVoucherRef}
						loading={loading}
						onVisibleChange={this.handleModalCreateReceiptVoucherVisibleChange}
						onSubmit={this.handleSubmitCreateReceiptVoucher}
					/>
				)}

				{this.state.visibleEditTransactionModal && (
					<ModalEditTransaction
						visible={true}
						onVisibleChange={this.handleModalEditTransactionVisibleChange}
						transaction={currentTransactionEdit}
						accountProjects={account.projects || []}
						accountCode={account.code}
						onSuccess={this.handleSuccessUpdateTransaction}
					/>
				)}

				{this.state.visibleEditTransactionNoteModal && (
					<ModalEditTransactionNote
						transaction={currentTransactionEdit}
						accountCode={account.code}
						onSuccess={() => {
							this.handleSuccessUpdateTransaction()
							this.handleModalEditTransactionNoteVisibleChange(false)
						}}
						onFailed={() => {}}
						onVisibleChange={this.handleModalEditTransactionNoteVisibleChange}
					/>
				)}

				{this.state.visibleCreateSubTransactionModal && (
					<SubTransactionCreate visible={true}
										  rootTransaction={currentTransactionEdit}
										  handleModalCreateSubTransactionVisibleChange={this.handleModalCreateSubTransactionVisibleChange}
										  fetchTransactions={this.fetchTransactions}
					/>
				)}
			</DefaultLayout>
		)
	}
}

export default TransactionsList
