import {FC} from "react";
import {Button, Dropdown, Menu} from "antd";
import {Link} from "react-router-dom";
import localStore from "../../../Util/LocalStore";
import AccountDomain from "../../../Domain/AccountDomain";

interface LinkDropdownProps {
    account: AccountDomain
}

const LinkDropdown: FC<LinkDropdownProps> = ({account}) => {
    const goPage = (page: string) => {
        localStore.setJson('account', account);
        window.location.href = page;
    }

    return (
        <Dropdown overlay={() => (
            <Menu>
                <Menu.Item key={"link-1"} onClick={() => goPage('transactions')}>
                    <Link to={'/transactions'}>
                        <i className="fa-solid fa-money-check-pen"/>
                        Đến lịch sử giao dịch
                    </Link>
                </Menu.Item>

                <Menu.Item key={"link-2"} onClick={() => goPage('pending-vouchers')}>
                    <Link to={'/pending-vouchers'}>
                        <i className="fa-solid fa-memo-circle-info"/>
                        Đến phiếu chờ giao dịch
                    </Link>
                </Menu.Item>

                <Menu.Item key={"link-3"} onClick={() => goPage('payment-vouchers')}>
                    <Link to={'/payment-vouchers'}>
                        <i className="fa-solid fa-receipt"></i> Đến Phiếu Chi
                    </Link>
                </Menu.Item>

                <Menu.Item key={"link-4"} onClick={() => goPage('receipt-vouchers')}>
                    <Link to={'/receipt-vouchers'}>
                        <i className="fa-solid fa-scroll"></i> Đến phiếu thu
                    </Link>
                </Menu.Item>
            </Menu>
        )} placement="bottomRight">
            <Button type={"text"}><i className="fa-solid fa-ellipsis-vertical"></i></Button>
        </Dropdown>
    )
}

export {LinkDropdown}