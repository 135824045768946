import React from 'react';
import {Avatar, Button, Card, Spin} from "antd";
import lodash from "lodash";
import {AxiosError, AxiosResponse} from "axios";
import AccountDomain from "../../../Domain/AccountDomain";
import AccountApi from "../../../Api/AccountApi";
import AddOwnerModal from "./AddOwnerModal";
import SecurityService from "../../../Util/SecurityService";
import md5 from "md5";
import {NotificationCommon} from '../../../Component/Notification';
import {ModalCommon} from "../../../Component/ModalCommon";

interface Props {
    account: AccountDomain,
    accountCode: string,
}

interface State {
    showAddOwnerModal: boolean,
    loading: boolean,
    owners: []
}

class AccountOwner extends React.Component<Props, State> {

    state: State = {
        showAddOwnerModal: false,
        loading: false,
        owners: []
    }

    componentDidMount() {
        this.fetchOwners();
    }

    fetchOwners = () => {
        const {accountCode} = this.props;
        this.setState({loading: true});
        AccountApi.getOwners(accountCode)
            .then((response: AxiosResponse) => {
                this.setState({owners: response.data});
            })
            .finally(() => {
                this.setState({loading: false});
            })
    }

    handleAddOwnerModalVisibleChange = (visible: boolean) => {
        this.setState({
            showAddOwnerModal: visible
        })
    }

    handleRemoveOwner = (owner: any) => {
        const {loading} = this.state;
        ModalCommon.confirm({
            title: <span>Bạn có chắc chắn muốn huỷ quyền chủ sở hữu của <b>{lodash.get(owner, 'owner.username')}</b>?</span>,
            okText: 'Đồng ý',
            cancelText: 'Hủy',
            okButtonProps: {
                loading: loading,
                disabled: loading,
            },
            cancelButtonProps: {
                loading: loading,
                disabled: loading,
            },
            onOk: () => {
                const {account} = this.props;
                this.setState({loading: true});
                AccountApi.removeOwner(account.code, owner.id)
                    .then(() => {
                        NotificationCommon.success({
                            message: 'Huỷ chủ sở hữu thành công'
                        });

                        this.fetchOwners();
                    })
                    .catch((error: AxiosError) => {
                        if (lodash.get(error.response, 'data.title') === 'unable_self_delete') {
                            NotificationCommon.error({
                                message: 'Không thể tự bỏ chủ sở hữu chính mình'
                            })
                        }
                        else {
                            NotificationCommon.error({
                                message: 'Huỷ chủ sở hữu không thành công'
                            })
                        }
                    })
                    .finally(() => {
                        this.setState({loading: false});
                    })
            }
        })
    }

    renderAvatar = (user: any) => {
        const hash = user.email ? md5(user.email) : md5(user.username);
        return `https://www.gravatar.com/avatar/${hash}?d=robohash`;
    }

    render() {
        const {account} = this.props;
        const {loading, owners, showAddOwnerModal} = this.state;
        const loggedUser = SecurityService.getUser();
        return (
            <Card
                className={'account-info'}
                title={`Chủ sở hữu`}
                extra={<Button type={'link'} size={'small'} onClick={() => this.handleAddOwnerModalVisibleChange(true)} className={'font-size-12'}>
                    <span><i className="fa-solid fa-plus mg-r-6"/></span>
                    <span>Thêm chủ sở hữu</span>
                </Button>}
            >
                <Spin spinning={loading}>
                    <div className="account-owners">
                    {owners.map((owner: any) => (
                        <div key={lodash.uniqueId('owner_')} className={'account-owner-item user-item flex'}>
                            <div>
                                <Avatar className={'account-owner-avatar'} src={lodash.get(owner, 'owner.avatar') || this.renderAvatar(lodash.get(owner, 'owner'))}
                                        alt={lodash.get(owner, 'owner.username')} shape={'square'} size={40}/>
                                <span className={'account-owner-name'}>{lodash.get(owner, 'owner.name')} ({lodash.get(owner, 'owner.username')})</span>
                            </div>
                            {loggedUser.username !== owner.owner.username && (
                                    <span onClick={this.handleRemoveOwner.bind(this, owner)} className={'delete-btn flr'}>
                                        Xóa
                                    </span>
                            )}
                        </div>
                    ))}
                    </div>
                </Spin>

                <AddOwnerModal
                    visible={showAddOwnerModal}
                    account={account}
                    fetchAccountOwners={this.fetchOwners}
                    onVisibleChange={this.handleAddOwnerModalVisibleChange}
                />
            </Card>
        )
    }
}

export default AccountOwner;
