import React, {useEffect, useState} from "react";
import {AutoComplete, Col, Row, Select, SelectProps, Tooltip} from "antd";
import ProjectDomain from "../../Domain/ProjectDomain";
import CustomerDomain from "../../Domain/CustomerDomain";
import ProjectApi from "../../Api/ProjectApi";
import CustomerApi from "../../Api/CustomerApi";
import lodash from "lodash";

interface Props {
    onChangeCustomer?: (value: any) => void,
    onCustomerPressEnter?: () => void,
    onChangeProject?: (value: any) => void,
    selectProps?: SelectProps<any>,
    disabled?: boolean,
    valueCustomer?: string | undefined,
    valueProject?: string | undefined,
}

const SelectCustomer: React.FC<Props> = ({
                                             disabled,
                                             valueCustomer,
                                             valueProject,
                                             onChangeCustomer,
                                             onCustomerPressEnter,
                                             onChangeProject
                                         }) => {
    const [projects, setProjects] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [search, setSearch] = useState<any>(undefined);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true)
        const filter = {
            offset: 0,
            limit: 1000
        }
        ProjectApi.filter(filter)
            .then(response => {
                setLoading(false)
                setProjects(response.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    useEffect(() => {
        if (valueCustomer) {
            const [, , username] = valueCustomer.split(':')
            setSearch(username ? username : "")
        }

    }, [valueCustomer])

    useEffect(() => {
        if (valueProject) {
            if (search && search.length >= 3) {
                setLoading(true)
                CustomerApi.getSuggestCustomers({project: valueProject, query: search, limit: "300"})
                    .then(response => {
                        setLoading(false)
                        setCustomers(response.data)
                    })
                    .catch((err) => {
                        if(err.response) {
                            console.log(err.response)
                        }
                        setCustomers([])
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            } else {
                setCustomers([])
            }
        }
    }, [valueProject, search])

    const handleSearchStaff = (value: any) => {
        if (value && value.toString().length >= 3) {
            setSearch(value)
        } else if (!value) {
            setSearch(undefined)
        }
    }

    return <Row gutter={4}>
        <Col span={12}>
            <Select
                allowClear
                disabled={disabled || loading}
                className={'width-100pc'}
                placeholder={"Hệ thống"}
                value={valueProject}
                onChange={onChangeProject}
            >
                {projects.map((item: ProjectDomain) => (
                    <Select.Option key={item.code} value={item.code}>{item.name}</Select.Option>
                ))}
            </Select>
        </Col>
        <Col span={12}>
            <AutoComplete
                allowClear
                disabled={!valueProject}
                className={'width-100pc'}
                placeholder={"Tìm kiếm theo Username/id"}
                filterOption={false}
                value={valueCustomer}
                showSearch={true}
                onChange={onChangeCustomer}
                onSearch={lodash.debounce(handleSearchStaff, 1000)}
                onKeyDown={e => {
                    if (e.key === 'Enter' && typeof onCustomerPressEnter === 'function') {
                        onCustomerPressEnter()
                    }
                }}
            >
                {customers.map((item: CustomerDomain) => (
                    <AutoComplete.Option key={item.username}
                                         value={item.username}
                                         className={''}
                    >
                        <Tooltip title={item.username} placement={'top'}><span className={'txt-maxW-100'}>{item.fullname} ({item.username})</span></Tooltip>
                    </AutoComplete.Option>
                ))}
            </AutoComplete>
        </Col>
    </Row>
}
export default SelectCustomer
