import React, {Component} from 'react';
import {Route, Switch} from "react-router";
import {BrowserRouter} from "react-router-dom";
import Home from "./Screen/Home";
import AuthenticateRoute from "./Component/AuthenticateRoute";
import Authentication from "./Screen/Authentication";
import AccountCreate from './Screen/Account/Create';
import Accounts from './Screen/Account';
import ReceiptVoucherList from "./Screen/ReceiptVoucher/List";
import PaymentVoucherList from "./Screen/PaymentVoucher/List";
import Login from "./Screen/Login";
import Logout from "./Screen/Logout";
import PaymentVoucherDetail from "./Screen/PaymentVoucher/Detail";
import ReceiptVoucherDetail from "./Screen/ReceiptVoucher/Detail";
import TransactionList from "./Screen/Transaction/List";
import ReceiptVoucherPrint from "./Screen/ReceiptVoucher/Print";
import PaymentVoucherPrint from './Screen/PaymentVoucher/Print';
import Notfound from "./Screen/Notfound";
import DeviceList from "./Screen/Devices";
import MessageList from "./Screen/Message";
import RoleList from "./Screen/Role";
import PermissionList from "./Screen/Permission";
import AccountDetail from "./Screen/Account/Detail";
import PendingVoucher from "./Screen/PendingVoucher";
import {PERMISSIONS} from "./Util/Constants";
import StaffList from "./Screen/Staff/List";
import StaffDetail from "./Screen/Staff/Detail/StaffDetail";
import {PaymentReasonList} from "./Screen/PaymentReason";
import MemoList from "./Screen/Memo";

export default class Routes extends Component {
    render() {
        return (
            <BrowserRouter basename={"/"}>
                <Switch>
                    <AuthenticateRoute path="/" exact={true} component={Home}/>
                    <Route path={'/login'} exact={true} component={Login}/>
                    <Route path={'/logout'} exact={true} component={Logout}/>
                    <Route path={'/authentication'} exact={true} component={Authentication}/>
                    <AuthenticateRoute permission={PERMISSIONS.ACCOUNT_CREATE} path="/accounts/create" exact={true} component={AccountCreate}/>
                    <AuthenticateRoute path="/accounts" exact={true} component={Accounts}/>
                    <AuthenticateRoute path="/accounts/:code" exact={true} component={AccountDetail}/>
                    <AuthenticateRoute path="/pending-vouchers" exact={true} component={PendingVoucher}/>
                    <AuthenticateRoute path="/receipt-vouchers" exact={true} component={ReceiptVoucherList}/>
                    <AuthenticateRoute path="/payment-vouchers" exact={true} component={PaymentVoucherList}/>
                    <AuthenticateRoute path="/receipt-vouchers/:code" exact={true} component={ReceiptVoucherDetail}/>
                    <AuthenticateRoute path="/receipt-vouchers/:code/print" exact={true}
                                       component={ReceiptVoucherPrint}/>
                    <AuthenticateRoute path="/payment-vouchers/:code" exact={true} component={PaymentVoucherDetail}/>
                    <AuthenticateRoute path="/payment-vouchers/:code/print" exact={true}
                                       component={PaymentVoucherPrint}/>
                    <AuthenticateRoute path="/transactions" exact={true} component={TransactionList}/>
                    <AuthenticateRoute permission={PERMISSIONS.DEVICE_VIEW} path="/devices" exact={true} component={DeviceList}/>
                    <AuthenticateRoute path="/messages" exact={true} component={MessageList}/>
                    <AuthenticateRoute path="/memos" exact={true} component={MemoList}/>
                    <AuthenticateRoute path="/staffs" exact={true} component={StaffList}/>
                    <AuthenticateRoute path="/staffs/:username" exact={true} component={StaffDetail} />
                    <AuthenticateRoute path="/payment-reasons" exact={true} component={PaymentReasonList} />
                    <AuthenticateRoute permission={PERMISSIONS.ROLE_VIEW} path="/security/roles" exact={true} component={RoleList}/>
                    <AuthenticateRoute permission={PERMISSIONS.ROLE_PERMISSION_VIEW} path="/security/permissions" exact={true} component={PermissionList}/>
                    <Route path={'*'} component={Notfound}/>
                </Switch>
            </BrowserRouter>
        )
    }
}
