import React, {RefObject} from "react"
import moment from "moment"
import { AutoComplete, Col, DatePicker, Form, Input, InputNumber, Radio, Row, Select } from "antd"
import ReceiptVoucherCreateCommand from "../../Command/ReceiptVoucherCreateCommand"
import ProjectDomain from "../../Domain/ProjectDomain"
import CustomerDomain from "../../Domain/CustomerDomain"
import lodash from "lodash"
import ProjectApi from "../../Api/ProjectApi"
import SuggestCustomerQueryCriteria from "../../QueryCriteria/SuggestCustomerQueryCriteria"
import CustomerApi from "../../Api/CustomerApi"
import Utils from "../../Util/Utils"
import { AppContext } from "../../Context/AppContext"
import AccountDomain from "../../Domain/AccountDomain"
import {DraggableModal, DraggableModalRef} from "../DraggableModal"

interface Props {
    modalRef?: RefObject<DraggableModalRef>
    loading?: boolean
    onVisibleChange: (visible: boolean) => void
    onSubmit: (form: ReceiptVoucherCreateCommand) => void
}

interface State {
    account: AccountDomain
    loading: boolean
    payerType: string
    form: ReceiptVoucherCreateCommand
    projects: Array<ProjectDomain>
    customers: Array<CustomerDomain>
    project: any
}
const maxHeight: number = (window.innerHeight / 100) * 85
const innerHeight: number = maxHeight > 515 ? 515 : maxHeight

class ModalReceiptVoucherCreate extends React.Component<Props, State> {
    static contextType = AppContext

    state: State = {
        account: Utils.getAppContext(this).state.account,
        loading: false,
        payerType: "system",
        form: {
            timestamp: moment().toISOString(),
            account: lodash.get(Utils.getAppContext(this).state.account, "code"),
			project:'',
        },
        projects: [],
        customers: [],
        project: undefined,
    }

    static getDerivedStateFromProps(props: any, state: any) {
        const newState = { ...state }
        if ("loading" in props) {
            newState.loading = props.loading
        }
        return newState
    }

    componentDidMount() {
        this.fetchProjects()
    }

    fetchProjects = (filter = {}) => {
        this.setState({ loading: true })
        filter = {
            ...filter,
            offset: 0,
            limit: 1000,
        }
        ProjectApi.filter(filter)
            .then((response) => {
                this.setState({
                    projects: response.data,
                })
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    fetchCustomers = (filter: SuggestCustomerQueryCriteria = {}) => {
        this.setState({ loading: true })
        CustomerApi.getSuggestCustomers({ limit: "300", ...filter })
            .then((response) => {
                this.setState({
                    customers: response.data,
                })
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    handleChangeInput = (field: string, e: any) => {
        this.setState({
            form: {
                ...this.state.form,
                [field]: e.target.value,
            },
        })
    }

    handleChangeNumber = (field: string, value: any) => {
        this.setState({
            form: {
                ...this.state.form,
                [field]: value,
            },
        })
    }

    handleChangeSelect = (field: string, value: any) => {
        this.setState({
            form: {
                ...this.state.form,
                [field]: value,
            },
        })
    }

    handleChangeDatePicker = (field: string, value: any) => {
        this.setState({
            form: {
                ...this.state.form,
                [field]: value ? value.toISOString() : null,
            },
        })
    }

    handleChangePayerType = (e: any) => {
        this.setState({
            payerType: e.target.value,
        })
    }

    handleChangeProject = (value: any) => {
        this.setState({
            project: value,
        })

		const { form } = this.state
		form.payerRef = ""
		this.setState({
			form: { ...form,project:value },
			customers: [],
		})
	}

    handleSearchStaff = (value: any) => {
        if (value && value.toString().length >= 3) {
            this.fetchCustomers({
                query: value,
                project: this.state.project,
            })
        }
    }

    handleSubmit = () => {
        const { form, payerType, customers, project } = this.state
        const data = { ...form }
        if (payerType === "person") {
            lodash.unset(data, "payerRef")
        } else {
            lodash.unset(data, "payer")
            const customer = customers.find((c) => c.username === data.payerRef)
            if (project && data.payerRef) {
                data.payerRef = `c:${project}:${data.payerRef}`
            }
            if (customer) {
                data.payer = `${customer.fullname} (${customer.project})`
            }
        }

        this.props.onSubmit(data)
    }

    handleCancel = () => {
        this.props.onVisibleChange(false)
    }

    render(): React.ReactNode {
        const form: ReceiptVoucherCreateCommand = this.state.form
        const { loading, payerType, customers, projects, project, account } = this.state
        const currency = lodash.get(account, "currency.code")
        const accountType = lodash.get(account, "type")
        const accountProject = account.projects || []

		return (
            <DraggableModal
                ref={this.props.modalRef}
                title="Tạo phiếu thu"
                onOk={this.handleSubmit}
                initialHeight={innerHeight}
                okText={"Tạo phiếu"}
                cancelText={"Hủy Bỏ"}
                okButtonProps={{
                    tabIndex: 11,
                    loading: loading,
                    disabled: loading || form.amount === undefined || form.amount === null  || !form.memo,
                    icon: <i className="fa-solid fa-check pd-r-8" />,
                }}
                cancelButtonProps={{
                    tabIndex: 12,
                    icon: <i className="fa-solid fa-xmark pd-r-8" />,
                    type: "ghost",
                    onClick: this.handleCancel
                }}
                handleCancel={this.handleCancel}
            >
                <Form labelCol={{ span: 6 }} labelAlign="left">
                    <div className={"mg-bt-12 font-medium fsz-16px capitalize"}>Thông tin giao dịch</div>
                    <Form.Item label={"Mã bút toán"}>
                        <Input
                            disabled={accountType === "CASH" || loading}
                            tabIndex={1}
                            autoFocus={true}
                            min={0}
                            className={"width-100pc"}
                            value={form.ref}
                            onChange={this.handleChangeInput.bind(this, "ref")}
                            onPressEnter={() => this.handleSubmit()}
                            placeholder="Vui lòng nhập mã bút toán"
                        />
                    </Form.Item>

                    <Form.Item
                        label={`Số tiền (${lodash.get(account, "currency.code")})`}
                        name="amount"
                        rules={[{ required: true, message: "Số tiền không được để trống" }]}
                    >
                        <InputNumber
                            tabIndex={2}
                            min={0}
                            className={"width-100pc"}
                            formatter={(value) => {
								if (!value) {
									return ''
								}

                                return currency === "VND"
                                    ? Utils.currencyFormat(value, "0,0")
                                    : `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }}
                            parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
                            value={form.amount}
                            disabled={loading}
                            onChange={this.handleChangeNumber.bind(this, "amount")}
                            onPressEnter={() => this.handleSubmit()}
                            placeholder={`Vui lòng nhập số tiền (${lodash.get(account, "currency.code")})`}
                        />
                    </Form.Item>

                    <Form.Item label={"Thời gian giao dịch"}>
                        <DatePicker
                            tabIndex={3}
                            className={"width-100pc"}
                            disabled={loading}
                            format={"DD/MM/YYYY HH:mm:ss"}
                            value={form.timestamp ? moment(form.timestamp) : null}
                            onChange={this.handleChangeDatePicker.bind(this, "timestamp")}
                            disabledDate={(current) => current && current > moment()}
                            placeholder="Vui lòng chọn thời gian giao dịch"
                            showTime
                        />
                    </Form.Item>

                    <Form.Item
                        label={"Nội dung giao dịch"}
                        name="memo"
                        rules={[{ required: true, message: "Nội dung giao dịch không được để trống" }]}
                    >
                        <Input
                            tabIndex={4}
                            value={form.memo}
                            disabled={loading}
                            placeholder="Vui lòng nhập nội dung giao dịch"
                            onChange={this.handleChangeInput.bind(this, "memo")}
                            onPressEnter={() => this.handleSubmit()}
                        />
                    </Form.Item>

                    <Form.Item label={"Ghi chú"}>
                        <Input.TextArea
                            tabIndex={5}
                            disabled={loading}
                            value={form.remark}
                            placeholder="Vui lòng nhập ghi chú"
                            onChange={this.handleChangeInput.bind(this, "remark")}
                        />
                    </Form.Item>

                    <div className={"mg-bt-12 font-medium fsz-16px capitalize"}>Người nộp tiền</div>

                    <Row gutter={12} className={"mg-bt-10"}>
                        <Col span={2}>
                            <Radio
                                disabled={!accountProject.length}
                                tabIndex={6}
                                value={"system"}
                                checked={payerType === "system"}
                                className="pd-l-8"
                                onChange={this.handleChangePayerType}
                            />
                        </Col>

                        <Col span={11}>
                            <Select
                                tabIndex={7}
                                allowClear
                                disabled={!accountProject.length || payerType !== "system"}
                                className={"width-100pc"}
                                placeholder={"Vui lòng chọn hệ thống"}
                                value={project}
                                onChange={this.handleChangeProject}
                            >
                                {projects
                                    .filter((item) => accountProject.includes(item.code))
                                    .map((item: ProjectDomain) => (
                                        <Select.Option key={item.code} value={item.code}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </Col>

                        <Col span={11}>
                            <AutoComplete
                                tabIndex={8}
                                allowClear
                                disabled={!accountProject.length || payerType !== "system"}
                                className={"width-100pc"}
                                placeholder={"Vui lòng nhập Username/id"}
                                filterOption={false}
                                value={form.payerRef}
                                showSearch={true}
                                onChange={this.handleChangeSelect.bind(this, "payerRef")}
                                onSearch={lodash.debounce(this.handleSearchStaff, 1000)}
                            >
                                {customers.map((item: CustomerDomain) => (
                                    <AutoComplete.Option key={item.username} value={item.username}>
                                        {item.fullname} ({item.username})
                                    </AutoComplete.Option>
                                ))}
                            </AutoComplete>
                        </Col>
                    </Row>

                    <Row gutter={12}>
                        <Col span={2}>
                            <Radio
                                tabIndex={9}
                                value={"person"}
                                checked={payerType === "person"}
                                className="pd-l-8"
                                onChange={this.handleChangePayerType}
                            />
                        </Col>
                        <Col span={22}>
                            <Input
                                tabIndex={10}
                                disabled={payerType !== "person" || loading}
                                placeholder={"Vui lòng nhập người nộp tiền"}
                                value={form.payer}
                                onChange={this.handleChangeInput.bind(this, "payer")}
                                onPressEnter={() => this.handleSubmit()}
                            />
                        </Col>
                    </Row>
                </Form>
            </DraggableModal>
        )
    }
}

export default ModalReceiptVoucherCreate
