import React, {RefObject} from "react"
import moment from "moment"
import lodash from "lodash"
import { AutoComplete, Col, DatePicker, Form, Input, InputNumber, Modal, Radio, Row, Select, Upload } from "antd"
import ProjectApi from "../../Api/ProjectApi"
import PaymentVoucherCreateCommand from "../../Command/PaymentVoucherCreateCommand"
import ProjectDomain from "../../Domain/ProjectDomain"
import CustomerDomain from "../../Domain/CustomerDomain"
import SuggestCustomerQueryCriteria from "../../QueryCriteria/SuggestCustomerQueryCriteria"
import CustomerApi from "../../Api/CustomerApi"
import Utils from "../../Util/Utils"
import { AppContext } from "../../Context/AppContext"
import SelectPaymentReason from "../SelectPaymentReason"
import AccountDomain from "../../Domain/AccountDomain"
import { RcFile, UploadFile } from "antd/lib/upload/interface"
import InvoiceApi from "../../Api/InvoiceApi"
import AccountApi from "../../Api/AccountApi"
import {DraggableModal, DraggableModalRef} from "../DraggableModal"

interface Props {
    modalRef?: RefObject<DraggableModalRef>
    loading?: boolean
    onVisibleChange: (visible: boolean) => void
    onSubmit: (data: PaymentVoucherCreateCommand) => void
}

interface State {
    loading: boolean
    receiverType: string
    project: any
    form: PaymentVoucherCreateCommand
    projects: Array<ProjectDomain>
    customers: Array<CustomerDomain>
    account: AccountDomain
    fileList: Array<UploadFile>
    previewImage: string
    previewTitle: string
    previewVisible: boolean
    uploadedAttachments: any
    countWarning: number
    visible: boolean
}
const maxHeight: number = (window.innerHeight / 100) * 85
const innerHeight: number = maxHeight > 752 ? 752 : maxHeight

class ModalPaymentVoucherCreate extends React.Component<Props, State> {
    static contextType = AppContext

    state: State = {
        account: this.context.state.account,
        loading: false,
        receiverType: "system",
        project: undefined,
        form: {
            timestamp: moment().toISOString(),
            account: lodash.get(this.context.state.account, "code"),
            paymentReason: "",
            invoice: "",
			project:'',
        },
        projects: [],
        customers: [],
        fileList: [],
        previewImage: "",
        previewTitle: "",
        previewVisible: false,
        uploadedAttachments: {},
        countWarning: 0,
        visible: true
    }

    static getDerivedStateFromProps(props: any, state: any) {
        const newState = { ...state }
        if ("loading" in props) {
            newState.loading = props.loading
        }
        return newState
    }

    componentDidMount() {
        this.fetchProjects()
    }

    fetchProjects = (filter = {}) => {
        this.setState({ loading: true })
        filter = {
            ...filter,
            offset: 0,
            limit: 1000,
        }
        ProjectApi.filter(filter)
            .then((response) => {
                this.setState({
                    projects: response.data,
                })
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    fetchCustomers = (filter: SuggestCustomerQueryCriteria = {}) => {
        this.setState({ loading: true })
        CustomerApi.getSuggestCustomers({ limit: "300", ...filter })
            .then((response) => {
                this.setState({
                    customers: response.data,
                })
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    handleChangeInput = (field: string, e: any) => {
        this.setState({
            form: {
                ...this.state.form,
                [field]: e.target.value,
            },
        })
    }

    handleChangeNumber = (field: string, value: any) => {
        this.setState({
            form: {
                ...this.state.form,
                [field]: value,
            },
        })
    }

    handleChangeSelect = (field: string, value: any) => {
        this.setState({
            form: {
                ...this.state.form,
                [field]: value,
            },
        })
    }

    handleChangeDatePicker = (field: string, value: any) => {
        this.setState({
            form: {
                ...this.state.form,
                [field]: value ? value.toISOString() : null,
            },
        })
    }

    handleChangeReceiverType = (e: any) => {
        this.setState({
            receiverType: e.target.value,
        })
    }

    handleChangeProject = (value: any) => {
        this.setState({
            project: value,
        })

        const { form } = this.state
        form.receiverRef = ""
		this.setState({
			form: { ...form,project:value },
			customers: [],
		})
    }

    handleSearchStaff = (value: any) => {
        if (this.state.project) {
            if (value && value.toString().length >= 3) {
                this.fetchCustomers({
                    query: value,
                    project: this.state.project,
                })
            }
        }
    }

    handleSubmit = () => {
        const { form, receiverType, uploadedAttachments, account, customers, project } = this.state
        const data = { ...form }

        if (receiverType === "person") {
            lodash.unset(data, "receiverRef")
        } else {
            lodash.unset(data, "receiver")
            const customer = customers.find((c) => c.username === data.receiverRef)
            if (project && data.receiverRef) {
                data.receiverRef = `c:${project}:${data.receiverRef}`
            }
            if (customer) {
                data.receiver = `${customer.fullname} (${customer.project})`
            }
        }

        if (!data.paymentReason) {
            data.paymentReason = "other"
        }

        if (Object.keys(uploadedAttachments).length) {
            form.invoiceAttachments = lodash.map(uploadedAttachments, (value) => value)
        }

        if (data.paymentReason && data.invoice) {
            AccountApi.getPaymentVoucherWarnings(account.code, data.paymentReason, data.invoice).then((response) => {
                this.setState({ countWarning: response.data.length }, () => {
                    Modal.confirm({
                        title: (
                            <div>
                                Đã có {this.state.countWarning} lần chi cho hóa đơn{" "}
                                <a
                                    target={"_blank"}
                                    href={`/payment-vouchers?invoice=${form.invoice}`}
                                    rel={"noreferrer"}
                                >
                                    {data.invoice}
                                </a>
                                , bạn vui lòng kiểm tra kỹ trước khi tạo phiếu
                            </div>
                        ),
                        centered: true,
                        okText: "Xác nhận",
                        cancelText: "Hủy bỏ",
                        onOk: () => {
                            this.setState({ countWarning: 0 })
                            this.props.onSubmit(data)
                        },
                        onCancel: () => {
                            this.setState({ countWarning: 0 })
                        },
                        okButtonProps: {
                            icon: <i className="fa-solid fa-check pd-r-8" />,
                        },
                        cancelButtonProps: {
                            icon: <i className="fa-solid fa-xmark pd-r-8" />,
                            type: "ghost",
                        },
                    })
                })
            })
        } else {
            this.props.onSubmit(data)
        }
    }

    handleCancel = () => {
        this.props.onVisibleChange(false)
    }

    handlePreviewImage = async (file: any) => {
        if (!file.url && !file.preview) {
            file.preview = await Utils.getBase64(file.originFileObj)
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
            previewTitle: file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
        })
    }

    handleUploadChange = (info: any) => {
        this.setState({
            fileList: info.fileList,
        })
        return false
    }

    handleCancelPreview = () => {
        this.setState({
            previewVisible: false,
            previewTitle: "",
            previewImage: "",
        })
    }

    handleBeforeUpload = (file: RcFile) => {
        const { uploadedAttachments } = this.state
        this.setState({ loading: true })
        InvoiceApi.attachment([file]).then((response) => {
            uploadedAttachments[file.uid] = response.data[0]
            this.setState({ uploadedAttachments })
        })
        return false
    }

    handleRemoveFile = (file: UploadFile) => {
        const { uploadedAttachments } = this.state
        lodash.unset(uploadedAttachments, file.uid)
        this.setState({ uploadedAttachments })
    }

    render(): React.ReactNode {
        const form: PaymentVoucherCreateCommand = this.state.form
        const {
            loading,
            receiverType,
            projects,
            customers,
            project,
            account,
            fileList,
            previewVisible,
            previewTitle,
            previewImage,
        } = this.state
        const currency = lodash.get(account, "currency.code")
        const accountType = lodash.get(account, "type")
        const accountProjects = account.projects || []
        return (
            <DraggableModal
                ref={this.props.modalRef}
                title="Tạo phiếu chi"
                onOk={this.handleSubmit}
                okText={"Tạo phiếu"}
                initialHeight={innerHeight}
                cancelText={"Hủy Bỏ"}
                okButtonProps={{
                    tabIndex: 11,
                    disabled: loading || form.amount === undefined || form.amount === null  || !form.memo,
                    icon: <i className="fa-solid fa-check pd-r-8" />,
                }}
                cancelButtonProps={{
                    tabIndex: 12,
                    disabled: loading,
                    icon: <i className="fa-solid fa-xmark pd-r-8" />,
                    type: "ghost",
                    onClick: this.handleCancel
                }}
                handleCancel={this.handleCancel}
            >
                <Form labelCol={{ span: 6 }} labelAlign="left" autoComplete={'on'}>
                    <div className={"mg-bt-12 font-medium fsz-16px capitalize"}>Thông tin giao dịch</div>

                    <Form.Item label={"Mã bút toán"}>
                        <Input
                            disabled={accountType === "CASH" || loading}
                            autoFocus={true}
                            tabIndex={1}
                            min={0}
                            className={"width-100pc"}
                            placeholder="Vui lòng nhập mã bút toán"
                            value={form.ref}
                            onChange={this.handleChangeInput.bind(this, "ref")}
                            onPressEnter={() => this.handleSubmit()}
                        />
                    </Form.Item>

                    <Form.Item
                        label={`Số tiền (${currency})`}
                        name="amount"
                        rules={[{ required: true, message: "Số tiền không được để trống" }]}
                    >
                        <InputNumber
                            tabIndex={2}
                            min={0}
                            className={"width-100pc"}
                            formatter={(value) => {
                                if (!value) {
                                    return ""
                                }

                                return currency === "VND"
                                    ? Utils.currencyFormat(value, "0,0")
                                    : `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }}
                            parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
                            value={form.amount}
                            disabled={loading}
                            placeholder={`Vui lòng nhập số tiền (${currency})`}
                            onChange={this.handleChangeNumber.bind(this, "amount")}
                            onPressEnter={() => this.handleSubmit()}
                        />
                    </Form.Item>

                    <Form.Item label={"Thời gian giao dịch"}>
                        <DatePicker
                            tabIndex={3}
                            className={"width-100pc"}
                            format={"DD/MM/YYYY HH:mm:ss"}
                            value={form.timestamp ? moment(form.timestamp) : null}
                            onChange={this.handleChangeDatePicker.bind(this, "timestamp")}
                            disabledDate={(current) => current && current > moment()}
                            showTime
                            placeholder="Vui lòng chọn thời gian giao dịch"
                        />
                    </Form.Item>

                    <Form.Item
                        label={"Nội dung giao dịch"}
                        name="memo"
                        rules={[{ required: true, message: "Nội dung giao dịch không được để trống" }]}
                    >
                        <Input
                            tabIndex={4}
                            value={form.memo}
                            disabled={loading}
                            onChange={this.handleChangeInput.bind(this, "memo")}
                            onPressEnter={() => this.handleSubmit()}
                            placeholder="Vui lòng nhập nội dung giao dịch"
                        />
                    </Form.Item>

                    <Form.Item label={"Số hoá đơn"}>
                        <Input
                            value={form.invoice}
                            disabled={loading}
                            placeholder="Vui lòng nhập số hoá đơn"
                            onChange={this.handleChangeInput.bind(this, "invoice")}
                            onPressEnter={() => this.handleSubmit()}
                        />
                    </Form.Item>

                    <Form.Item label={"Mục đích chi"}>
                        <SelectPaymentReason
                            value={form.paymentReason}
                            selectProps={{
                                placeholder: "Vui lòng nhập mục đích chi",
                            }}
                            onChange={this.handleChangeSelect.bind(this, "paymentReason")}
                        />
                    </Form.Item>

                    <Form.Item label={"Ghi chú"}>
                        <Input.TextArea
                            rows={3}
                            tabIndex={5}
                            value={form.remark}
                            placeholder="Vui lòng nhập ghi chú"
                            onChange={this.handleChangeInput.bind(this, "remark")}
                            autoComplete={'on'}
                        />
                    </Form.Item>

                    <Form.Item label={"Ảnh hoá đơn"} className="label-align-center">
                        <Upload
                            disabled={loading}
                            accept={"image/*"}
                            listType="picture-card"
                            multiple
                            fileList={fileList}
                            beforeUpload={this.handleBeforeUpload}
                            onPreview={this.handlePreviewImage}
                            onChange={this.handleUploadChange}
                            onRemove={this.handleRemoveFile}
                        >
                            <i className="fa-solid fa-plus-large" />
                            Chọn ảnh từ máy tính
                        </Upload>
                        <Modal
                            closeIcon={<i className="fa-solid fa-xmark" />}
                            visible={previewVisible}
                            title={previewTitle}
                            footer={null}
                            onCancel={this.handleCancelPreview}
                            centered
                        >
                            <img alt="example" style={{ width: "100%" }} src={previewImage} />
                        </Modal>
                    </Form.Item>

                    <div className={"mg-bt-12 font-medium fsz-16px capitalize"}>Người nhận tiền</div>

                    <Row gutter={12} className={"mg-bt-10"}>
                        <Col span={2}>
                            <Radio
                                tabIndex={6}
                                value={"system"}
                                className="pd-l-8"
                                checked={receiverType === "system"}
                                disabled={!accountProjects.length}
                                onChange={this.handleChangeReceiverType}
                            />
                        </Col>
                        <Col span={11}>
                            <Select
                                tabIndex={7}
                                disabled={!accountProjects.length || receiverType !== "system"}
                                className={"width-100pc"}
                                placeholder={"Vui lòng nhập hệ thống"}
                                value={project}
                                allowClear
                                onChange={this.handleChangeProject}
                            >
                                {projects
                                    .filter((item) => accountProjects.includes(item.code))
                                    .map((item: ProjectDomain) => (
                                        <Select.Option key={item.code} value={item.code}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </Col>
                        <Col span={11}>
                            <AutoComplete
                                tabIndex={8}
                                disabled={!accountProjects.length || receiverType !== "system"}
                                autoClearSearchValue={false}
                                className={"width-100pc"}
                                placeholder={"Vui lòng nhập Username/id"}
                                value={form.receiverRef}
                                showSearch={true}
                                filterOption={false}
                                options={customers.map((item: CustomerDomain) => {
                                    return {
                                        value: item.username,
                                        label: `${item.fullname} (${item.username})`,
                                    }
                                })}
                                allowClear
                                onChange={this.handleChangeSelect.bind(this, "receiverRef")}
                                onSearch={lodash.debounce(this.handleSearchStaff, 1000)}
                            />
                        </Col>
                    </Row>

                    <Row gutter={12}>
                        <Col span={2}>
                            <Radio
                                tabIndex={9}
                                value={"person"}
                                className="pd-l-8"
                                checked={receiverType === "person"}
                                onChange={this.handleChangeReceiverType}
                            />
                        </Col>
                        <Col span={22}>
                            <Input
                                autoFocus={true}
                                tabIndex={10}
                                disabled={receiverType !== "person" || loading}
                                placeholder={"Vui lòng nhập người nhận tiền"}
                                value={form.receiver}
                                onChange={this.handleChangeInput.bind(this, "receiver")}
                                onPressEnter={() => this.handleSubmit()}
                            />
                        </Col>
                    </Row>
                </Form>
            </DraggableModal>
        )
    }
}

export default ModalPaymentVoucherCreate
