import React from 'react';
import {Select} from "antd";
import {debounce} from 'lodash';
import ProjectDomain from "../../Domain/ProjectDomain";
import ProjectApi from "../../Api/ProjectApi";

interface Props {
    value?: any,
    onChange?: (code: string) => void
}

interface State {
    value: any,
    loading: boolean,
    projects: Array<ProjectDomain>
}

class SelectProject extends React.Component<Props, State> {

    state = {
        loading: false,
        value: undefined,
        projects: []
    }

    static getDerivedStateFromProps(props: Props, state: State) {
        const newState = {...state};
        if ('value' in props) {
            newState.value = props.value;
        }

        return newState;
    }

    componentDidMount() {
        this.fetchProjects();
    }

    fetchProjects = (query = {}) => {
        this.setState({loading: true});
        query = {
            offset: 0,
            limit: 1000,
            ...query,
        }
        ProjectApi.filter(query)
            .then(response => {
                this.setState({projects: response.data});
            })
            .finally(() => {
                this.setState({loading: false});
            })
    };

    handleChange = (value: any) => {
        this.setState({value});
        this.props.onChange?.(value);
    }

    handleSearch = (keyword: any) => {
        const fetchProjects = debounce(this.fetchProjects, 500);
        fetchProjects({query: keyword});
    }

    render() {
        const {projects, value} = this.state;
        return (
            <Select
                showSearch={true}
                filterOption={false}
                allowClear={true}
                value={value}
                onChange={this.handleChange}
                onSearch={this.handleSearch}
                placeholder={'Vui lòng chọn hệ thống'}
            >
                {projects.map((item: any) => (
                    <Select.Option key={item.code} value={item.code}>{item.name}</Select.Option>
                ))}
            </Select>
        );
    }
}

export default SelectProject;
