import apiClient from "../Util/ApiClient";

export default class ReceiptVoucherApi {

    static getReceiptVouchersByAccount = (account: any, query = {}) => {
        return apiClient.get(`/accounts/${account}/receipt-vouchers`, {
            params: query
        });
    };

    static create = (data: any) => {
        return apiClient.post('/receipt-vouchers', data);
    };

    static getByCode = (code: string) => {
        return apiClient.get(`/receipt-vouchers/${code}`);
    };

    static update = (code: string, data: any) => {
        return apiClient.patch(`/receipt-vouchers/${code}`, data);
    }

    static reject = (code: string) => {
        return apiClient.post(`/receipt-vouchers/${code}/reject`)
    }

    static approve = (code: string) => {
        return apiClient.post(`/receipt-vouchers/${code}/approve`)
    }

    static review = (code:string) => {
        return apiClient.post(`/receipt-vouchers/${code}/review`)
    }

}
