import {AutoComplete, Col, Form, Input, Modal, Radio, Row, Select} from "antd"
import TextArea from "antd/lib/input/TextArea"
import lodash from "lodash"
import React, {useEffect, useState} from "react"
import AccountApi from "../../Api/AccountApi"
import CustomerApi from "../../Api/CustomerApi"
import ProjectApi from "../../Api/ProjectApi"
import CustomerDomain from "../../Domain/CustomerDomain"
import ProjectDomain from "../../Domain/ProjectDomain"
import TransactionsDomain from "../../Domain/TransactionDomain"
import SuggestCustomerQueryCriteria from "../../QueryCriteria/SuggestCustomerQueryCriteria"
import {NotificationCommon} from "../Notification"

const {Item} = Form

interface UpdateTransactionProps {
    visible: boolean
    onVisibleChange: (visible: boolean) => void
    transaction: TransactionsDomain
    accountProjects: any
    accountCode: string
    onSuccess: () => void
}

const ModalEditTransaction: React.FC<UpdateTransactionProps> = (props) => {
    const [form] = Form.useForm()
    const currentTypeUser = lodash.get(props.transaction, 'participantRef') || lodash.get(props.transaction, 'project') ? 'system' : 'person'
    const [payerOrReceiver, setPayerOrReceiver] = useState(currentTypeUser)
    const [project, setProject] = useState(props.transaction.participantRef ? lodash.get(props.transaction, "participantRef.project") : props.transaction.project ? lodash.get(props.transaction, 'project') : undefined)
    const [participantRef, setParticipantRef] = useState(lodash.get(props.transaction, 'participantRef.username'))

    const [loading, setLoading] = useState(false)
    const [projects, setProjects] = useState([])

    const [customers, setCustomers] = useState([]);

    useEffect(() => {
        fetchProjects({}).then();
        const query: string = lodash.get(props.transaction, 'participantRef.username')

        if (project && query) {
            fetchCustomers({project, query}).then();
        }

        // eslint-disable-next-line
    }, [props.transaction])

    const fetchCustomers = async (filter: SuggestCustomerQueryCriteria = {}) => {
        try {
            const response = await CustomerApi.getSuggestCustomers({limit: "300", ...filter})
            setCustomers(response.data)
        } catch (error: any) {
            NotificationCommon.error({
                message: "Không tải được danh sách khách hàng. Lỗi: " + error.message,
            })
        }
    }

    const fetchProjects = async (filter = {}) => {
        setLoading(true)
        filter = {...filter, offset: 0, limit: 1000}
        try {
            const response = await ProjectApi.filter(filter)
            setProjects(response.data)
            setLoading(false)
        } catch (error: any) {
            NotificationCommon.error({
                message: "Không tải được danh sách dự án. Lỗi: " + error.message,
            })
        }
    }

    const handleChangeReceiverType = (e: any) => {
        setPayerOrReceiver(e.target.value)
        setParticipantRef(null)
        setProject(undefined)
        setCustomers([])
    }

    const handleChangeProject = (value: any) => {
        setProject(value)
        setParticipantRef('')
        setCustomers([])
    }

    const handleChangeParticipantRef = (value: any) => {
        setParticipantRef(value)
    }

    const handleSearchStaff = (value: any) => {
        if (project) {
            if (value && value.toString().length >= 3) {
                fetchCustomers({
                    query: value,
                    project,
                })
            }
        }
    }

    const handleUpdateTransaction = async () => {
        const {memo, personName} = form.getFieldsValue(true)
        let participant
        if (payerOrReceiver === "system") {
            if (!project || !participantRef) {
                participant = null
            } else {
                const customerCurrent = customers.find((cus: CustomerDomain) => cus.code === `c:${project}:${participantRef}`)
                if (customerCurrent && project) {
                    participant = `${lodash.get(customerCurrent, 'fullname')} (${project})`
                } else if (participantRef && project) {
                    participant = `${participantRef} (${project})`
                }
            }
        } else {
            participant = personName
        }

        try {
            await AccountApi.updateTransaction(props.accountCode, lodash.get(props.transaction, 'code'), {
                memo,
                participant,
                participantRef: (project && participantRef) ? `c:${project}:${participantRef}` : null,
                project: project,
            })
            NotificationCommon.success({message: "Cập nhật thành công!"})
            props.onSuccess()
        } catch (err: any) {
            NotificationCommon.error({message: err.message})
        }
        props.onVisibleChange(false)
    }

    return (
        <Modal
            visible={props.visible}
            title={`Cập nhật giao dịch ${lodash.get(props.transaction, 'code')}`}
            okText={"Xác Nhận"}
            closeIcon={<i className="fa-solid fa-xmark"/>}
            cancelText={"Hủy Bỏ"}
            onCancel={() => props.onVisibleChange(false)}
            onOk={handleUpdateTransaction}
            width={660}
            okButtonProps={{
                tabIndex: 11,
                loading: loading,
                icon: <i className="fa-solid fa-check pd-r-8"/>
            }}
            cancelButtonProps={{
                tabIndex: 12,
                disabled: loading,
                icon: <i className="fa-solid fa-xmark pd-r-8"/>,
                type: 'ghost'
            }}
            centered
        >
            <Form
                layout="vertical"
                name="form_edit_transaction"
                initialValues={{
                    memo: lodash.get(props.transaction, 'memo'),
                    project,
                    personName: payerOrReceiver === 'person' ? lodash.get(props.transaction, 'participant') : ''
                }}
                form={form}
            >
                <Item label={<span className="font-medium fsz-16px">Nội dung giao dịch</span>} name="memo">
                    <TextArea rows={3} onPressEnter={handleUpdateTransaction} disabled
                              placeholder="Vui lòng nhập nội dung giao dịch"/>
                </Item>

                <Item className="mg-bt-0" label={<span className="font-medium fsz-16px">Người nhận/nộp</span>}>
                    <Row gutter={16} className={"mg-bt-12"}>
                        <Col span={2} className="flex items-center">
                            <Radio
                                disabled={!props.accountProjects.length}
                                value={"system"}
                                className="pd-l-8"
                                checked={payerOrReceiver === "system"}
                                onChange={handleChangeReceiverType}
                            />
                        </Col>
                        <Col span={11}>
                            <Select
                                disabled={!props.accountProjects.length || payerOrReceiver !== "system"}
                                className={"width-100pc"}
                                placeholder={"Vui lòng chọn hệ thống"}
                                value={loading ? undefined : project}
                                allowClear
                                onChange={handleChangeProject}
                            >
                                {projects
                                    .filter((item: ProjectDomain) => props.accountProjects.includes(item.code))
                                    .map((item: ProjectDomain) => (
                                        <Select.Option key={item.code} value={item.code}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </Col>
                        <Col span={11}>
                            <AutoComplete
                                disabled={!props.accountProjects.length || payerOrReceiver !== "system"}
                                className={"width-100pc"}
                                placeholder={"Vui lòng nhập Username"}
                                showSearch={true}
                                filterOption={false}
                                allowClear
                                onChange={handleChangeParticipantRef}
                                onSearch={lodash.debounce(handleSearchStaff, 500)}
                                value={participantRef}
                            >
                                {customers.map((item: CustomerDomain) => (
                                    <AutoComplete.Option key={item.username} value={item.username}>
                                        {item.fullname} ({item.username})
                                    </AutoComplete.Option>
                                ))}
                            </AutoComplete>
                        </Col>
                    </Row>
                    <Row gutter={12}>
                        <Col span={2} className="flex items-center">
                            <Radio value={"person"} checked={payerOrReceiver === "person"} className="pd-l-8"
                                   onChange={handleChangeReceiverType}/>
                        </Col>
                        <Col span={22}>
                            <Item name="personName" className="mg-bt-0">
                                <Input
                                    disabled={payerOrReceiver !== "person"}
                                    placeholder={"Vui lòng nhập người nộp tiền"}
                                    onPressEnter={handleUpdateTransaction}
                                />
                            </Item>
                        </Col>
                    </Row>
                </Item>
            </Form>
        </Modal>
    )
}

export default ModalEditTransaction
