import {Table} from "antd"
import {ColumnsType, TableProps} from "antd/lib/table"
import {FC} from "react"
import SkeletonTable from "./SkeletonTable"

interface IProps extends TableProps<any> {
    columns: ColumnsType<any>
}

const CommonTable: FC<IProps> = ({ loading, scroll, ...props }) => {
    if (loading) {
        return <SkeletonTable columns={props.columns} />
    }

    const scrollDefault = props.columns.length > 3 ? { x: 1167 } : undefined

    return <Table
        scroll={scroll || scrollDefault}
        {...props}
        // rowKey={() => uniqueId()}
    />
}

export default CommonTable
