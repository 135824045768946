import React from 'react';
import Routes from "./Routes";
import AccountDomain, {AccountModel} from "./Domain/AccountDomain";
import localStore from "./Util/LocalStore";
import {AppContext, AppContextValueInterface, defaultAppContextValue} from './Context/AppContext';
import AccountApi from "./Api/AccountApi";
import {TenantModel} from "./Domain/TenantDomain";
import axios from "axios";
import appConfig from "./Config/App";
import { notification } from 'antd';

import "./App.scss";

notification.config({
    placement: 'topRight',
    duration: 3,
    maxCount: 1,
  });
  
interface AppStateInterface {
    loading: boolean,
    account: AccountDomain,
    tenant: any
}

class App extends React.Component<any, AppStateInterface> {

    state: AppStateInterface = {
        loading: false,
        account: localStore.getJson('account') || {...AccountModel},
        tenant: localStore.getJson('tenant') || {...TenantModel}
    }

    componentDidMount() {
        this.fetchCurrentTenant();
    }

    fetchCurrentTenant = () => {
        const tenant = localStore.getJson('tenant');
        if (!tenant) {
            axios({
                url: appConfig.apiUrl + '/tenants/current'
            })
                .then(response => {
                    this.setState({
                        tenant: response.data
                    })

                    localStore.setJson('tenant', response.data);
                })
        }
    }

    fetchAccount = (accountCode: string) => {
        this.setState({loading: true})
        return AccountApi.findByCode(accountCode)
            .then(response => {
                this.setState({account: response.data});
            })
            .finally(() => this.setState({loading: false}))
    }

    updateAccount = (account: AccountDomain) => {
        localStore.setJson('account', account);
        this.setState({account});
    }

    getContextValue = () => {
        const value: AppContextValueInterface = {...defaultAppContextValue};
        value.state.loading = this.state.loading;
        value.state.account = this.state.account;
        value.state.tenant = this.state.tenant;
        value.func.updateAccount = this.updateAccount;
        value.func.fetchAccountAndUpdateState = this.fetchAccount;
        return {...value};
    }

    render() {
        return (
            <AppContext.Provider value={this.getContextValue()}>
                <div className="main-body">
                    <Routes />
                </div>
            </AppContext.Provider>
        );
    }
}

export default App;
