import {Button, Tag, Tooltip} from 'antd';
import {Component, ReactNode} from "react";
import DeviceDomain, {DeviceModel} from "../../Domain/DeviceDomain";
import {DEVICE_STATUSES, PERMISSIONS} from "../../Util/Constants";
import lodash from 'lodash';
import SecurityService from "../../Util/SecurityService";
import ConfirmDeletePopup from "../../Component/ConfirmPopup";
import DeviceApi from "../../Api/DeviceApi";
import CommonTable from '../../Component/CommonTable';
import Utils from "../../Util/Utils";
import { NotificationCommon } from '../../Component/Notification';

interface Props {
    location?:any,
    loading?: boolean,
    items: Array<DeviceDomain>
    total: number,
    pageSize: number,
    currentPage: number,
    onChangePage: (page: number, pageSize?: number) => void,
    onSenderConfigVisibleChange: (device: DeviceDomain, visible: boolean) => void,
    getDevices:(filter:any)=>void;
}

interface State {
    secretVisible: any,
    selectedRowKeys: any,
    confirmDelete: boolean,
    deleting:boolean,
    selectedDevice:DeviceDomain,
    deleteContent:string|ReactNode,
}

class DataList extends Component<Props, State> {

    state = {
        selectedDevice:DeviceModel,
        deleteContent:'',
        confirmDelete: false,
        deleting:false,
        secretVisible: {},
        selectedRowKeys: [],
        columns: [
            {
             title:"Mã",
             key:'code',
             dataIndex: 'code',
            },
            {
                title: "Tên",
                key: "name",
                dataIndex: "name",
                render: (row: any) => {
                    return <span className='font-medium'>{row}</span>
                }
            },
            {
                title: "Trạng thái",
                key: "status",
                align: 'center',
                render: (row: any) => {
                    const status: any = DEVICE_STATUSES.find(item => item.code === row.status);
                    if (status) {
                        return (<div>
                                <Tag className='pd-l-12 pd-r-12 capitalize'
                                     color={status.color}>{lodash.get(status, 'name')}
                                </Tag>
                        </div>)
                    }
                    return (<div>
                        <Tag className='fsz-14px pd-l-12 pd-r-12 capitalize'>Không xác định</Tag>
                    </div>)
                }
            },
            {
                title: "Hệ điều hành",
                key: "os",
                dataIndex: "os",
                align:'left',
                render:(os:string) => <span className={'capitalize'}>{os}</span>
            },
            {
                title: "Cấu hình sender",
                key: "configSender",
                align: 'center',
                render: (row: any) => {
                    if (SecurityService.can(PERMISSIONS.DEVICE_CONFIG_VIEW)) {
                        return (
                            <Button type='text' onClick={() => this.props.onSenderConfigVisibleChange(row, true)}><i
                                className="fa-solid fa-gear text-blue"/></Button>
                        )
                    }
                }
            },
            {
                title: "",
                key: "delete",
                align: 'center',
                width:56,
                render: (row: any) => <div className={'device-actions'}>
                        {SecurityService.can(PERMISSIONS.DEVICE_DELETE) &&  <Tooltip title={'Xóa'} placement={'left'}>
                            <span className={'pointer'} onClick={()=>this.onClickDeleteDevice(row)}><i className="fa-solid fa-trash text-danger"/></span>
                        </Tooltip>}
                </div>
            }
        ]
    };

    handleChangePage = (page: number, pageSize ?: number) => {
        this.props.onChangePage(page, pageSize)
    };

    onClickDeleteDevice = (row:DeviceDomain) => {
        if(row.status === 'CONNECTED') {
            this.setState({
                confirmDelete: true,
                selectedDevice:row,
                deleteContent:<div className={'delete-connected-device'}>
                    <div>Bạn có chắc chắn muốn xóa thiết bị <span className="bold">{row.name}</span>?</div>
                    <div className={'warning-content'}>
                        <span className={'warning-icon'}><i className="fa-solid fa-triangle-exclamation"/></span>
                        <span className={'mg-l-10 font-size-12'}>Thiết bị <span className={'bold'}>{row.name}</span> sẽ không phân tích được tin nhắn. Bạn có chắc chắn muốn xóa?</span>
                    </div>
                </div>
            });
        } else {
            this.setState({
                confirmDelete: true,
                selectedDevice:row,
                deleteContent:<span>Bạn có chắc chắn muốn xóa thiết bị <span className="bold">{row.name}</span>?</span>});
        }
    }

    handleDeleteDevice = () => {
        let code = this.state.selectedDevice.code || '';
        this.setState({deleting:true })
        DeviceApi.deleteDevice(code).then(()=> {
            NotificationCommon.success({
                message:"Xóa thiết bị thành công!",
                icon:<i className="fa-solid fa-circle-check"/>,
            })
            this.props.getDevices({...Utils.getQueryFromLocation(window.location)})
        }).catch((err)=> {
            console.log(err.response)
            NotificationCommon.error({
                message:"Xóa thất bại! Vui lòng thử lại",
            })
        }).finally(()=>this.setState({deleting:false,confirmDelete:false}))
    }

    handleCancelDeleteDevice = () => {
        this.setState({confirmDelete: false})
    }


    render() {
        const {loading, total, pageSize, items, currentPage} = this.props;
        const columns: any = this.state.columns;
        return (
            <>
                <CommonTable
                    className={''}
                    columns={columns}
                    dataSource={items}
                    loading={loading}
                    rowKey={'code'}
                    pagination={{
                        current: currentPage,
                        total: total,
                        pageSize: pageSize,
                        pageSizeOptions: [10, 20, 25, 50, 100],
                        hideOnSinglePage: true,
                        onChange: this.handleChangePage
                    }}
                    rowClassName={'device-row-record'}
                />
                <ConfirmDeletePopup title={'Xác nhận xóa'}
                                    description={this.state.deleteContent}
                                    onOk={this.handleDeleteDevice}
                                    onCancel={this.handleCancelDeleteDevice}
                                    visible={this.state.confirmDelete}
                                    deleting={this.state.deleting}
                />
            </>
        );
    }
}

export default DataList;
