import React from "react";
import {Badge, Button, DatePicker, Drawer, Skeleton, Table, Tooltip} from "antd";
import lodash from "lodash";
import TransactionsDomain from "../../Domain/TransactionDomain";
import {AppContext} from "../../Context/AppContext";
import AccountApi from "../../Api/AccountApi";
import moment from "moment";
import {InfoCircleOutlined} from "@ant-design/icons";
import Utils from "../../Util/Utils";
import {RangePickerProps} from "antd/es/date-picker";
import CustomPagination from "../CustomPagination";

const beginDateFormat = "YYYY-MM-DD 00:00:00"
const endDateFormat = "YYYY-MM-DD 23:59:59"

type Props = {
    visible: boolean,
    onClose: () => void,
    record: TransactionsDomain | undefined,
}

function TransactionListByUser(props: Props) {
    const [transactionByCustomer, setTransactionByCustomer] = React.useState<Array<TransactionsDomain>|any>();
    const [loading, setLoading] = React.useState(false);
    const [pagination, setPagination] = React.useState<any>();
    const [form, setForm] = React.useState<any>({});
    const [filter, setFilter] = React.useState({});
    const context = React.useContext(AppContext);
    const {
        visible, onClose, record
    } = props;

    const getTransactionsByUser = React.useCallback((filter: any) => {
        const account = context.state.account;
        setLoading(true)
        if (account.code) {
            let filterTemp = {
                offset: 0,
                limit: 25,
                sort: "timestamp:desc",
                ...filter,
            }
            setFilter(filterTemp)
            AccountApi.getTransactions(lodash.get(account, "code"), filterTemp)
                .then((response: any) => {
                    setTransactionByCustomer(response.data);
                    setPagination({
                        current: parseInt(lodash.get(response, "headers.x-page-number")) + 1,
                        pageSize: parseInt(lodash.get(response, "headers.x-page-size")),
                        total: parseInt(lodash.get(response, "headers.x-total-count")),
                    })
                })
                .catch((err) => {
                    console.log(err.response)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [context])

    React.useEffect(() => {
        let temp: any = {};
        if (record) {
            if (record.participantRef) {
                temp = {
                    participant: `c:${record.participantRef?.project}:${record.participantRef?.username}`,
                }
            } else if (record?.participant) {
                temp = {
                    participant: record.participant,
                }
            }
        }
        setFilter(temp);
        getTransactionsByUser(temp);
    }, [record, getTransactionsByUser])

    const handleChangePage = (page: number, pageSize?: number) => {
        let filterTemp = {
            ...filter,
            page: page,
            limit: pageSize,
            offset: (page - 1) * pageSize!,
        }
        setFilter(filterTemp)
        getTransactionsByUser(filterTemp);
    }

    const handleChangeRangePicker = (field: string, values: any) => {
        let data: any = {
            timestampFrom: '',
            timestampTo: '',
        };
        if (field === "timestamp") {
            data["timestampFrom"] = values && values[0] ? moment(values[0].format(beginDateFormat)).toISOString() : undefined
            data["timestampTo"] = values && values[1] ? moment(values[1].format(endDateFormat)).toISOString() : undefined
        }
        setForm(data)
        if(!values) {
            console.log('chay vao day')
            handleResetFilter();
        }
    }

    const disabledFutureDate: RangePickerProps['disabledDate'] = (current) => {
        return current && current > moment().endOf('day');
    };

    const handleSearchByTimestamp = () => {
        getTransactionsByUser({
            ...filter,
            ...form,
        })
    }

    const handleResetFilter = () => {
        getTransactionsByUser({
            ...filter,
            form:undefined,
            timestampFrom:undefined,
            timestampTo:undefined,
        })
    }

    const handleDestroyDrawer = () => {
        setTransactionByCustomer(undefined)
        onClose();
    }

    const columns = [
        {
            title: 'Thời gian',
            key: 'createdAt',
            width: '10%',
            render: (row: any) => {
                return <span
                    className={'flr'}>{row.timestamp ? moment(row.timestamp).format('DD/MM/YYYY HH:mm:ss') : '--'}</span>
            }
        },
        {
            title: 'Người thực hiện GD',
            key: 'customer',
            width: '10%',
            render: (row: any) => {
                const renderCustomerUserName = () => {
                    return lodash.get(record, 'participantRef.username')
                }
                return (
                    <div className={'flex space-between'}>
                        {!row.participantRef ? (
                            <span>{row.participant || '--'}</span>
                        ) : (
                            <Tooltip
                                title={
                                    lodash.get(record, 'participantRef.fullname')
                                        ? `${lodash.get(record, 'participantRef.fullname')} (${lodash.get(
                                            record,
                                            'participantRef.project'
                                        )})`
                                        : row.participant
                                }
                                placement={'top'}
                            >
                                {renderCustomerUserName()}
                            </Tooltip>
                        )}
                    </div>
                )
            }
        },
        {
            title: 'Nội dung GD',
            key: 'memo',
            width: '45%',
            render: (row: any) => {
                let account = context.state.account
                return (<div style={{minWidth: 100}}>
                    <div>{row.memo}</div>
                    <div>
                        TK: <span className="bold">{account.name}</span>
                    </div>
                    {account.type === 'BANK' && (
                        <div>
                            Ngân hàng: <span className="bold">{lodash.get(account, 'bank.name')}</span>
                        </div>
                    )}
                    {row.remark && (
                        <div className={'transaction-remark'}>
                            <InfoCircleOutlined/> {row.remark}
                        </div>
                    )}
                </div>)
            }
        },
        {
            title: 'Giá trị',
            key: 'amount',
            dataIndex: 'amount',
            width: '15%',
            render: (amount: number) => {
                let account = context.state.account;
                const CURRENCY = lodash.get(account, 'currency.code');
                return (<span
                    className={`bold fsz-14px ${amount < 0 ? 'negative-money' : 'positive-money'}`}>{Utils.currencyFormatByCurrency(amount, CURRENCY)}</span>)
            }
        },
        {
            title: 'Số dư',
            key: 'balance',
            dataIndex: 'balance',
            width: '15%',
            render: (balance: number) => {
                let account = context.state.account;
                const CURRENCY = lodash.get(account, 'currency.code');
                return (<span
                    className={`bold fsz-14px ${balance < 0 ? 'negative-money' : 'positive-money'}`}>{Utils.currencyFormatByCurrency(balance, CURRENCY)}</span>)
            }
        }
    ]

    return (
        <div className={'customer-transaction-drawer'}>
            <Drawer
                title={<div className={'flex space-between'}>
                    <span>Tài chính khách hàng <Badge overflowCount={9999} count={pagination ? pagination.total : 0}/></span>
                    <span className={'pointer text-dark-color'} onClick={handleDestroyDrawer}><i className="fa-solid fa-xmark"/></span>
                </div>}
                placement={'right'}
                width={window.innerWidth >= 768 ? 735 : 378}
                onClose={handleDestroyDrawer}
                visible={visible}
                extra={null}
                closable={false}
                destroyOnClose
            >
                <div className={'flex width-100pc align-items-center mg-bt-12 space-between transaction-quickView'}>
                    <div className={'flex align-items-center width-100pc'} style={{flex: 1}}>
                        <div className={''}>Thời gian diễn ra giao dịch</div>
                        <div className={'mg-l-6 mg-r-6 date-picker'} style={{flex: 1}}>
                            <DatePicker.RangePicker
                                className={"width-100pc"}
                                value={[
                                    form.timestampTo ? moment(form.timestampTo) : null,
                                    form.timestampFrom ? moment(form.timestampFrom) : null,
                                ]}
                                disabledDate={disabledFutureDate}
                                placeholder={["Ngày bắt đầu", "Ngày kết thúc"]}
                                format={"DD/MM/YYYY"}
                                onChange={(values: any) => {
                                    handleChangeRangePicker('timestamp', values)
                                }}
                                allowClear
                                autoFocus
                            />
                        </div>
                    </div>
                    <div className={''}>
                        <Button type={'primary'} onClick={handleSearchByTimestamp}
                                disabled={!form.timestampFrom || !form.timestampTo}
                        >Tìm kiếm</Button>
                    </div>
                </div>
                <Skeleton loading={loading} active avatar>
                    <Table
                        className={''}
                        columns={columns}
                        loading={loading}
                        rowKey={() => lodash.uniqueId()}
                        dataSource={transactionByCustomer}
                        pagination={false}
                        scroll={{x:690}}
                    />
                    {pagination && pagination.total > 10 && <CustomPagination pagination={pagination}
                                                                onPageChange={handleChangePage}
                                                                pageSizes={[10,25,50,100]}
                                                                filter={filter}
                    />}
                </Skeleton>
            </Drawer>
        </div>
    )
}

export default TransactionListByUser;
