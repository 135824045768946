export default interface TransactionsDomain {
	code: string
	paymentVoucher?: any
	timestamp?: string
	approvedAt?: any
	amount?: string | number
	preparedBy?: any
	approvedBy?: any
	receiptVoucher?: any | undefined
	participant?: string
	participantRef?: any
	remark?: string
	memo?: string,
	project?:string,
	type?:string,
	hasSubTransaction?:boolean,
}

export const TransactionDomainDefault: TransactionsDomain = {
	code: "",
}
