import { notification } from "antd"
import { ArgsProps } from "antd/lib/notification"

export class NotificationCommon {
    static error({ message, key }: ArgsProps) {
        notification.error({
            message: "Rất tiếc!",
            description: message,
            icon: <i className="fa-solid fa-triangle-exclamation" />,
            closeIcon: <i className="fa-solid fa-xmark" />,
            key
        })
    }

    static success({ message }: ArgsProps) {
        notification.success({
            message: "Chúc mừng!",
            description: message,
            icon: <i className="fa-solid fa-circle-check"/>,
            closeIcon: <i className="fa-solid fa-xmark" />,
        })
    }

    static warning({ message }: ArgsProps) {
        notification.warning({
            message: "Cảnh báo!",
            description: message,
            icon: <i className="fa-solid fa-triangle-exclamation" />,
            closeIcon: <i className="fa-solid fa-xmark" />,
        })
    }
}
