import {Button, Card, Empty, Modal} from "antd";
import React, {useEffect, useState} from "react";
import ProjectApi from "../../../Api/ProjectApi";
import AccountDomain from "../../../Domain/AccountDomain";
import {AddProjectModal} from "./AddProjectModal";
import {DeleteOutlined} from '@ant-design/icons'
import AccountApi from "../../../Api/AccountApi";
import {events} from "../../../Util/Event";
import {ACCOUNT_EVENT} from "../../../Util/Constants";
import ProjectDomain from "../../../Domain/ProjectDomain";
import CommonTable from "../../../Component/CommonTable";
import { NotificationCommon } from "../../../Component/Notification";

interface Props {
    account: AccountDomain
}

export const Project = (props: Props) => {
    const {account} = props

    useEffect(() => {
        if (account.projects && Array.isArray(account.projects) && account.projects.length) {
            setLoading(true)
            ProjectApi.filter({codes: account.projects.join(','), limit: 100})
                .then(response => {
                    setProjects(response.data)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
        else {
            setProjects([])
        }
    }, [account.projects])

    const [loading, setLoading] = useState(false)
    const [projects, setProjects] = useState([])
    const [showAddProjectModal, setShowAddProjectModal] = useState<boolean>(false)

    const columns = [
        {
            title: "Hệ thống",
            key: "name",
            dataIndex: "name"
        },
        {
            title: "Mô tả",
            key: "description",
            dataIndex: "description"
        },
        {
            title: "",
            key: "remove",
            render: (row: any) => {
                return (<div className={'flr'}>
                        <DeleteOutlined onClick={handleRemoveProject.bind(this, row)} className={'pointer text-danger'} />
                    </div>
                )
            }
        }
    ]

    const handleAddProjectModalVisibleChange = (visible: boolean) => {
        setShowAddProjectModal(visible)
    }

    const handleRemoveProject = (project: ProjectDomain) => {
        Modal.confirm({
            title: <span>Bạn có chắc chắn muốn bỏ hệ thống <b>{project.name}</b> khỏi tài khoản quỹ này?</span>,
            okButtonProps: {
                loading: loading,
                disabled: loading,
                tabIndex: 2,
                icon: <i className="fa-solid fa-check mg-r-8"/>
            },
            cancelButtonProps: {
                loading: loading,
                disabled: loading,
                tabIndex: 3,
                icon: <i className="fa-solid mg-r-8 fa-xmark"/>,
                type: 'ghost'
            },
            okText: "Đồng ý",
            cancelText: "Hủy",
            onOk: () => {
                setLoading(true)
                AccountApi.removeProjects(account.code,[project.code])
                    .then(() => {
                        NotificationCommon.success({
                            message: 'Xoá thành công'
                        })
                        events.emit(ACCOUNT_EVENT.REMOVE_PROJECT)
                    })
                    .catch(() => {
                        NotificationCommon.error({
                            message: "Xoá không thành công"
                        })
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            }
        })
    }

    return (
        <Card
            className={'account-info'}
            title={`Hệ thống`}
            extra={<Button size={'small'} type={'link'} onClick={() => handleAddProjectModalVisibleChange(true)} className={'font-size-12'}>
                <span><i className="fa-solid fa-plus mg-r-6"/></span>
                <span>Thêm Hệ Thống</span>
            </Button>}
        >
            {projects.length ? (
                <CommonTable
                    loading={loading}
                    columns={columns}
                    rowKey={'id'}
                    dataSource={projects}
                    pagination={false}
                />
            ) : (
                <Empty description={'Chưa cấu hình hệ thống'} />
            )}

            {showAddProjectModal && (
                <AddProjectModal
                    account={account}
                    onVisibleChange={handleAddProjectModalVisibleChange}
                />
            )}
        </Card>
    )
}
