import {Button, Form, FormInstance, Input, Modal, Radio, Select,} from 'antd'
import React from 'react'
import AccountApi from '../../Api/AccountApi'
import BankApi from '../../Api/BankApi'
import CurrencyApi from '../../Api/CurrencyApi'
import DefaultComponent from '../../Component/DefaultComponent'
import {NotificationCommon} from '../../Component/Notification'
import BankDomain from '../../Domain/BankDomain'
import CurrencyDomain from '../../Domain/CurrencyDomain'
import DefaultPropsInterface from '../../Interface/DefaultPropsInterface'
import {ACCOUNT_TYPES} from '../../Util/Constants'

interface FormInterface {
    code?: string
    name?: string
    type?: string
    bank?: string
    accountNumber?: string
    accountHolder?: string
    currency?: string
    description?: string
}

interface Props extends DefaultPropsInterface {
    onVisibleChange: (visible: boolean) => {}
}
interface State {
    loading: boolean
    banks: BankDomain[]
    currencies: CurrencyDomain[]
    form: FormInterface
    disableSubmit: boolean
    focusOnInput:boolean,
}

class Create extends DefaultComponent<Props, State> {
    formRef = React.createRef<FormInstance>()
    state = {
        loading: false,
        disableSubmit: true,
        form: {
            type: 'CASH',
        },
        banks: [],
        currencies: [],
        focusOnInput:true,
    }

    componentDidMount(): void {
        this.fetchBanks()
        this.fetchCurrencies()
    }

    componentDidUpdate(prevProps: Readonly<Props & React.ComponentProps<any>>, prevState: Readonly<State & React.ComponentState>, snapshot?: any) {
        if(prevState.form.type !== this.state.form.type) {
            this.setState({focusOnInput:true})
        }
    }

    validateValues() {
        const formInst: FormInterface = this.state.form
        const { type, name, currency, bank } = formInst

        const isError: boolean =
            type === 'CASH' ? !name || !currency : !name || !currency || !bank
        this.setState({
            disableSubmit: isError,
        })
    }

    fetchBanks = () => {
        this.setState({ loading: true })
        BankApi.filter({})
            .then((response: any) => {
                this.setState({
                    banks: response.data,
                })
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    fetchCurrencies = () => {
        this.setState({ loading: true })
        CurrencyApi.filter({})
            .then((response: any) => {
                this.setState({
                    currencies: response.data,
                })
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    handleChangeInput = (field: string, e: any) => {
        this.setState(
            {
                form: {
                    ...this.state.form,
                    [field]: e.target.value,
                },
            },
            () => {
                this.validateValues()
            }
        )
    }

    handleChangeSelect = (field: string, value: any) => {
        this.setState(
            {
                form: {
                    ...this.state.form,
                    [field]: value,
                },
            },
            () => {
                this.validateValues()
            }
        )
    }

    handleFilterOptions = (input: string, option: any) => {
        return (
            option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
            option?.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
        )
    }

    handleSubmit = () => {
        const {disableSubmit} = this.state;
        if(!disableSubmit) {
            this.setState({ loading: true });
            AccountApi.create(this.state.form)
                .then(() => {
                    this.setState({ loading: false })
                    NotificationCommon.success({
                        message:
                            'Tạo tài khoản quỹ thành công. Vui lòng đợi trong giây lát để hoàn tất việc chốt sổ quỹ lần đầu',
                    })
                    this.props.onVisibleChange(false)
                    this.props.onCreateSuccess()
                })
                .catch((error: any) => {
                    if (error.response.status === 400) {
                        NotificationCommon.error({
                            message: 'Vui lòng kiểm tra thông tin trên form',
                        })
                    } else {
                        NotificationCommon.error({
                            message: 'Có lỗi xảy ra, vui lòng thử lại',
                        })
                    }
                    this.setState({ loading: false })
                })
        }
    }

    handleCancel = () => {
        this.props.onVisibleChange(false)
    }

    render(): React.ReactNode {
        const form: FormInterface = this.state.form
        const banks: BankDomain[] = this.state.banks
        const { currencies } = this.state
        const { loading, disableSubmit } = this.state

        return (
            <Modal
                title={'Tạo tài khoản quỹ'}
                visible={true}
                width={660}
                closeIcon={<i className="fa-solid fa-xmark" />}
                centered
                footer={null}
                onCancel={this.handleCancel}
            >
                <Form ref={this.formRef}>
                    <Form.Item labelCol={{ span: 4 }} labelAlign="left">
                        <Radio.Group
                            name={'type'}
                            value={form.type}
                            onChange={this.handleChangeInput.bind(this, 'type')}
                        >
                            {ACCOUNT_TYPES.map((item) => (
                                <Radio key={item.key} value={item.key}>
                                    {item.text}
                                </Radio>
                            ))}
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        labelCol={{ span: 4 }}
                        name="name"
                        labelAlign="left"
                        label={'Tên tài khoản'}
                        rules={[
                            {
                                required: true,
                                message: 'Tên tài khoản không được để trống',
                            },
                        ]}
                    >
                        <Input
                            autoFocus={this.state.focusOnInput}
                            value={form.name}
                            onChange={this.handleChangeInput.bind(this, 'name')}
                            placeholder="Vui lòng nhập tên tài khoản"
                        />
                    </Form.Item>

                    <Form.Item
                        labelCol={{ span: 4 }}
                        name="currency"
                        labelAlign="left"
                        label={'Đơn vị tiền tệ'}
                        rules={[
                            {
                                required: true,
                                message: 'Đơn vị tiền tệ không được để trống',
                            },
                        ]}
                    >
                        <Select
                            value={form.currency}
                            onChange={this.handleChangeSelect.bind(
                                this,
                                'currency'
                            )}
                            placeholder="Vui lòng nhập đơn vị tiền tệ"
                        >
                            {currencies.map((item: CurrencyDomain) => (
                                <Select.Option
                                    key={item.code}
                                    value={item.code}
                                >
                                    {item.code}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    {form.type === 'BANK' && (
                        <>
                            <Form.Item
                                labelCol={{ span: 4 }}
                                name="bank"
                                labelAlign="left"
                                label={'Ngân hàng'}
                                required={true}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ngân hàng không được để trống',
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Vui lòng chọn ngân hàng"
                                    filterOption={this.handleFilterOptions.bind(
                                        this
                                    )}
                                    value={form.bank}
                                    onChange={this.handleChangeSelect.bind(
                                        this,
                                        'bank'
                                    )}
                                >
                                    {banks.map((item: BankDomain) => (
                                        <Select.Option
                                            key={item.code}
                                            value={item.code}
                                        >
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                labelCol={{ span: 4 }}
                                labelAlign="left"
                                label={'Số tài khoản'}
                            >
                                <Input
                                    value={form.accountNumber}
                                    onChange={this.handleChangeInput.bind(
                                        this,
                                        'accountNumber'
                                    )}
                                    placeholder="Vui lòng nhập số tài khoản"
                                />
                            </Form.Item>

                            <Form.Item
                                labelCol={{ span: 4 }}
                                labelAlign="left"
                                label={'Chủ tài khoản'}
                            >
                                <Input
                                    value={form.accountHolder}
                                    onChange={this.handleChangeInput.bind(
                                        this,
                                        'accountHolder'
                                    )}
                                    placeholder="Vui lòng nhập chủ tài khoản"
                                />
                            </Form.Item>
                        </>
                    )}

                    <Form.Item
                        labelCol={{ span: 4 }}
                        labelAlign="left"
                        label={'Ghi chú'}
                        className="mg-bt-12"
                    >
                        <Input.TextArea
                            value={form.description}
                            onChange={this.handleChangeInput.bind(
                                this,
                                'description'
                            )}
                            placeholder="Vui lòng nhập ghi chú"
                            onKeyDown={(e) => {
                                if (e.code === "Enter" && !e.shiftKey) {
                                    this.handleSubmit();
                                    e.preventDefault();
                                }
                            }}
                        />
                    </Form.Item>
                    <Form.Item className="mg-bt-0">
                        <div className={'width-100pc flex justify-content-flex-end'}>
                            <Button onClick={this.handleCancel} disabled={loading} type={'ghost'}>
                                <i className="fa-solid fa-xmark pd-r-8 " /> Hủy bỏ
                            </Button>
                            <Button
                                className={'mg-l-8'}
                                onClick={this.handleSubmit} htmlType={'submit'} loading={loading} disabled={loading ||disableSubmit }
                            >
                                <i className="fa-solid fa-check pd-r-8" />Xác nhận
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

export default Create
