import {QuestionCircleOutlined} from '@ant-design/icons';
import {Button, Tag, Tooltip, Typography} from 'antd';
import lodash from 'lodash';
import moment from "moment";
import {Component, createRef} from "react";
import {Link} from "react-router-dom";
import MessageDomain from "../../Domain/MessageDomain";
import {MESSAGE_STATUSES} from "../../Util/Constants";
import AccountDomain from "../../Domain/AccountDomain";
import CommonTable from '../../Component/CommonTable';
import { LogModal } from '../../Component/LogModal';
import { LogResrouceType } from '../../Interface/LogInterface';
import { ListItem } from './LogListItem';
import { LogModalRef } from '../../Component/LogModal/type';

interface Props {
    loading?: boolean,
    items: Array<MessageDomain>
    account: AccountDomain
    total: number,
    pageSize: number,
    currentPage: number,
    onChangePage: (page: number, pageSize?: number) => void,
    onRetryAnalyticMessage: (message: MessageDomain) => void,
    onModalCreatePaymentVoucherVisibleChange: (messageId: string, visible: boolean) => void,
    onModalCreateReceiptVoucherVisibleChange: (messageId: string, visible: boolean) => void,
    onModalUpdateRemarkVisibleChange: (message: MessageDomain, visible: boolean) => void
}

interface State {

}

const renderTooltipStatus = () => {
    return (
        <div>
            {Object.keys(MESSAGE_STATUSES).map(status => (
                <div>{status}: {lodash.get(MESSAGE_STATUSES, status)}</div>
            ))}
        </div>
    )
};

class DataList extends Component<Props, State> {
    logRef = createRef<LogModalRef>()

    state = {
        projects: [],
        columns: [
        {
            title: "TK quỹ / Mã GD / Mã sms",
            key: "transactionAccount",
            render: (row: any) => (
                <div className={'font-size-14 data'}>
                    <div className={'message-table-row-item'}>
                        <Tooltip
                            title={"Tài khoản quỹ"}><i className="fa-regular fa-money-check-dollar"/>
                            <span className={'text-dark-2'}>{this.props.account.name} ({lodash.get(row, 'account')})</span>
                        </Tooltip>
                        <Typography.Paragraph className={''} copyable={{
                            text: lodash.get(row, 'account'),
                            icon: [<i className="far fa-copy"/>, <i className="fa-solid fa-check"/>]
                        }}/>
                    </div>
                    <div className={'message-table-row-item'}>
                        <Tooltip title={"Mã giao dịch"}><i className="fa-regular fa-rectangle-barcode"/> {row.transaction ?
                            <Link className={'font-14px blue-link'}
                                  to={`/transactions?query=${row.transaction}`}>{row.transaction}</Link> : '--'}
                        </Tooltip>
                        <Typography.Paragraph className={'flr'} copyable={{
                            text: row.transaction,
                            icon: [<i className="far fa-copy"/>, <i className="fa-solid fa-check"/>]
                        }}/>
                    </div>
                    <div className={'message-table-row-item'}>
                        <Tooltip title={"Mã tin nhắn"}><i className="fa-regular fa-message-sms"/>
                            <span className={'text-dark-2'}>{lodash.get(row, 'id')}</span>
                        </Tooltip>
                        <Typography.Paragraph className={'flr'} copyable={{
                            text: lodash.get(row, 'id'),
                            icon: [<i className="far fa-copy"/>, <i className="fa-solid fa-check"/>]
                        }}/>
                    </div>
                </div>
            )
        },
        {
            title: "Nhận sms/Hệ thống nhận",
            key: "time",
            render: (row: any) => (
                <div className={'font-size-14'}>
                    <div>
                        <Tooltip title={'Nhận sms'}>
                            {moment(row.messageIncomingAt).format("DD/MM/YYYY HH:mm:ss")}
                        </Tooltip>
                    </div>
                    <div>
                        <Tooltip title={'Hệ thống nhận'}>
                            {moment(row.timestamp).format("DD/MM/YYYY HH:mm:ss")}
                        </Tooltip>
                    </div>
                </div>
            )
        },
        {
            title: "Hệ thống/Username",
            key: "username",
            render: (record: any) => {
                const customer = lodash.get(record, 'messageData.dataCustomer');
                const project = lodash.get(record, 'messageData.dataProject');

                let customerLink = `/transactions?participant=c:${project}:${customer}&participantType=inside&project=${project}&participantUsername=${customer}`

                return (
                    <div>
                        <div className={'font-size-14'}><Tooltip title={'Hệ thống'}>{project}</Tooltip></div>
                        <div className={'font-size-14'}><Tooltip title={'Username'}>{customerLink ? <Link target={'_blank'} to={customerLink}>{customer}</Link> : customer}</Tooltip></div>
                    </div>
                )
            }
        },
        {
            title: "Thiết bị/Sim/Sender",
            key: "device",
            render: (row: any) => {
                return (
                    <>
                        <div className={'font-size-14 message-table-row-item'}>
                            <Tooltip title={'Thiết bị'}>
                                <i className="fa-regular fa-desktop"/> <span className={'text-dark-2'}>{lodash.get(row, 'device.name')}</span>
                            </Tooltip>
                            <Typography.Paragraph className={''} copyable={{
                                text: lodash.get(row, 'device.name'),
                                icon: [<i className="far fa-copy"/>, <i className="fa-solid fa-check"/>]
                            }}/>
                        </div>
                        <div className={'font-size-14 message-table-row-item'}>
                            <Tooltip title={'Sim'}><i className="fa-regular fa-sim-card pd-l-2"/><span className={'text-dark-2 mg-l-6'}>{lodash.get(row, 'sim')}</span></Tooltip>
                            <Typography.Paragraph className={''} copyable={{
                                text: lodash.get(row, 'sim'),
                                icon: [<i className="far fa-copy"/>, <i className="fa-solid fa-check"/>]
                            }}/>
                        </div>
                        <div className={'font-size-14 message-table-row-item'}>
                            <Tooltip title={'Sender'}><i className="fa-regular fa-paper-plane"/> <span className={'text-dark-2'}>{lodash.get(row, 'sender')}</span> </Tooltip>
                            <Typography.Paragraph className={''} copyable={{
                                text: lodash.get(row, 'sender'),
                                icon: [<i className="far fa-copy"/>, <i className="fa-solid fa-check"/>]
                            }}/>
                        </div>
                    </>
                )
            }
        },
        {
            title: "Nội dung",
            key: "content",
            dataIndex: "content",
            render: (content: any, row: any) =>
                <>
                    <div className="font-size-14 width-200px h-scroll">{content}</div>
                    <div className="font-12px text-danger">Ghi chú GD: <b className={'italic'}>{row.remark}</b></div>
                </>
        },
        {
            title: <span>Trạng thái <Tooltip
                title={renderTooltipStatus()}><QuestionCircleOutlined/></Tooltip></span>,
            key: "status",
            dataIndex: "status",
            render: (status: any) => {
                let color = 'default';
                if (status === 'SUCCESS') {
                    color = 'success';
                } else if (status === 'FAILED') {
                    color = 'error';
                } else if (status === 'REJECTED') {
                    color = 'warning'
                }
                return (
                    <div>
                        <div><Tag color={color}>{status}</Tag></div>
                    </div>
                )
            }
        },
        {
            title: "Lý do lỗi cuối",
            key: "failedReason",
            dataIndex: "lastFailedReason",
            render: (lastFailedReason: any) => (
                <div className={'align-center justify-center'}>
                    {lastFailedReason && (
                        <Tooltip title={`${lastFailedReason}`} placement={'top'}>
                            <i className="fa-regular fa-eye pointer " style={{color: '#339DFF'}}/>
                        </Tooltip>
                    )}
                </div>
            )
        },
        {
            title: "Tiến trình",
            key: "process",
            render: (row: any) => {
                if (row.process === 'PROCESSED') {
                    return <Link to={`/transactions?query=${row.transaction}`}>{row.process}</Link>
                } else {
                    return <span>{row.process}</span>
                }
            }
        },
        {
            title: '',
            width: 48,
            key: "log",
            render: (row: MessageDomain) => (
                <Button
                    type="link"
                    className="text-blue font-size-12 underline"
                    onClick={() => this.openLogModal(row)}
                >
                    <i className="fa-regular fa-file-lines pd-r-4"/> Log
                </Button>
            )
        },
        {
            title: '',
            width:48,
            key: "actions",
            render: (row: any) => {
                return (
                    <div className={'message-container__data-list-action-col'}>
                        {!row.transaction && (
                            <div>
                                <Tooltip title={'Phân tích lại'} color={'#FDB924'} placement={'left'}>
                                    <div className={'message-container__data-list__action'}
                                         onClick={this.handleRetryAnalyticMessage.bind(this, row)}
                                         style={{backgroundColor: '#FDB924'}}>
                                        <i className="fa-solid fa-arrow-rotate-right"/>
                                    </div>
                                </Tooltip>
                                <Tooltip title={'Tạo phiếu thu'} color={'#339DFF'} placement={'left'}>
                                    <div className={'message-container__data-list__action'}
                                         onClick={() => this.props.onModalCreateReceiptVoucherVisibleChange(row.id, true)}
                                         style={{backgroundColor: '#339DFF'}}>
                                        <i className="fa-solid fa-scroll"/>
                                    </div>
                                </Tooltip>
                                <Tooltip title={'Tạo phiếu chi'} color={'#4DD69C'} placement={'left'}>
                                    <div className={'message-container__data-list__action'}
                                         onClick={() => this.props.onModalCreatePaymentVoucherVisibleChange(row.id, true)}
                                         style={{backgroundColor: '#4DD69C'}}>
                                        <i className="fa-solid fa-memo"/>
                                    </div>
                                </Tooltip>
                            </div>
                        )}
                        <div>
                            <Tooltip title={'Ghi chú giao dịch'} color={'#3340B6'} placement={'left'}>
                                <div className={'message-container__data-list__action'}
                                     onClick={() => this.props.onModalUpdateRemarkVisibleChange(row, true)}
                                     style={{backgroundColor: '#3340B6'}}>
                                    <i className="fa-solid fa-note"/>
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                )
            }
        }
    ]
    }

    handleRetryAnalyticMessage = (message: MessageDomain) => {
        this.props.onRetryAnalyticMessage(message);
    }

    handleChangePage = (page: number, pageSize ?: number) => {
        this.props.onChangePage(page, pageSize)
    }

    openLogModal = (record: any) => {
        const { account } = this.props
        this.logRef.current?.setLogQuery({
            account: account.code,
            resourceType: LogResrouceType.SMS,
            resourceCode: record.id,
        })
    }

    render() {
        const {loading, total, pageSize, items, currentPage} = this.props;
        const {columns} = this.state

        return (
            <>
                <CommonTable
                    columns={columns}
                    dataSource={items}
                    loading={loading}
                    rowKey={() => lodash.uniqueId()}
                    pagination={{
                        current: currentPage,
                        total: total,
                        pageSize: pageSize,
                        hideOnSinglePage: false,
                        onChange: this.handleChangePage,
                        pageSizeOptions:[10,20,25,50,100],
                        showLessItems:true,
                        showSizeChanger:true
                    }}
                    rowClassName={'message-table-row'}
                />

                <LogModal
                    ref={this.logRef}
                    title="Log tin nhắn"
                    ListItemContent={ListItem}
                    isShowResourceCodeOnTitle={true}
                />
            </>
        );
    }
}

export default DataList;
