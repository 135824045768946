import {Form, FormInstance, Input, Modal, Radio, Select, Switch} from 'antd'
import lodash from 'lodash'
import React from 'react'
import AccountApi from '../../Api/AccountApi'
import BankApi from '../../Api/BankApi'
import DefaultComponent from '../../Component/DefaultComponent'
import {NotificationCommon} from '../../Component/Notification'
import BankDomain from '../../Domain/BankDomain'
import DefaultPropsInterface from '../../Interface/DefaultPropsInterface'
import {ACCOUNT_TYPES} from '../../Util/Constants'

interface FormInterface {
    code: string
    name: string
    type: string
    bank?: string
    accountNumber: string
    accountHolder: string
    currency: string
    description: string
    status: string
}

interface Props extends DefaultPropsInterface {
    code: any
    onUpdateSuccess: () => void
}

interface State {
    loading: boolean
    form: FormInterface
    disableSubmit: boolean
}

class Update extends DefaultComponent<Props, State> {
    formRef = React.createRef<FormInstance>()

    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            form: {},
            disableSubmit: true,
            banks: [],
        }
    }

    componentDidMount(): void {
        this.setState({loading: true})

        this.fetchAccount()

        this.fetchBanks()
    }

    fetchAccount = () => {
        AccountApi.findByCode(this.getCode())
            .then((response) => {
                const account = response.data
                let form = {...account}
                lodash.unset(form, 'currency')
                form.currency = lodash.get(account, 'currency.code')
                form.bank = lodash.get(account, 'bank.code')
                this.setState({
                    form,
                })
                this.formRef.current?.setFieldsValue(form)
            })
            .finally(() => {
                this.setState({loading: false})
            })
    }

    fetchBanks = () => {
        this.setState({loading: true})
        BankApi.filter({})
            .then((response: any) => {
                this.setState({
                    banks: response.data,
                })
            })
            .finally(() => {
                this.setState({loading: false})
            })
    }

    getCode = () => {
        return this.props.code
    }

    validateValues() {
        const formInst: FormInterface = this.state.form
        const {type, name, currency, bank} = formInst

        const isError: boolean =
            type === 'CASH' ? !name || !currency : !name || !currency || !bank
        this.setState({
            disableSubmit: isError,
        })
    }

    handleChangeInput = (field: string, e: any) => {
        this.setState(
            {
                form: {
                    ...this.state.form,
                    [field]: e.target.value,
                },
            },
            () => {
                this.validateValues()
            }
        )
    }

    handleChangeSelect = (field: string, value: any) => {
        this.setState({
            form: {
                ...this.state.form,
                [field]: value,
            },
        }, () => {
            this.validateValues()
        })
    }

    handleSubmit = () => {

        const data = {
            ...this.state.form,
            bank:
                typeof this.state.form.bank === 'object'
                    ? this.state.form.bank.code || null
                    : this.state.form.bank,
        }
        if (this.state.form.name && this.state.form.name.length > 0) {
            this.setState({loading: true})
            AccountApi.update(this.props.code, data)
                .then(() => {
                    this.props.onUpdateSuccess()
                })
                .catch((error) => {
                    if (error.response.status === 400) {
                        NotificationCommon.error({
                            message: 'Vui lòng kiểm tra thông tin trên form',
                        })
                    } else {
                        NotificationCommon.error({
                            message: 'Có lỗi xảy ra, vui lòng thử lại',
                        })
                    }
                }).finally(() => {
                this.setState({loading: true})
            })
        } else {
            NotificationCommon.error({
                message: 'Vui lòng kiểm tra thông tin trên form',
            })
        }

    }

    handleCancel = () => {
        this.props.onVisibleChange(false)
    }

    render(): React.ReactNode {
        const form: FormInterface = this.state.form
        const {loading, banks, disableSubmit} = this.state

        return (
            <Modal
                title={
                    <div className="flex">
                        <span>Chỉnh Sửa Thông Tin Quỹ - <span className='no-wrap'>{form.code}</span></span>
                        <Switch
                            size={'small'}
                            className={'mg-l-8 mg-t-4'}
                            checked={form.status === 'ACTIVE'}
                        />
                    </div>
                }
                visible={true}
                width={660}
                okText={'Xác Nhận'}
                closeIcon={<i className="fa-solid fa-xmark"/>}
                cancelText={'Hủy Bỏ'}
                centered
                onOk={this.handleSubmit}
                onCancel={this.handleCancel}
                okButtonProps={{
                    loading: loading,
                    disabled: loading || disableSubmit,
                    icon: <i className="fa-solid fa-check pd-r-8"/>,
                }}
                cancelButtonProps={{
                    disabled: loading,
                    icon: <i className="fa-solid fa-xmark pd-r-8"/>,
                    type: 'ghost',
                }}
            >
                <Form labelCol={{span: 4}} labelAlign="left" ref={this.formRef}>
                    <Form.Item>
                        <Radio.Group
                            name={'type'}
                            value={form.type}
                            onChange={this.handleChangeInput.bind(this, 'type')}
                        >
                            {ACCOUNT_TYPES.map((item) => (
                                <Radio key={item.key} disabled value={item.key}>
                                    {item.text}
                                </Radio>
                            ))}
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        label={'Tên tài khoản'}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Tên tài khoản không được để trống',
                            },
                        ]}
                    >
                        <Input
                            value={form.name}
                            placeholder="Vui lòng nhập tên tài khoản"
                            onChange={this.handleChangeInput.bind(this, 'name')}
                        />
                    </Form.Item>

                    <Form.Item
                        label={'Đơn vị tiền tệ'}
                        name="currency"
                        rules={[
                            {
                                required: true,
                                message: 'Đơn vị tiền tệ không được để trống',
                            },
                        ]}
                    >
                        <Select
                            value={lodash.get(form, 'currency')}
                            placeholder="Vui lòng chọn đơn vị tiền tệ"
                            onChange={this.handleChangeSelect.bind(
                                this,
                                'currency'
                            )}
                        >
                            <Select.Option key={'vnd'} disabled value={'vnd'}>
                                {'VND'}
                            </Select.Option>
                        </Select>
                    </Form.Item>

                    {form.type === 'BANK' && (
                        <>
                            <Form.Item
                                label={'Ngân hàng'}
                                name="bank"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Ngân hàng không được để trống',
                                    },
                                ]}
                            >
                                <Select
                                    value={form?.bank}
                                    placeholder="Vui lòng chọn ngân hàng"
                                    onChange={this.handleChangeSelect.bind(
                                        this,
                                        'bank'
                                    )}
                                >
                                    {banks.map((item: BankDomain) => (
                                        <Select.Option
                                            key={item.code}
                                            value={item.code}
                                        >
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>

                            <Form.Item label={'Số tài khoản'}>
                                <Input
                                    value={form.accountNumber}
                                    placeholder="Vui lòng nhập số tài khoản"
                                    onChange={this.handleChangeInput.bind(
                                        this,
                                        'accountNumber'
                                    )}
                                />
                            </Form.Item>

                            <Form.Item label={'Chủ tài khoản'}>
                                <Input
                                    value={form.accountHolder}
                                    placeholder="Vui lòng nhập chủ tài khoản"
                                    onChange={this.handleChangeInput.bind(
                                        this,
                                        'accountHolder'
                                    )}
                                />
                            </Form.Item>
                        </>
                    )}

                    <Form.Item label={'Ghi chú'} className="mg-bt-0">
                        <Input.TextArea
                            value={form.description}
                            placeholder="Vui lòng nhập ghi chú"
                            onChange={this.handleChangeInput.bind(
                                this,
                                'description'
                            )}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

export default Update
