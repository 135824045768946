import {Layout, Spin} from 'antd';
import lodash from "lodash";
import React, {Component} from 'react';
import DocumentTitle from "react-document-title";
import AccountApi from "../../Api/AccountApi";
import {AppContext} from "../../Context/AppContext";
import AccountDomain, {AccountModel} from "../../Domain/AccountDomain";
import UserDomain from "../../Domain/UserDomain";
import DefaultPropsInterface from "../../Interface/DefaultPropsInterface";
import localStore from "../../Util/LocalStore";
import SecurityService from "../../Util/SecurityService";
import Utils from "../../Util/Utils";
import Header, { BreadcrumbInterface } from "./Header";
import SideBar from "./Sidebar";
import ProfileApi from "../../Api/ProfileApi";


const {Content} = Layout;

interface Props extends DefaultPropsInterface {
    loading?: boolean
    breadcrumb?: BreadcrumbInterface[],
}

interface State {
    account: AccountDomain,
    accounts: Array<AccountDomain>,
    loading: boolean,
    collapsed: boolean,
    user: UserDomain | null
}

class DefaultLayout extends Component<Props, State> {

    static defaultProps = {
        loading: false
    };

    static contextType = AppContext;

    state: State = {
        account: Utils.getAppContext(this).state.account,
        accounts: [],
        loading: false,
        collapsed: true,
        user: SecurityService.getUser()
    }

    componentDidMount() {
        this.fetchMyAccounts();
        if (this.state.account.code) {
            this.fetchAccountByCode(this.state.account.code);
        }

        this.fetchPermissions();
    }

    fetchPermissions = () => {
        ProfileApi.getPermissions()
            .then(response => {
                localStore.setJson('permissions', response.data);
            })
    }

    fetchMyAccounts = (query = {}) => {
        query = {
            sort:'name:ASC',
            offset: 0,
            limit: 1000,
            ...query
        }
        this.setState({loading: true});
        AccountApi.autocomplete(query)
            .then(response => {
                this.setState({
                    accounts: response.data
                });

                if (response.data.length) {
                    if (!this.state.account.code) {
                        this.fetchAccountByCode(lodash.get(response.data, '0.code'));
                    }
                }
            })
            .finally(() => {
                this.setState({loading: false});
            })
    };

    fetchAccountByCode = (code: string) => {
        if (code) {
            AccountApi.findByCode(code)
                .then(response => {
                    this.setState({account: response.data});
                    localStore.setJson('account', response.data);

                    Utils.getAppContext(this).func.updateAccount(response.data);
                })
                .catch(() => {
                    localStore.removeItem('account');
                    Utils.getAppContext(this).func.updateAccount({...AccountModel})
                })
        }
    }

    handleChangeAccount = (accountCode: string) => {
        this.fetchAccountByCode(accountCode);
        this.setState({loading: true});
        setTimeout(() => {
            window.location.reload();
        }, 500);
    }

    handleSearchAccount = (value: any) => {
        if (value && value.length >= 3) {
            const accounts = this.state.accounts.filter((account: any) => {
                return lodash.concat(account.name, account.code).toString().toLowerCase().indexOf(value.toLowerCase()) >= 0
            });
            this.setState({
                accounts
            });
            this.fetchMyAccounts({name:value});
        } else if (!value) {
            this.fetchMyAccounts();
        }
    }

    toggle = (callback?: () => void) => {
        this.setState({
            ...this.state,
            collapsed: !this.state.collapsed,
        }, () => {
            if (typeof callback === 'function') {
                callback()
            }
        });
    };

    render() {
        const {loading, breadcrumb} = this.props;
        const {collapsed, user, account, accounts} = this.state;

        return (
            <DocumentTitle
                title={this.props.hasOwnProperty('title') && this.props.title !== undefined ? this.props.title : 'M7 Hamel'}>
                <Layout className={"main-layout"}>
                    <SideBar
                        collapsed={collapsed}
                        history={this.props.history}
                        match={this.props.match}
                        toggle={this.toggle}
                        />
                    <Layout>
                        <Header
                            collapsed={collapsed}
                            toggle={this.toggle}
                            user={user}
                            account={account}
                            accounts={accounts}
                            breadcrumb={breadcrumb}
                            onChangeAccount={this.handleChangeAccount}
                            onSearchAccount={this.handleSearchAccount}
                            {...this.props}
                        />
                        <Content className={"main-content "}>
                            <div className={loading ? 'loading-container ' : 'main-inner '}>
                                <Spin tip={'Đang tải...'} spinning={loading} className={""}>
                                    {this.props.children}
                                </Spin>
                            </div>
                        </Content>
                    </Layout>
                </Layout>
            </DocumentTitle>
        )
    }
}

export default DefaultLayout
