import { Button, Col, Form, Input, Row, Select } from 'antd'
import { Component } from 'react'
import DefaultPropsInterface from '../../Interface/DefaultPropsInterface'
import Utils from '../../Util/Utils'
import { DEVICE_STATUSES } from '../../Util/Constants'

interface FormInterface {
    code?: string
    name?: string
    pairingToken?: string
    wlanMac?: string
    bluetoothMac?: string
    status?: string
    os?: string
    deviceId?: string
    secret?: string
}

interface Props extends DefaultPropsInterface {}

interface State {
    form: FormInterface
    isShow: boolean
}

class FilterForm extends Component<Props, State> {
    state = {
        form: {
            ...Utils.getQueryFromLocation(this.props.location),
        },
        isShow: false,
    }

    handleChangeInput = (field: string, e: any) => {
        const form: any = this.state.form
        form[field] = e.target.value
        this.setState({ form })
    }

    handleChangeSelect = (field: string, value: any) => {
        const form: any = this.state.form
        form[field] = value
        this.setState({ form })
    }

    handleFilter = () => {
        Utils.pushCleanQueryToHistory(this.props.history, this.props.location, this.state.form)
    }

    clearFilter = () => {
        this.setState({
            form: {},
        })
        Utils.pushCleanQueryToHistory(this.props.history, this.props.location, {})
    }

    toggleFilterShow = () => {
        this.setState({ isShow: !this.state.isShow })
    }

    render() {
        const form: FormInterface = this.state.form
        const { isShow } = this.state

        const disbaleSubmit: boolean = Utils.checkDisableSubmit(form)

        return (
            <>
                <div
                    className="font-medium fsz-16px capitalize title pointer space-between"
                    onClick={this.toggleFilterShow}
                >
                    Tìm kiếm
                    <span className="text-dark-color">
                        {isShow ? <i className="fa-solid fa-chevron-up" /> : <i className="fa-solid fa-chevron-down" />}
                    </span>
                </div>
                {isShow && (
                    <Form className=" bottom-item-12 pd-t-12" labelAlign="left">
                        <Row gutter={12}>
                            <Col xs={24} lg={8}>
                                <Form.Item label={'Tên thiết bị'} className={'label-width-medium'}>
                                    <Input
                                        placeholder={'Tên thiết bị'}
                                        value={form.name}
                                        onChange={this.handleChangeInput.bind(this, 'name')}
                                        onPressEnter={() => this.handleFilter()}
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={24} lg={8}>
                                <Form.Item label={'Trạng thái'} className={'label-width-medium'}>
                                    <Select
                                        value={form.status}
                                        placeholder={'Trạng thái'}
                                        onChange={this.handleChangeSelect.bind(this, 'status')}
                                        allowClear={true}
                                    >
                                        {DEVICE_STATUSES.map((item) => (
                                            <Select.Option key={item.code} value={item.code}>
                                                {item.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={8}>
                                <Form.Item label={'Hệ điều hành'} className={'label-width-medium'}>
                                    <Input
                                        placeholder={'Hệ điều hành'}
                                        value={form.os}
                                        onChange={this.handleChangeInput.bind(this, 'os')}
                                        onPressEnter={() => this.handleFilter()}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <div className="text-right">
                            {!disbaleSubmit && (
                                <Button
                                    type={'text'}
                                    onClick={this.clearFilter}
                                    icon={<i className="fa-solid fa-repeat pd-r-4" />}
                                    className="font-size-12px"
                                >
                                    Làm mới bộ lọc
                                </Button>
                            )}
                            <Button disabled={disbaleSubmit} type="primary" onClick={this.handleFilter}>
                                Tìm kiếm
                            </Button>
                        </div>
                    </Form>
                )}
            </>
        )
    }
}

export default FilterForm
