import lodash from 'lodash';
import moment from "moment";
import {Component} from "react";
import CommonTable from '../../Component/CommonTable';
import MemoDomain from "../../Domain/MemoDomain";

interface Props {
    loading?: boolean,
    items: Array<MemoDomain>
    total: number,
    pageSize: number,
    currentPage: number,
    onChangePage: (page: number, pageSize?: number) => void
}

interface State {
    
}

class DataList extends Component<Props, State> {

    state = {
        projects: [],
        columns: [
            {
                title: "Hệ thống",
                key: "project",
                render: (record: any) => {
                    return record.project
                }
            },
            {
                title: "Cú pháp",
                key: "placeholder",
                render: (row: any) => row.placeholder
            },
            {
                title: "Ngày tạo",
                key: "createdAt",
                render: (row: any) => moment(row.createdAt).format('DD/MM/YYYY HH:mm:ss')
            },
            {
                title: "Người tạo",
                key: "createdBy",
                dataIndex: "createdBy",
                render: (value: any) => value ? value : '--'
            },
            {
                title: "Cập nhật lần cuối",
                key: "updatedBy",
                render: (row: any) => {
                    if (row.modifiedBy || row.modifiedAt) {
                        return <div>{row.modifiedBy ? <span>Bởi<b> {row.modifiedBy}</b></span> : ''} {row.modifiedAt ?
                            <span>lúc {moment(row.modifiedAt).format("DD/MM/YYYY")}</span> : ""}</div>
                    }
                    return '--'
                }
            }
        ]
    }

    handleChangePage = (page: number, pageSize ?: number) => {
        this.props.onChangePage(page, pageSize)
        window.scroll({top: 0, behavior: 'smooth'})
    }

    render() {
        const {loading, total, pageSize, items, currentPage} = this.props;
        const {columns} = this.state;
        return (
            <div className={''}>
                <CommonTable
                    className={''}
                    columns={columns}
                    dataSource={items}
                    loading={loading}
                    rowKey={() => lodash.uniqueId()}
                    pagination={{
                        current: currentPage,
                        total: total,
                        pageSize: pageSize,
                        // hideOnSinglePage: true,
                        onChange: this.handleChangePage,
                        showSizeChanger:true,
                        pageSizeOptions:[10,20,25,50,100],
                        showLessItems:true,
                    }}
                    rowClassName={'memo-record'}
                    scroll={{y:window.innerHeight-240,x:768}}
                />
            </div>
        );
    }
}

export default DataList;
