import React from 'react';
import DefaultPropsInterface from '../../../Interface/DefaultPropsInterface';
import {Col, Form, Row} from 'antd';
import lodash, {get} from 'lodash';
import moment from 'moment';
import Utils from '../../../Util/Utils';
import Clipboard from '../../../Component/Clipboard';
import Paragraph from 'antd/lib/typography/Paragraph';
import {REVIEW_STATUSES_CODE, STATUSES_CODE} from "../../../Util/Constants";

interface Props extends DefaultPropsInterface {
    paymentVoucher: any;
    formatInvoiceLink: () => any;
}

interface State {
}

class PaymentVoucherReadOnly extends React.Component<Props, State> {
    renderInvoice = () => {
        const paymentVoucher: any = this.props.paymentVoucher;
        if (!paymentVoucher.invoice) {
            return null;
        }

        const copyComponent = (
            <Clipboard
                value={get(paymentVoucher, 'invoice')}
                successMessage={'Đã copy'}
            />
        );
        if (paymentVoucher.invoice && this.props.formatInvoiceLink()) {
            return (
                <div>
                    <a
                        rel={'noreferrer'}
                        target={'_blank'}
                        href={this.props.formatInvoiceLink()}
                    >
                        {paymentVoucher.invoice}
                    </a>{' '}
                    {copyComponent}
                </div>
            );
        }

        return (
            <span className={'font-medium'}>
                {paymentVoucher.invoice} {copyComponent}
            </span>
        );
    };

    renderReceiver = () => {
        const paymentVoucher: {} = this.props.paymentVoucher;
        if (lodash.get(paymentVoucher, 'receiverRef.fullname')) {
            return `${lodash.get(
                paymentVoucher,
                'receiverRef.username'
            )} (${lodash.get(paymentVoucher, 'receiverRef.fullname')})`;
        }

        return (
            lodash.get(paymentVoucher, 'receiverRef.username') ||
            lodash.get(paymentVoucher, 'receiver')
        );
    };

    getStatusName = (code: string) => {
        let status: any = STATUSES_CODE.find((x) => x.code === code)
        if (status) return status.name
    }

    render() {
        const paymentVoucher: any = this.props.paymentVoucher;
        return (
            <Form
                labelCol={{xs: 6,lg:8}}
                labelAlign={'left'}
                className={'payment-infos horizontal-form'}
            >
                <Row>
                    <Col xs={24} lg={12}>
                        <Form.Item label={'Tài khoản quỹ'}>
                    <span className={'font-medium'}>
                        {get(paymentVoucher, 'account.name')} (
                        {get(paymentVoucher, 'account.code')})
                    </span>
                        </Form.Item>

                        <Form.Item label={'Thời gian tạo'}>
                    <span className={'font-medium'}>
                        {get(paymentVoucher, 'preparedAt')
                            ? moment(get(paymentVoucher, 'preparedAt')).format(
                                'DD/MM/YYYY HH:mm:ss'
                            )
                            : '--'}
                    </span>
                        </Form.Item>

                        <Form.Item label={'Người tạo'}>
                    <span className={'font-medium'}>
                        {get(paymentVoucher, 'preparedBy.username')
                            ? get(paymentVoucher, 'preparedBy.username')
                            : '--'}
                    </span>
                        </Form.Item>

                        {get(paymentVoucher,'status.code') !== 'REJECTED' && <>
                            <Form.Item label={'Thời gian xác nhận'}>
                        <span className={'font-medium'}>
                            {get(paymentVoucher, 'approvedAt')
                                ? moment(get(paymentVoucher, 'approvedAt')).format(
                                    'DD/MM/YYYY HH:mm:ss'
                                )
                                : '--'}
                        </span>
                            </Form.Item>

                            <Form.Item label={'Người xác nhận'}>
                        <span className={'font-medium'}>
                            {get(paymentVoucher, 'approvedBy.username')
                                ? get(paymentVoucher, 'approvedBy.username')
                                : '--'}
                        </span>
                            </Form.Item>
                        </>}

                        <Form.Item label={'Trạng thái'}>
                    <span className={'font-medium'}>
                      {this.getStatusName(get(paymentVoucher, 'status.code'))}
                    </span>
                        </Form.Item>

                        {get(paymentVoucher, 'reviewStatus') !== 'PENDING' && <>
                            <Form.Item label={'Trạng thái phiếu'}>
                        <span className={'font-medium'}>
                             {paymentVoucher && paymentVoucher.reviewStatus ?
                                 REVIEW_STATUSES_CODE.find((x) => paymentVoucher.reviewStatus === x.code)?.name : "--"}
                        </span>
                            </Form.Item>

                            <Form.Item label={'Thời gian duyệt'}>
                        <span className={'font-medium'}>
                            {get(paymentVoucher, 'reviewedAt')
                                ? moment(get(paymentVoucher, 'reviewedAt')).format(
                                    'DD/MM/YYYY HH:mm:ss'
                                )
                                : '--'}
                        </span>
                            </Form.Item>

                            <Form.Item label={'Người duyệt phiếu'}>
                        <span className={'font-medium'}>
                            {get(paymentVoucher, 'reviewedBy.username')
                                ? get(paymentVoucher, 'reviewedBy.username')
                                : '--'}
                        </span>
                            </Form.Item>

                        </>}
                    </Col>
                    <Col xs={24} lg={12}>
                        <Form.Item label={'Mã giao dịch'}>
                            <Paragraph
                                className={'font-medium'}
                                copyable={
                                    get(paymentVoucher, 'transaction') && {
                                        icon: (
                                            <i
                                                className="fa-regular fa-copy"
                                                style={{
                                                    color: '#7D7E7E',
                                                    fontSize: 12,
                                                }}
                                            />
                                        ),
                                        text: get(paymentVoucher, 'transaction')
                                    }
                                }
                            >
                                {get(paymentVoucher, 'transaction') || '--'}
                            </Paragraph>
                        </Form.Item>

                        <Form.Item label={'Người nhận tiền'}>
                            <div className={'font-medium'}>{this.renderReceiver()}</div>
                        </Form.Item>

                        <Form.Item label={'Số tiền'}>
                            <div className={'font-medium'}>
                                {Utils.currencyFormatByCurrency(
                                    get(paymentVoucher, 'amount'),
                                    get(paymentVoucher, 'currency.code')
                                )}{' '}
                                {get(paymentVoucher, 'currency.code')}
                            </div>
                        </Form.Item>

                        <Form.Item label={'Nội dung giao dịch'}>
                            <div className={'font-medium'}>
                                {get(paymentVoucher, 'memo')}
                            </div>
                        </Form.Item>

                        <Form.Item label={'Ghi chú'}>
                            <div className={'font-medium'}>
                                {get(paymentVoucher, 'remark')}
                            </div>
                        </Form.Item>

                        <Form.Item label={'Thời gian giao dịch'}>
                            <div className={'font-medium'}>
                                {moment(get(paymentVoucher, 'timestamp')).format(
                                    'DD/MM/YYYY HH:mm:ss'
                                )}
                            </div>
                        </Form.Item>

                        <Form.Item label={'Mã bút toán'}>
                            <Paragraph
                                className={'font-medium'}
                                copyable={
                                    get(paymentVoucher, 'ref') && {
                                        icon: (
                                            <i
                                                className="fa-regular fa-copy"
                                                style={{
                                                    color: '#7D7E7E',
                                                    fontSize: 12,
                                                }}
                                            />
                                        ),
                                        text: get(paymentVoucher, 'ref')
                                    }
                                }
                            >
                                {get(paymentVoucher, 'ref')}
                            </Paragraph>
                        </Form.Item>

                        <Form.Item label={'Số hoá đơn'}>
                            {this.renderInvoice()}
                        </Form.Item>

                        <Form.Item label={'Mục đích chi'}>
                            <div className={'font-medium'}>
                                {get(paymentVoucher, 'paymentReason.name')}
                            </div>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        );
    }
}

export default PaymentVoucherReadOnly;
