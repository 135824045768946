import React from 'react'
import DocumentTitle from "react-document-title"
import lodash from 'lodash';
import queryString from 'query-string';
import axios from 'axios';
import loadingGif from '../../resources/images/loading.gif'
import errorPng from '../../resources/images/error.png'
import DefaultPropsInterface from "../../Interface/DefaultPropsInterface";
import localStore from "../../Util/LocalStore";
import DefaultComponent from "../../Component/DefaultComponent";
import appConfig from "../../Config/App";

interface Props extends DefaultPropsInterface {
    history: any
}

interface State {
    isNotPermission: boolean,
    msg: string
}

class Authentication extends DefaultComponent<Props, State> {

    state = {
        isNotPermission: false,
        msg: 'Đang kiểm tra xác thực, vui lòng đợi'
    };

    componentWillMount () {
        const locationHash = window.location.hash;
        const params: any = queryString.parse(locationHash.substring(1));
        const redirectBackUrl = localStore.getItem('redirectBackUrl') ? localStore.getItem('redirectBackUrl') : '/';

        // reset loginSession
        localStore.setItem('loginSession', null);

        // check authentication
        if (lodash.has(params, 'access_token')) {
            this.setState({msg: "Bạn sẽ được chuyển đến Bảng điều khiển ngay bây giờ"}, () => {
                axios({
                    url: appConfig.apiUrl + '/profile',
                    method: 'GET',
                    headers: {
                        Authorization: 'Bearer ' + params['access_token']
                    }
                })
                    .then((response) => {
                        localStore.setJson('loginSession', {
                            accessToken: params['access_token'],
                            expireAt: params['expire_at']
                        });
                        localStore.setJson('loggedUser', response.data);
                        setTimeout(()=> {
                            // @ts-ignore
                            window.location.href = redirectBackUrl;
                        }, 1000);
                    })
                    .catch(error => {
                        localStore.removeItem('loginSession');
                        localStore.removeItem('loggedUser');
                        this.setState({
                            isNotPermission: true,
                            msg: lodash.get(error, 'response.data.message')
                        })
                    });
            })
        } else {
            this.setState({
                isNotPermission: true,
                msg: "Không thể đăng nhập"
            })
        }
    }

    render() {
        return(
            <DocumentTitle title={`Xác thực`}>
                <div className={'splash-container'}>

                    {
                        !this.state.isNotPermission
                        ?
                        <div className={'text-center margin-auto'}>
                            <img src={loadingGif} alt={""}/>
                            <p className="splash-text txt-color-black2 mgt25">
                                {this.state.msg}
                            </p>
                        </div>
                        :
                        <div className={'text-center margin-auto'}>
                            <div>
                                <img src={errorPng} alt={""}/>
                            </div>
                            <div className="txt-center">
                                <p className="splash-text txt-color-black2 mgt20">
                                    Không thể xác thực
                                </p>
                                <p className="splash-text txt-color-black2 mgt20">
                                    Vui lòng liên hệ với Admin
                                </p>
                                <div className={'mgt50'}>
                                    <a href="/" className="splash-text txt-color-blue2 mg-r-20">
                                        <i className="fas fa-undo-alt mgr5"/> <span>Thử lại</span>
                                    </a>
                                </div>

                            </div>
                        </div>
                    }
                </div>
            </DocumentTitle>
        );
    }
}

export default Authentication;