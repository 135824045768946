import React, {useMemo, useState} from "react";
import {Tooltip} from "antd";
import SettlementModal from "./SettlementModal";
import {AccountModel} from "../../../Domain/AccountDomain";
import {AxiosError} from "axios";
import moment from 'moment';
import lodash, {get} from 'lodash';
import {Link} from "react-router-dom";
import { NotificationCommon } from "../../../Component/Notification";
import {LinkDropdown} from "./LinkDropdown";
import localStore from "../../../Util/LocalStore";

interface currencyProps {
    code: string
}

interface bankProps {
    code: string,
    name: string
}

interface ItemProps {
    index?: string | number,
    status?: string,
    code: string | number,
    currency: currencyProps,
    name: string,
    showAccount: (code: any) => void,
    type: string,
    accountHolder?: string,
    bank?: bankProps,
    accountNumber?: string,
    description?: string,
    note?: string,
    lastSettlementAt?: string,
    lastSettlementBy?: string
}


const Item: React.FC<ItemProps> = (props: ItemProps) => {
    const {
        status,
        currency,
        name,
        code,
        showAccount,
        type,
        accountHolder,
        bank,
        accountNumber,
        description,
        lastSettlementAt,
        lastSettlementBy
    } = props;

    const account: any = useMemo(() => {
        const p: any = {...props}
        delete p.showAccount

        return p
    }, [props])

    const [isShowSettlementModal, setIsShowSettlementModal] = useState(false);
    const [selectedAccount, setSelectedAccount] = useState({...AccountModel});
    const settlementDuration = lodash.get(localStore.getJson('tenant'),'config.settlementDuration')
    const timeToShowAlert = moment().subtract(settlementDuration + 1,'days') > moment(lastSettlementAt)


    const showSettlementModal = (account: any) => {
        setIsShowSettlementModal(true);
        setSelectedAccount(account);
    }

    const handleSettlementModalVisible = (visible: boolean) => {
        setIsShowSettlementModal(visible);
    }

    const handleSettlementSuccess = () => {
        NotificationCommon.success({
            message: "Chốt sổ thành công"
        });

        setIsShowSettlementModal(false);

        setTimeout(() => {
            window.location.reload();
        }, 500);
    }

    const handleSettlementFail = (error: AxiosError) => {
        if (get(error.response, 'status') === 400) {
            if (get(error.response, 'data.title') === 'voucher_status_ineligible') {
                NotificationCommon.error({
                    message: `${get(error.response, 'data.receiptVoucher')} phiếu thu, ${get(error.response, 'data.paymentVoucher')} phiếu chi cần phải được xác nhận hoặc huỷ hết trước khi chốt sổ`
                })
            }
            else if (get(error.response, 'data.title') === 'timestamp_invalid') {
                NotificationCommon.error({
                    message: 'Thời gian chốt không hợp lệ'
                })
            }
            else if (get(error.response, 'data.title') === 'settlement_at_ineligible') {
                NotificationCommon.error({
                    message: `Thời gian chốt sổ cần lớn hơn ${moment(lastSettlementAt).format("DD/MM/YYYY HH:mm")}`
                })
            }
            else {
                NotificationCommon.error({
                    message: error.message
                })
            }
        }
        else {
            NotificationCommon.error({
                message: error.message
            })
        }
    }

    return (
        <div className={"account-item rounded mg-bt-12 bg-white"}>
            <div className={'head'}>
                <div className={(status === "ACTIVE" ? "icon-active" : "icon-inactive") + " self-center"}/>
                <div className={"account-item__name mg-l-8"} style={{width: "100%"}}>
                    <Tooltip title={code}>
                        <div className={"font-size-14 bold text-maxW-250"}>
                            <Link className={'link--no-blue'} to={`/accounts/${code}`}>{code}</Link>
                        </div>
                    </Tooltip>
                    <span className={'mg-l-4'}><i className="fa-solid fa-pipe"/></span>
                    <Tooltip title={'Tên tài khoản'}>
                        <div className={"font-size-14 bold text-maxW-250 mg-l-4"}>
                            <Link className={'link--no-blue'} to={`/accounts/${code}`}>{name}</Link>
                        </div>
                    </Tooltip>
                </div>
                <LinkDropdown account={account}/>
            </div>
            <div className={'body'}>
                {type === "BANK" && <div className={"account-item__bank_info"}>
                    <div className={"row"}>
                        <span className={'label'}>Ngân hàng:</span>
                        <Tooltip placement="topLeft" title={bank?.name}>
                            <div className={'bold text-maxW-300'}>{bank?.name}</div>
                        </Tooltip>
                    </div>
                    <div className={"row"}>
                        <span className={'label'}>Số tài khoản:</span>
                        <Tooltip placement="topLeft" title={accountNumber}>
                            <div className={'bold text-maxW-300'}>{accountNumber}</div>
                        </Tooltip>
                    </div>
                    <div className={"row"}>
                        <span className={'label'}>Chủ tài khoản:</span>
                        <Tooltip placement="topLeft" title={accountHolder}>
                            <div className={'bold text-maxW-300'}>{accountHolder}</div>
                        </Tooltip>
                    </div>
                </div>
                }
            </div>
            <div className="account-item__last_settlement">
                Chốt lần cuối lúc {moment(lastSettlementAt).format("DD/MM/YYYY HH:mm")} bởi {lastSettlementBy}
            </div>
            <div className={"bottom"}>
                <div className={"px-10 account-item__bottom__currency"}>
                    {currency.code}
                </div>
                <div className={'account-item__bottom__actions pd-bt-4'}>
                    <span onClick={() => showSettlementModal(props)} className={'font-size-12 pointer mg-r-12 '} style={{ color: '#148F14' }}>
                        <i className="fa-regular fa-pen-to-square pd-r-4 "/>Chốt Sổ
                    </span>
                    <span  onClick={() => showAccount(code)} className={'font-size-12 pointer'} style={{ color: '#148F14' }} >
                        <i className="fa-regular fa-circle-info pd-r-4"/>Thông Tin Quỹ
                    </span>
                    {description && description?.length > 0 &&
                        <Tooltip title={description} arrowPointAtCenter placement="topRight">
                            <span className="mg-l-8 px-4 text-secondary-color-2 pointer">
                                <i className="fa-solid fa-note-sticky"/>
                            </span>
                        </Tooltip>}
                    {timeToShowAlert && <Tooltip
                        title={<div> Hệ thống sẽ tự động chốt sổ vào thời điểm <span className={'bold'}>{moment().subtract(settlementDuration,'days').format('DD/MM/YYYY')}!</span></div>}
                        placement="topRight" className={'account-item__description mg-l-4'}
                    >
                        <i className="fa-regular fa-triangle-exclamation text-yellow pointer"/>
                    </Tooltip>}
                </div>
            </div>

            {isShowSettlementModal && (
                <SettlementModal
                    onVisibleChange={handleSettlementModalVisible}
                    onSuccess={handleSettlementSuccess}
                    onFail={handleSettlementFail}
                    account={selectedAccount}
                />
            )}
        </div>
    );
}

export default Item;
