export const ACCOUNT_TYPES = [
    {key: "CASH", text: "Tiền mặt"},
    {key: "BANK", text: "Ngân hàng"}
];

export const PARTICIPANT_TYPES = [
    {key: "inside", text: "Trong hệ thống"},
    {key: "outside", text: "Ngoài hệ thống"}
];

export const MESSAGE_STATUSES = {
    NEW: "Vừa nhận được từ app",
    RAW_PROCESSING: "Đọc các thông tin cơ bản",
    MEMO_PROCESSING: "Phân tích được memo",
    SUCCESS: "Hoàn thành",
    FAILED: "Lỗi",
    REJECTED: "Không chấp nhận"
}

export const DEVICE_STATUSES = [
    {code: "PAIRING", name: "Chờ kết nối", color: "#FDB924"},
    {code: "CONNECTED", name: "Đã kết nối", color: "#4DD69C"},
    {code: "DISABLED", name: "Ngắt kết nối", color: "#FF4559"}
];

export const PERMISSIONS = {
    ACCOUNT_CREATE: 'account:create',
    ROLE_VIEW: 'role:view',
    ROLE_CREATE: 'role:create',
    ROLE_UPDATE: 'role:update',
    ROLE_PERMISSION_VIEW: 'role:permission:view',
    PERMISSION_GRANT: 'permission:grant',
    DEVICE_CREATE: 'device:create',
    DEVICE_VIEW: 'device:view',
    DEVICE_CONFIG_VIEW: 'device:config:view',
    SIM_CONFIG_CREATE: 'sim:config:create',
    SIM_CONFIG_UPDATE: 'sim:config:update',
    MEMO_CONFIG: 'memo:config:create',
    STAFF_VIEW: 'staff:view',
    PAYMENT_REASON_VIEW: 'payment-reason:view',
    PAYMENT_REASON_CREATE: 'payment-reason:create',
    PAYMENT_REASON_UPDATE: 'payment-reason:update',
    DEVICE_DELETE: 'device:delete',
    TRANSACTION_LOG_VIEW:'transaction:log-view'
}

export const PAYMENT_VOUCHER_WARNING = {
    DUPLICATE_PAYMENT_REASON: "duplicate:payment-reason"
}

export const ACCOUNT_EVENT = {
    ADD_PROJECT: 'ADD_PROJECT',
    REMOVE_PROJECT: "REMOVE_PROJECT"
}

export const FILTER_TYPES = [
    {code: "between", name: "Khoảng"},
    {code: "eq", name: "="},
    {code: "gte", name: ">="},
    {code: "lte", name: "<="},
]

export const REVIEW_STATUSES_CODE = [
    {code:'PENDING',name:'Chờ duyệt'},
    {code:'REVIEWED',name:'Đã duyệt'},
]

export const STATUSES_CODE = [
    {code:'PENDING',name:'Chờ xác nhận'},
    {code:'APPROVED',name:'Đã xác nhận'},
    {code:'REJECTED',name:'Hủy'},
]
