import apiClient from "../Util/ApiClient";
import SuggestStaffQueryCriteria from "../QueryCriteria/SuggestStaffQueryCriteria";
import {StaffQueryCriteria} from "../QueryCriteria/StaffQueryCriteria";

export default class StaffApi {

    static getSuggestStaff(query: SuggestStaffQueryCriteria) {
        return apiClient.get('/staffs/autocomplete', {
            params: {...query}
        })
    }

    static getStaffs(query: StaffQueryCriteria) {
        return apiClient.get('/staffs', {
            params: {...query}
        })
    }

    static getStaffByUsername(username: any) {
        return apiClient.get(`/staffs/${username}`)
    }

    static getAccountsByUsername(username: any) {
        return apiClient.get(`/staffs/${username}/accounts`,{
            params:{ sort: 'createdAt:desc'}
        })
    }
}
