import React, { FC } from 'react'
import { Table, Skeleton } from 'antd'
import { ColumnsType } from 'antd/lib/table'

interface IProps {
    columns: ColumnsType<any>
}

const SkeletonTable: FC<IProps> = ({ columns }: IProps) => {
    return (
        <Table
            rowKey="key"
            pagination={false}
            dataSource={[...Array(5)].map((_, index) => ({
                key: `key${index}`,
            }))}
            columns={columns.map((column: any) => {
                return {
                    ...column,
                    render: function renderPlaceholder() {
                        return <Skeleton key={column.dataIndex} title={true} paragraph={false} />
                    },
                }
            })}
            scroll={{ x: 1167 }}
        />
    )
}

export default SkeletonTable
