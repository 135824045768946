import React from 'react';
import DefaultPropsInterface from "../../../Interface/DefaultPropsInterface";
import {Col, Form, Row} from 'antd';
import lodash, {get} from 'lodash';
import moment from "moment";
import Utils from "../../../Util/Utils";
import Clipboard from "../../../Component/Clipboard";
import {REVIEW_STATUSES_CODE} from "../../../Util/Constants";

interface Props extends DefaultPropsInterface {
    receiptVoucher: any
}

interface State {
}

class ReceiptVoucherReadOnly extends React.Component<Props, State> {

    renderPayer = () => {
        const receiptVoucher: {} = this.props.receiptVoucher;
        if (lodash.get(receiptVoucher, 'payerRef.fullname')) {
            return `${lodash.get(receiptVoucher, 'payerRef.username')} (${lodash.get(receiptVoucher, 'payerRef.fullname')})`
        }

        return lodash.get(receiptVoucher, 'payerRef.username') || lodash.get(receiptVoucher, 'payer')
    }

    render() {
        const receiptVoucher: any = this.props.receiptVoucher;
        return (
            <Form
                labelCol={{xs: 6,lg:10}}
                labelAlign="left"
                className={'payment-infos horizontal-form'}
            >
                <Row>
                   <Col xs={24} lg={12}>
                       <Form.Item label={'Tài khoản quỹ'}>
                           <span className={'font-medium'}>{get(receiptVoucher, 'account.name')} ({get(receiptVoucher, 'account.code')})</span>
                       </Form.Item>

                       <Form.Item label={'Thời gian tạo'}>
                           <span className={'font-medium'}>{get(receiptVoucher, 'preparedAt') ? moment(get(receiptVoucher, 'preparedAt')).format('DD/MM/YYYY HH:mm:ss') : '--'}</span>
                       </Form.Item>

                       <Form.Item label={'Người tạo'}>
                           <span className={'font-medium'}>{get(receiptVoucher, 'preparedBy.username') ? get(receiptVoucher, 'preparedBy.username') : '--'}</span>
                       </Form.Item>

                       <Form.Item label={'Trạng thái'}>
                           <span className={'font-medium'}>{get(receiptVoucher, 'status.name') || "--"}</span>
                       </Form.Item>

                       {get(receiptVoucher,'status.code') !== 'REJECTED' && <>
                           <Form.Item
                               label={'Thời gian xác nhận'}
                           >
                               <span className={'font-medium'}>{get(receiptVoucher, 'approvedAt') ? moment(get(receiptVoucher, 'approvedAt')).format('DD/MM/YYYY HH:mm:ss') : '--'}</span>
                           </Form.Item>

                           <Form.Item
                               label={'Người xác nhận'}
                           >
                               <span className={'font-medium'}>{get(receiptVoucher, 'approvedBy.username') ? get(receiptVoucher, 'approvedBy.username') : '--'}</span>
                           </Form.Item>
                       </>}

                       {get(receiptVoucher, 'reviewStatus') !== 'PENDING' && <>
                           <Form.Item label={'Trạng thái phiếu'}>
                        <span className={'font-medium'}>
                             {receiptVoucher && receiptVoucher.reviewStatus ?
                                 REVIEW_STATUSES_CODE.find((x) => receiptVoucher.reviewStatus === x.code)?.name : "--"}
                        </span>
                           </Form.Item>

                           <Form.Item label={'Thời gian duyệt'}>
                        <span className={'font-medium'}>
                            {get(receiptVoucher, 'reviewedAt')
                                ? moment(get(receiptVoucher, 'reviewedAt')).format(
                                    'DD/MM/YYYY HH:mm:ss'
                                )
                                : '--'}
                        </span>
                           </Form.Item>

                           <Form.Item label={'Người duyệt phiếu'}>
                        <span className={'font-medium'}>
                            {get(receiptVoucher, 'reviewedBy.username')
                                ? get(receiptVoucher, 'reviewedBy.username')
                                : '--'}
                        </span>
                           </Form.Item>
                       </>}

                   </Col>
                    <Col xs={24} lg={12}>
                        <Form.Item label={'Mã giao dịch'}>
                            <span className={'font-medium'}>{get(receiptVoucher, 'transaction') || "--"}</span> {get(receiptVoucher, 'transaction') && <Clipboard value={get(receiptVoucher, 'transaction')} successMessage={'Đã copy'} />}
                        </Form.Item>

                        <Form.Item label={'Người nộp tiền'}>
                            <div className={'font-medium'}>
                                {this.renderPayer()}
                            </div>
                        </Form.Item>

                        <Form.Item label={'Số tiền'}>
                            <div className={'font-medium'}>{Utils.currencyFormatByCurrency(get(receiptVoucher, 'amount'), get(receiptVoucher, 'currency.code'))} {get(receiptVoucher, 'currency.code')}</div>
                        </Form.Item>

                        <Form.Item label={'Nội dung giao dịch'}>
                            <div className={'font-medium'}>{get(receiptVoucher, 'memo')}</div>
                        </Form.Item>

                        <Form.Item label={'Ghi chú'}>
                            <div className={'font-medium'}>{get(receiptVoucher, 'remark')}</div>
                        </Form.Item>

                        <Form.Item label={'Thời gian giao dịch'}>
                            <div className={'font-medium'}>{moment(get(receiptVoucher, 'timestamp')).format('DD/MM/YYYY HH:mm:ss')}</div>
                        </Form.Item>

                        <Form.Item label={'Mã bút toán'}>
                            <div className={'font-medium'}>{get(receiptVoucher, 'ref')} {get(receiptVoucher, 'ref') && <Clipboard value={get(receiptVoucher, 'ref')} successMessage={'Đã copy'} />}</div>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        );
    }
}

export default ReceiptVoucherReadOnly;
