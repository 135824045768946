import React from 'react';
import {Form, Input, Modal} from "antd";
import MemoApi from "../../Api/MemoApi";
import MemoMappingCommand from "../../Command/MemoMappingCommand";
import SelectProject from "../../Component/SelectProject";
import {AxiosError} from "axios";
import lodash, {omitBy} from "lodash";
import { NotificationCommon } from '../Notification';

interface Props {
    onVisibleChange: (visible: boolean) => void,
    onSuccess: () => void
}

interface State {
    loading: boolean,
    form: MemoMappingCommand,
}

class ModalMemoConfig extends React.Component<Props, State> {

    state: State = {
        loading: false,
        form: {
            project: "",
            placeholder: ""
        },
    };

    handleSubmit = () => {
        this.setState({loading: true});
        const data = omitBy(this.state.form, (value: any) => (value === "" || value === undefined || value === null));
        MemoApi.config(data)
            .then(() => {
                this.setState({loading: false});
                NotificationCommon.success({
                    message: 'Cấu hình thành công',
                    icon: <i className="fa-solid fa-circle-check"/>,
                })
                this.props.onSuccess()
            })
            .catch((error: AxiosError) => {
                this.setState({loading: false});
                if (lodash.get(error.response, 'status') === 400) {
                    if (lodash.get(error.response, 'data.title') === 'memo_mapping_existed') {
                        NotificationCommon.error({
                            message: "Cú pháp đã tồn tại",
                            icon: <i className="fa-solid fa-triangle-exclamation"/>,
                        })
                    }
                } else {
                    NotificationCommon.error({
                        message: 'Cấu hình thất bại',
                        icon: <i className="fa-solid fa-triangle-exclamation"/>,
                    })
                }
            })
    }

    handleCancel = () => {
        this.props.onVisibleChange(false);
    }

    handleEnableSubmit = () => {
        let validatedForm = this.state.form;
        if (validatedForm) {
            if ((validatedForm.project !== '' || null || undefined) && (validatedForm.placeholder !== '' || null || undefined)) return true;
        } else return false;
    }

    handleChangeInput = (field: string, e: any) => {
        this.setState({
            form: {
                ...this.state.form,
                [field]: e.target.value,
            }
        })
    };

    handleChangeProject = (v: string) => {
        this.setState({
            form: {
                ...this.state.form,
                project: v
            }
        })
    }

    render() {
        const {loading} = this.state;
        return (
            <Modal
                visible={true}
                title={"Cấu hình cú pháp"}
                closeIcon={<i className="fa-solid fa-xmark"  />}
                okText={<span><i className="fa-regular fa-check mg-r-8"/>Cập nhật</span>}
                cancelText={<span><i className="fa-regular fa-xmark mg-r-8"/>Bỏ qua</span>}
                width={800}
                okButtonProps={{
                    loading: loading,
                    disabled: !this.handleEnableSubmit(),
                }}
                cancelButtonProps={{
                    disabled: loading,
                    type: 'ghost'
                }}
                onOk={this.handleSubmit}
                onCancel={this.handleCancel}
                centered
            >
                <Form labelCol={{xs: 4, sm: 3}} labelAlign={'left'}>
                    <Form.Item name={'project'} label={'Hệ thống'} rules={[{required: true, message: 'Hệ thống không được để trống'}]}>
                        <SelectProject onChange={(v) => this.handleChangeProject(v)}/>
                    </Form.Item>
                    <Form.Item name={'placeholder'} label={'Cú pháp'}
                               rules={[
                                   {required: true, message: 'Cú pháp không được để trống'},
                                   {pattern: new RegExp(/^\S*$/),message:'Cú pháp không được có dấu cách!'}
                               ]} className="mg-bt-0">
                        <Input onChange={this.handleChangeInput.bind(this, 'placeholder')} placeholder={'Vui lòng nhập cú pháp'}/>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

export default ModalMemoConfig;
