import React, {Component} from 'react'

import {Button, Layout, Menu} from 'antd';
import lodash from 'lodash';
import {Link} from 'react-router-dom';
import {PERMISSIONS} from "../../Util/Constants";
import SecurityService from "../../Util/SecurityService";

const {Sider} = Layout;

interface IMenuItem {
    key: string,
    title: string,
    icon: any,
    url: string,
    permissions: Array<string>,
    children?: Array<IMenuItem>
}

interface Props {
    collapsed: boolean,
    toggle: (callback?: () => void) => void,
    isShowSideBarItems: boolean,
    history: any,
    match: any
}

interface State {
    items: IMenuItem[],
    openKeys: Array<any>,
    openKeysWhenColapsed: Array<any>
}

class SideBar extends Component<Props, State> {

    static defaultProps = {
        isShowSideBarItems: true
    };
    sidebarRef: React.RefObject<any>;

    constructor(props: any) {
        super(props);
        this.state = {
            items: [
                {key: "DASHBOARD", title: "Bảng điều khiển", permissions: [], icon: <i className="fa-solid fa-gauge" />, url: "/"},
                {key: "ACCOUNT", title: "Tài khoản quỹ", permissions: [], icon: <i className="fa-solid fa-yen-sign" />, url: "/accounts"},
                {key: "PENDING_VOUCHER", title: "Phiếu chờ giao dịch", permissions: [], icon: <i className="fa-solid fa-memo-circle-info" />, url: "/pending-vouchers"},
                {key: "PAYMENT_VOUCHER", title: "Phiếu chi", permissions: [], icon: <i className="fa-solid fa-receipt" />, url: "/payment-vouchers"},
                {key: "RECEIPT_VOUCHER", title: "Phiếu thu", permissions: [], icon: <i className="fa-solid fa-scroll" />, url: "/receipt-vouchers"},
                {key: "TRANSACTION", title: "Lịch sử giao dịch", permissions: [], icon: <i className="fa-solid fa-money-check-pen" />, url: "/transactions"},
                {key: "DEVICE", title: "Thiết bị", permissions: [PERMISSIONS.DEVICE_VIEW, PERMISSIONS.DEVICE_CREATE], icon: <i className="fa-solid fa-desktop" />, url: "/devices"},
                {
                    key: "MESSAGE", title: "Tin nhắn", permissions: [], icon: <i className="fa-solid fa-messages" />, url: "/messages",
                    children: [
                        {key: "MESSAGE_LIST", title: "Tin nhắn", permissions: [], icon: <i className="fa-solid fa-messages" />, url: "/messages"},
                        {key: "MEMO", title: "Cú pháp", permissions: [], icon: <i className="fa-solid fa-check-to-slot" />, url: "/memos"}
                    ]
                },
                {key: "STAFF", title: "Nhân viên", permissions: [], icon: <i className="fa-solid fa-user-headset" />, url: "/staffs"},
                {key: "PAYMENT_REASON", title: "Mục đích chi", permissions: [PERMISSIONS.PAYMENT_REASON_VIEW], icon: <i className="fa-brands fa-readme" />, url: "/payment-reasons"},
                {
                    key: "SECURITY", title: "Bảo mật", permissions: [PERMISSIONS.ROLE_VIEW, PERMISSIONS.ROLE_PERMISSION_VIEW], icon: <i className="fa-solid fa-shield" />, url: "/security",
                    children: [
                        {key: "ROLE", title: "Vai trò", permissions: [PERMISSIONS.ROLE_VIEW], icon: <i className="fa-solid fa-users" />, url: "/security/roles"},
                        {key: "PERMISSION", title: "Quyền", permissions: [PERMISSIONS.ROLE_PERMISSION_VIEW], icon: <i className="fa-solid fa-user-lock" />, url: "/security/permissions"}
                    ]
                }
            ],
            openKeys: [],
            openKeysWhenColapsed: []
        };
        this.sidebarRef = React.createRef()
    }

    handleClickOutside(event: any, sidebarRef: any) {
        if (sidebarRef && !sidebarRef?.current?.contains(event.target)) {
            if (!this.props.collapsed) {
                this.props.toggle()
            }
        }
      }

    componentDidMount() {
        this.setMenuOpenKeys();
        document.addEventListener("mousedown", e => this.handleClickOutside(e, this.sidebarRef));
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", e => this.handleClickOutside(e, this.sidebarRef));
      }

      
    getMenuSelectedKeys = () => {
        if (this.props.match) {
            if (lodash.startsWith(this.props.match.path, '/accounts')) {
                return ['ACCOUNT'];
            } else if (lodash.startsWith(this.props.match.path, '/pending-vouchers')) {
                return ['PENDING_VOUCHER'];
            } else if (lodash.startsWith(this.props.match.path, '/payment-vouchers')) {
                return ['PAYMENT_VOUCHER'];
            } else if (lodash.startsWith(this.props.match.path, '/receipt-vouchers')) {
                return ['RECEIPT_VOUCHER'];
            } else if (lodash.startsWith(this.props.match.path, '/transactions')) {
                return ['TRANSACTION'];
            } else if (lodash.startsWith(this.props.match.path, '/devices')) {
                return ['DEVICE'];
            } else if (lodash.startsWith(this.props.match.path, '/messages')) {
                return ['MESSAGE', 'MESSAGE_LIST'];
            } else if (lodash.startsWith(this.props.match.path, '/memos')) {
                return ['MESSAGE', 'MEMO'];
            }
            else if (lodash.startsWith(this.props.match.path, '/staffs')) {
                return ['STAFF'];
            } else if (lodash.startsWith(this.props.match.path, '/payment-reasons')) {
                return ['PAYMENT_REASON'];
            } else if (lodash.startsWith(this.props.match.path, '/security')) {
                if (lodash.startsWith(this.props.match.path, '/security/roles')) {
                    return ['SECURITY', 'ROLE'];
                } else if (lodash.startsWith(this.props.match.path, '/security/permissions')) {
                    return ['SECURITY', 'PERMISSION'];
                }
            }
        }

        return ['DASHBOARD'];
    };

    setMenuOpenKeys = () => {
        if (this.props.match) {
            if (lodash.startsWith(this.props.match.path, '/security')) {
                this.setState({'openKeys': ['SECURITY']});
            }
            else if (lodash.startsWith(this.props.match.path, '/messages') || lodash.startsWith(this.props.match.path, '/memos')) {
                this.setState({openKeys: ['MESSAGE']})
            }
        }
    };

    handleOnOpenChange = (openKeys: any) => {
        this.setState({openKeys});
        this.setState({openKeysWhenColapsed: openKeys});
    };

    isShowItem = (item: IMenuItem) => {
        if (!item.permissions.length) {
            return true;
        }

        for (let perm of item.permissions) {
            if (SecurityService.can(perm)) {
                return true;
            }
        }

        return false;
    };

    openSidebar = () => {
        const selectedKeys: string[] = this.getMenuSelectedKeys()

        this.props.toggle(() => {
                this.setState({
                    openKeys: [selectedKeys[0]]
                })
            })
    }

    render() {
        const {collapsed, isShowSideBarItems} = this.props;
        const {items, openKeys, openKeysWhenColapsed} = this.state;

        const renderMenuItem = (item: IMenuItem) => {
            if (this.isShowItem(item)) {
                if (item.children) {
                    return (
                        <Menu.SubMenu key={item.key} className={'sidebar-item-has-children'} title={item.title} icon={item.icon}>
                            {item.children.map(childItem => (
                                <Menu.Item
                                    key={childItem.key}
                                    className={`_sidebar_menu_${childItem.key.toLowerCase()} ${!this.isShowItem(childItem) ? 'd-none' : ''}`}
                                >
                                    <Link to={childItem.url} className="_link-menu-dashboard sidebar-item">
                                        {childItem.icon}
                                        <span className="sidebar-item__url pd-l-8">
                                            {childItem.title}
                                        </span>
                                    </Link>
                                </Menu.Item>
                            ))}
                        </Menu.SubMenu>
                    )
                }
                else {
                    return (
                        <Menu.Item
                            key={item.key}
                            className={`_sidebar_menu_${item.key.toLowerCase()}`}
                        >
                            <Link to={item.url} className="_link-menu-dashboard sidebar-item">
                                {item.icon}
                                <span className="sidebar-item__url pd-l-8">
                                    {item.title}
                                </span>
                            </Link>
                        </Menu.Item>
                    );
                }
            }
        };

        return (
            <Sider
                ref={this.sidebarRef}
                trigger={null}
                collapsible
                collapsed={collapsed}
                className={"sidebar"}
                collapsedWidth={48}
            >
                <div className='collapseBtn' >
                    {collapsed ? (
                            <Button type='text' onClick={this.openSidebar}> <i className="fa-solid fa-chevrons-right"  /></Button>
                        ) : (
                            <Button type='text' onClick={() => this.props.toggle()}><i className="fa-solid fa-chevrons-left" /></Button>
                        )}
                </div>
                {isShowSideBarItems && (
                    <Menu onOpenChange={this.handleOnOpenChange} mode="inline" openKeys={collapsed ? openKeysWhenColapsed : openKeys} selectedKeys={this.getMenuSelectedKeys()} className="sidebar-menu">
                        {items.map((item) => renderMenuItem(item))}

                        <Menu.Item
                            key={'logout-menu-item'}
                            className="logout"
                        >
                            <Link to="/logout" className="_link-menu-dashboard sidebar-item  space-between items-center">
                                <span className="sidebar-item__url">
                                    Đăng xuất
                                </span>
                                <i className="fa-solid fa-arrow-right-from-bracket pd-l-8" />
                            </Link>
                        </Menu.Item>
                    </Menu>
                )}

            </Sider>
        );
    }
}

export default React.memo(SideBar);
