import React from "react";
import {Form, Input, Modal} from "antd";
import MessageApi from "../../Api/MessageApi";
import MessageDomain from "../../Domain/MessageDomain";
import { NotificationCommon } from "../../Component/Notification";

interface Props {
    message: MessageDomain,
    onSuccess: (message: MessageDomain) => void,
    onVisibleChange: (visible: boolean) => void
}

interface State {
    loading: boolean
    remark: undefined | string
    isChanged: boolean
}

export class ModalUpdateRemark extends React.Component<Props, State> {

    state: State = {
        loading: false,
        remark: this.props.message.remark,
        isChanged: false
    }

    componentDidMount() {
        this.setState({remark: this.props.message.remark })
    }

    handleSubmit = () => {
        const {remark = ''} = this.state
            this.setState({loading: true})
            MessageApi.update(this.props.message.id, {remark})
            .then((response) => {
                NotificationCommon.success({
                    message: 'Cập nhật ghi chú giao dịch thành công',
                })
                this.setState({loading: false})
                this.props.onSuccess({
                    ...this.props.message,
                    remark: response.data.remark
                })

                this.props.onVisibleChange(false)

            })
            .catch(() => {
                this.setState({loading: false})
                NotificationCommon.error({
                    message: "Đã có lỗi xảy ra. Vui lòng thử lại",
                })
            })
    }

    render() {
        const {loading, remark, isChanged} = this.state;
        return (
            <Modal
                title={"Cập nhật ghi chú giao dịch"}
                okText={"Cập nhật"}
                visible={true}
                cancelText={'Bỏ Qua'}
                closeIcon={<i className="fa-solid fa-xmark"  />}
                okButtonProps={{
                    loading: loading,
                    disabled: loading || !remark || !isChanged,
                icon: <i className="fa-solid fa-check mg-r-8"  />
            }}
                cancelButtonProps={{
                    type:'ghost',
                    loading: loading,
                    disabled: loading,
                    icon: <i className="fa-solid fa-xmark mg-r-8"  />
            }}
                onOk={this.handleSubmit.bind(this)}
                onCancel={() => this.props.onVisibleChange(false)}
				centered
            >
                <Form>
                    <Form.Item
                        label={"Ghi chú giao dịch"}
                        name={'remark'}
                        required={true}
                        rules={[
                            {required: true, message: "Ghi chú giao dịch không được để trống"}
                        ]}
                        initialValue={remark}
                        className="mg-bt-0"
                        labelCol={{xs: 24, md: 6}}
                        labelAlign="left"
                    >
                        <Input autoFocus placeholder={"Vui lòng nhập ghi chú giao dịch"} onChange={(e) => {
                            this.setState({remark: e.target.value, isChanged: true})
                        }} />
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}
