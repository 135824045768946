import lodash from "lodash";
import React from 'react';
import DefaultComponent from "../../Component/DefaultComponent";
import DefaultLayout from "../../Component/Layout/Default";
import Utils from "../../Util/Utils";
import DataList from "./DataList";
import MemoDomain from "../../Domain/MemoDomain";
import MemoApi from "../../Api/MemoApi";
import SecurityService from "../../Util/SecurityService";
import {PERMISSIONS} from "../../Util/Constants";
import {Badge, Button, Col, Row} from "antd";
import ModalMemoConfig from "../../Component/ModalMemoConfig";


interface State {
    loading: boolean,
    memos: Array<MemoDomain>,
    total: number,
    pageSize: number,
    currentPage: number,
    showMemoConfigModal: boolean
}

interface Props {
}

class MemoList extends DefaultComponent<Props, State> {

    state: State = {
        loading: false,
        memos: [],
        pageSize: 25,
        total: 0,
        currentPage: 1,
        showMemoConfigModal: false
    }

    componentDidMount() {
        this.fetchMemos({
            ...this.getQueryFromLocation()
        })
    }

    componentDidUpdate(prevProps: any) {
        if (
            JSON.stringify(this.props.location.search) !== JSON.stringify(prevProps.location.search)
            || JSON.stringify(this.props.match.params) !== JSON.stringify(prevProps.match.params)
        ) {
            this.fetchMemos({
                ...this.getQueryFromLocation()
            });
            this.setState({
                ...this.state,
                pageSize:this.getQueryFromLocation().limit,
                currentPage:this.getQueryFromLocation().page,
            })
        }
    }

    fetchMemos = (filter: any = {}) => {
        this.setState({loading: true})
        filter = {
            sort: 'createdAt:desc',
            ...filter
        };

        MemoApi.filter(filter)
            .then(response => {
                this.setState({
                    currentPage: parseInt(lodash.get(response, 'headers.x-page-number')) + 1,
                    pageSize: parseInt(lodash.get(response, 'headers.x-page-size')),
                    total: parseInt(lodash.get(response, 'headers.x-total-count')),
                    memos: response.data,
                })
            })
            .finally(() => {
                this.setState({loading: false});
            })
    };

    onChangePage = (page: number, pageSize: any) => {
        this.pushCleanQueryToHistory({
            ...this.getQueryFromLocation(),
            page: page,
            limit: pageSize,
            offset: (page - 1) * pageSize
        })
    }

    handleMemoConfigModalVisibleChange = (visible: boolean) => {
        this.setState({showMemoConfigModal: visible});
    }

    handleMemoCreateSuccess = () => {
        this.fetchMemos({t: lodash.uniqueId()})
        this.handleMemoConfigModalVisibleChange(false)
    }

    render() {
        const {
            memos,
            currentPage,
            pageSize,
            total,
            loading,
            showMemoConfigModal
        } = this.state;
        return (
            <DefaultLayout
                {...this.props}
                title={`Danh sách cú pháp (${total})`}
                breadcrumb={[{title:'Danh Sách Cú Pháp'}]}
            >
                <div className="memo-container">
                    <div className="white-box">
                        <div className={'memo-container__header'}>
                            <Row>
                                <Col xs={20} sm={16} md={18} lg={21} xl={21}>
                                    <div className="">
                                        <span className={'mg-r-4'}>Danh sách cú pháp</span>
                                        <Badge count={Utils.currencyFormat(total)} overflowCount={99}/>
                                    </div>
                                </Col>
                                <Col xs={24} sm={8} md={6} lg={3}>
                                    <div className="flr">
                                        {SecurityService.can(PERMISSIONS.MEMO_CONFIG) &&
                                        <Button type={'primary'} onClick={() => this.handleMemoConfigModalVisibleChange(true)}><i
                                            className="fa-regular fa-plus mg-r-8"/>Cấu hình cú pháp</Button>}
                                    </div>
                                </Col>
                            </Row>
                            {/*<div className="clearfix"/>*/}
                        </div>
                        <div className={'memo-container__data'}>
                            <DataList
                                loading={loading}
                                items={memos}
                                total={total}
                                pageSize={pageSize}
                                currentPage={currentPage}
                                onChangePage={this.onChangePage}
                            />
                        </div>
                    </div>
                </div>

                {showMemoConfigModal && (
                    <ModalMemoConfig
                        onVisibleChange={this.handleMemoConfigModalVisibleChange}
                        onSuccess={this.handleMemoCreateSuccess}
                    />
                )}

            </DefaultLayout>
        );
    }
}

export default MemoList;
