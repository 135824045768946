import apiClient from "../Util/ApiClient";
import SuggestCustomerQueryCriteria from "../QueryCriteria/SuggestCustomerQueryCriteria";

export default class CustomerApi {

    static getSuggestCustomers(query: SuggestCustomerQueryCriteria) {
        return apiClient.get('/customers/autocomplete', {
            params: {...query}
        })
    }
}