import React from "react";
import AccountDomain, {AccountModel} from "../Domain/AccountDomain";
import {Tenant, TenantModel} from "../Domain/TenantDomain";

export interface AppContextValueInterface {
    state: AppContextValueStateInterface,
    func: AppContextValueFuncInterface
}

interface AppContextValueFuncInterface {
    updateAccount: (account: AccountDomain) => void,
    fetchAccountAndUpdateState: (accountCode: string) => void
}

interface AppContextValueStateInterface {
    loading: boolean,
    account: AccountDomain,
    tenant: Tenant
}

export const defaultAppContextValue: AppContextValueInterface = {
    state: {
        loading: false,
        account: {...AccountModel},
        tenant: {...TenantModel}
    },
    func: {
        updateAccount: () => {},
        fetchAccountAndUpdateState: () => {}
    }
}

const AppContext = React.createContext({...defaultAppContextValue});

export {AppContext};
