import {Button, Col, Form, Input, Row} from 'antd';
import {Component} from 'react';
import DefaultPropsInterface from "../../../Interface/DefaultPropsInterface";
import Utils from "../../../Util/Utils";

interface FormInterface {
    query?: string
    email?: string
}

interface Props extends DefaultPropsInterface {
}

interface State {
    form: FormInterface
}

class FilterForm extends Component<Props, State> {

    state: State = {
        form: {
            ...Utils.getQueryFromLocation(this.props.location),
        }
    }

    componentDidUpdate(prevProps: any) {
        if (
            JSON.stringify(this.props.location.search) !== JSON.stringify(prevProps.location.search)
            || JSON.stringify(this.props.match.params) !== JSON.stringify(prevProps.match.params)
        ) {
            this.setState({form: Utils.getQueryFromLocation(this.props.location)});
        }
    }

    handleChangeInput = (field: string, e: any) => {
        const form: any = this.state.form;
        form[field] = e.target.value;
        this.setState({form});
    }

    handleFilter = () => {
        let filterTemp = {
            ...this.state.form,
            page:1,
            offset:0,
            limit:25,
        };
        Utils.pushCleanQueryToHistory(this.props.history, this.props.location, filterTemp);
    }

    clearFilter = () => {
        this.setState({
            form: {}
        });
        Utils.pushCleanQueryToHistory(this.props.history, this.props.location, {});
    }

    render() {
        const form: FormInterface = this.state.form;

        const disbaleSubmit: boolean = Utils.checkDisableSubmit(form)

        return (
            <Form layout={'horizontal'}
                  labelAlign={'left'}
            >
                <Row gutter={48}>
                    <Col xs={24} lg={6}>
                        <Form.Item label={"Username"}>
                            <Input
                                allowClear
                                className={'width-100pc form-input'}
                                value={form.query}
                                placeholder={'Tìm kiếm theo Username'}
                                onChange={this.handleChangeInput.bind(this, 'query')}
                                onPressEnter={this.handleFilter}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={6}>
                        <Form.Item label={"Email"}>
                            <Input
                                allowClear
                                className={'width-100pc form-input'}
                                value={form.email}
                                placeholder={'Tìm kiếm theo Email'}
                                onChange={this.handleChangeInput.bind(this, 'email')}
                                onPressEnter={this.handleFilter}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <div className="text-right">
                    {
                        !disbaleSubmit && (
                            <Button type={'text'} onClick={this.clearFilter}  className="font-size-12px">
                                <i className="fa-regular fa-repeat mg-r-5"/>
                                Làm mới bộ lọc
                            </Button>
                        )
                    }
                        
                    <Button type={'primary'} onClick={this.handleFilter} disabled={disbaleSubmit}>Tìm kiếm</Button>
                </div>
            </Form>
        );
    }
}

export default FilterForm;
