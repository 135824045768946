import {Button, Col, DatePicker, Form, Input, Row, Select, Tooltip} from 'antd';
import moment from "moment";
import {Component} from 'react';
import SelectDevice from "../../Component/SelectDevice";
import DefaultPropsInterface from "../../Interface/DefaultPropsInterface";
import {MESSAGE_STATUSES} from "../../Util/Constants";
import Utils from "../../Util/Utils";

const RangePicker = DatePicker.RangePicker;

interface FormInterface {
    device?: string,
    sim?: string,
    timestampFrom?: string|null,
    timestampTo?: string|null,
    messageIncomingAtFrom?: string|null,
    messageIncomingAtTo?: string|null,
    status?: string,
    sender?: string,
    content?: string,
    account?: string,
    id?: string,
    username?: string,
    query?:string,
}

interface Props extends DefaultPropsInterface {
}

interface State {
    form: FormInterface
}

class FilterForm extends Component<Props, State> {

    state: State = {
        form: {
            ...Utils.getQueryFromLocation(this.props.location)
        }
    }

    componentDidUpdate(prevProps: any) {
        if (
            JSON.stringify(this.props.location.search) !== JSON.stringify(prevProps.location.search)
            || JSON.stringify(this.props.match.params) !== JSON.stringify(prevProps.match.params)
        ) {
            this.setState({form: Utils.getQueryFromLocation(this.props.location)});
        }
    }

    handleChangeInput = (field: string, e: any) => {
        const form: any = this.state.form;
        form[field] = e.target.value;
        this.setState({form});
    }

    handleChangeSelect = (field: string, value: any) => {
        const form: any = this.state.form;
        form[field] = value;
        this.setState({form});
    }

    handleChangeDateRangePicker = (field: string, values: any) => {
        const {form} = this.state;
        if (field === 'timestamp') {
            form.timestampFrom = values && values[0] ? moment(values[0].format("YYYY-MM-DD 00:00:00")).toISOString(): null;
            form.timestampTo = values && values[1] ? moment(values[1].format("YYYY-MM-DD 23:59:59")).toISOString(): null;
        }
        else if (field === 'messageIncomingAt') {
            form.messageIncomingAtFrom = values && values[0] ? moment(values[0].format("YYYY-MM-DD 00:00:00")).toISOString(): null;
            form.messageIncomingAtTo = values && values[1] ? moment(values[1].format("YYYY-MM-DD 23:59:59")).toISOString(): null;
        }
        this.setState({form});
    }

    handleFilter = () => {
        let newFilter = {
            ...this.state.form,
            offset:0,
            page:1,
        }
        Utils.pushCleanQueryToHistory(this.props.history, this.props.location,newFilter);
    }

    clearFilter = () => {
        this.setState({
            form: {}
        });
        Utils.pushCleanQueryToHistory(this.props.history, this.props.location, {});
    }

    render() {
        const form: FormInterface = this.state.form;
        const disbaleSubmit: boolean = Utils.checkDisableSubmit(form)

        return (
            <Form layout={'horizontal'}
                  className={'message-container__filters-form'}
                  labelAlign={'left'}
                  colon={true}
            >
                <Row gutter={36}>
                    <Col xs={24} lg={12} xl={9}>
                        <Form.Item label={"Nhận sms lúc"}>
                            <RangePicker
                                allowClear
                                className={'width-100pc'}
                                placeholder={['Từ','Đến']}
                                value={[
                                    form.messageIncomingAtFrom ? moment(form.messageIncomingAtFrom): null,
                                    form.messageIncomingAtTo ? moment(form.messageIncomingAtTo): null,
                                ]}
                                onChange={this.handleChangeDateRangePicker.bind(this, 'messageIncomingAt')}
                                suffixIcon={<i className="fa-solid fa-calendar-range"/>}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={12} xl={8}>
                        <Form.Item label={"Hệ thống nhận"}>
                            <RangePicker
                                allowClear
                                className={'width-100pc'}
                                placeholder={['Từ','Đến']}
                                value={[
                                    form.timestampFrom ? moment(form.timestampFrom): null,
                                    form.timestampTo ? moment(form.timestampTo): null,
                                ]}
                                onChange={this.handleChangeDateRangePicker.bind(this, 'timestamp')}
                                suffixIcon={<i className="fa-solid fa-calendar-range"/>}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={12} xl={7}>
                        <Form.Item label={"Thiết bị"}>
                            <SelectDevice
                                value={form.device}
                                onChange={this.handleChangeSelect.bind(this, 'device')}
                                selectProps={{
                                    placeholder: 'Tìm kiếm theo thiết bị'
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={12} xl={9}>
                        <Form.Item label={"Sim"}>
                            <Input
                                className={'form-input'}
                                allowClear
                                placeholder={"Tìm kiếm theo Sim"}
                                value={form.sim}
                                onChange={this.handleChangeInput.bind(this, 'sim')}
                                onPressEnter={() => this.handleFilter()}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={12} xl={8}>
                        <Form.Item label={"Sender"}>
                            <Input
                                className={'form-input'}
                                allowClear
                                placeholder={"Tìm kiếm theo Sender"}
                                value={form.sender}
                                onChange={this.handleChangeInput.bind(this, 'sender')}
                                onPressEnter={() => this.handleFilter()}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={12} xl={7}>
                        <Form.Item label={"Trạng thái"}>
                            <Select
                                allowClear
                                value={form.status}
                                placeholder={'Tìm kiếm theo trạng thái'}
                                onChange={this.handleChangeSelect.bind(this, 'status')}
                            >
                                {Object.keys(MESSAGE_STATUSES).map(status => (
                                    <Select.Option key={status} value={status}>{status}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    {/*<Col xs={24} lg={12} xl={8}>*/}
                    {/*    <Form.Item label={"Mã sms"}>*/}
                    {/*        <Input*/}
                    {/*            className={'form-input'}*/}
                    {/*            allowClear*/}
                    {/*            placeholder={"Tìm kiếm theo Mã sms"}*/}
                    {/*            value={form.id}*/}
                    {/*            onChange={this.handleChangeInput.bind(this, 'id')}*/}
                    {/*            onPressEnter={() => this.handleFilter()}*/}
                    {/*        />*/}
                    {/*    </Form.Item>*/}
                    {/*</Col>*/}
                    {/*<Col xs={24} lg={12} xl={8}>*/}
                    {/*    <Form.Item label={"Username"}>*/}
                    {/*        <Input*/}
                    {/*            className={'form-input'}*/}
                    {/*            allowClear*/}
                    {/*            placeholder={"Tìm kiếm theo Username"}*/}
                    {/*            value={form.username}*/}
                    {/*            onChange={this.handleChangeInput.bind(this, 'username')}*/}
                    {/*            onPressEnter={() => this.handleFilter()}*/}
                    {/*        />*/}
                    {/*    </Form.Item>*/}
                    {/*</Col>*/}
                    <Col xs={24} lg={12} xl={9}>
                        <Form.Item label={<div>Từ khóa <Tooltip title={'Username/ Mã SMS/ Mã GD/ Nội dung'} placement={'top'}><i className="fa-regular fa-circle-question mg-l-3"/></Tooltip></div>}>
                            <Input
                                className={'form-input'}
                                allowClear
                                placeholder={"Tìm kiếm theo Username/ Mã SMS/ Mã GD/ Nội dung"}
                                value={form.query}
                                onChange={this.handleChangeInput.bind(this, 'query')}
                                onPressEnter={() => this.handleFilter()}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <div className="text-right pd-r-10">
                    <Row gutter={18} justify={'end'} >
                        {!disbaleSubmit &&(
                            <Button type={'text'} onClick={this.clearFilter} className="font-size-12px" >
                                <i className="fa-regular fa-repeat mg-r-5"/>
                                Làm mới bộ lọc
                            </Button>
                        )}
                        <Button
                            disabled={disbaleSubmit}
                            type={'primary'}
                            onClick={this.handleFilter}>Tìm kiếm</Button>
                    </Row>
                </div>
            </Form>
        );
    }
}

export default FilterForm;
