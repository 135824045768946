import {WarningTwoTone} from '@ant-design/icons';
import {Button, notification, Tooltip} from 'antd';
import lodash, {get} from 'lodash';
import moment from 'moment';
import PaymentVoucherDomain from "../../Domain/PaymentVoucherDomain";
import Utils from "../../Util/Utils";
import PaymentVoucherApi from "../../Api/PaymentVoucherApi";
import AccountDomain from "../../Domain/AccountDomain";
import {AxiosError} from "axios";
import AccountApi from "../../Api/AccountApi";
import DefaultPropsInterface from "../../Interface/DefaultPropsInterface";
import DefaultComponent from "../../Component/DefaultComponent";
import CommonTable from '../../Component/CommonTable';
import {NotificationCommon} from '../../Component/Notification';

interface State {
    loading: boolean
    columns: Array<any>
    disableBtns: {[key: string]: boolean}
}

interface Props extends DefaultPropsInterface {
    account: AccountDomain
    onApproveSuccess: () => void
    loading?: boolean,
    items: Array<PaymentVoucherDomain>
    total: number
    pageSize: number
    currentPage: number
    onPageChange: (page: number, pageSize: number) => void
}

class PaymentVoucherList extends DefaultComponent<Props, State> {

    state: State = {
        loading: false,
        disableBtns: {},
        columns: [
            {
                title: "Ngày tạo",
                key: "preparedAt",
                width: '15%',
                render: (row: any) => {
                    const preparedAt = row.preparedAt ? moment(row.preparedAt).format("DD/MM/YYYY HH:mm:ss") : "--";
                    const isShowWarning = row.warning && row.invoice;

                    return (
                    <div>
                        <Tooltip title={"Ngày tạo"}>
                            <span>{preparedAt}</span>
                        </Tooltip>

                        {isShowWarning && (
                            <Tooltip title={'Click để xem cảnh báo'}>
                                <WarningTwoTone className={'mg-l-10 pointer'} twoToneColor="#ff4d4f"  onClick={this.countWarning.bind(this, row)} />
                            </Tooltip>
                        )}
                    </div>)
                }
            },
            {
                title: "Thời gian giao dịch",
                key: "timestamp",
                width: '15%',
                align: 'right',
                render: (row: any) => {
                    const timestamp = row.timestamp ? moment(row.timestamp).format("DD/MM/YYYY HH:mm:ss") : "--";
                    return timestamp
                }
            },
            {
                title: "Người nhận tiền",
                key: "receiver",
                width: '12%',
                dataIndex: "receiver",
                render: (receiver: string, row: PaymentVoucherDomain) => {
                    let transactionUrl = `/transactions?participantType=outside&participant=${receiver}`;
                    if (row.receiverRef) {
                        transactionUrl = `/transactions?participantType=inside&project=${lodash.get(row.receiverRef, 'project')}&participant=${lodash.get(row.receiverRef, 'code')}&participantUsername=${lodash.get(row.receiverRef, 'username')}`;
                        return (
                            <Tooltip title={row.receiver}
                                     placement={'top'}>
                                <a href={transactionUrl} target={'_blank'} rel={'noreferrer'}>
                                    {lodash.get(row.receiverRef, 'username')}
                                </a>
                            </Tooltip>
                        )
                    }

                    return <a href={transactionUrl} target={'_blank'} rel={'noreferrer'}>{receiver}</a>;
                }
            },
            {
                title: "Hệ thống",
                key: "project",
                width: '10%',
                dataIndex: 'project',
            },
            {
                title: "Nội dung giao dịch",
                key: "memo",
                width: '18%',
                dataIndex: "memo",
            },
            {
                title: "Số tiền",
                key: "amount",
                width: '12%',
                dataIndex: "amount",
                align: 'right',
                render: (amount: any, row: any) => {
                    const currency = lodash.get(row, 'currency.code');
                    return (
						<span className={'fsz-16px negative-money bold'}>
                            {Utils.currencyFormatByCurrency(amount, currency)}
                            </span>
                    )
                }
            },
            {
                title: "Thao tác",
                key: "action",
                width: '28%',
                align: 'right',
                render: (row: any) => {
                    const disabled: boolean = this.state.disableBtns[row.id]
                    return (<div className={'flex justify-content-flex-end'}>
                        <Button
                            disabled={disabled}
                            // style={{width: '100%', maxWidth: 200}}
                            onClick={this.onApprove.bind(this, row.code, row.id)}
                            icon={<i className="fa-regular fa-file-check pd-r-6 pd-bt-2" />}
                            className="only-show-from-sm mg-r-6"
                        >
                            Xác Nhận GD
                        </Button>
                        {get(row,'reviewStatus') === 'PENDING' ?  <Button
                                disabled={disabled} type={'primary'}
                                onClick={this.onReview.bind(this, row.code, row.id)}
                                icon={<i className="fa-regular fa-check mg-r-6" />}
                                className="only-show-from-sm width-100pc"
                                style={{maxWidth:150}}
                            >
                                Duyệt Phiếu
                            </Button>
                            : <Button icon={<i className="fa-regular fa-check mg-r-6" />} disabled type={'ghost'}
                                      className={'width-100pc'} style={{maxWidth:150}}
                            >
                                Đã Duyệt
                            </Button>
                        }
                    </div>)
                }
            },
        ]
    };

    onApprove = (code: string, id: string) => {
        this.setState({loading: true})
        this.setState({disableBtns: {
            ...this.state.disableBtns,
            [id]: true
        }})

        PaymentVoucherApi.approve(code)
            .then(() => {
                NotificationCommon.success({message: "Duyệt phiếu chi thành công!"})
                this.props.onApproveSuccess();
            })
            .catch((error: AxiosError) => {
                let message: any = "Duyệt phiếu chi thất bại!";
                if (lodash.get(error.response, 'status') === 400) {
                    if (lodash.get(error.response, 'data.title') === 'ref_exist') {
                        const transactionCode = lodash.get(error.response, 'data.transactionCode');
                        message = <span>Mã bút toán đã tồn tại trong giao dịch <b>{transactionCode}</b></span>;
                        NotificationCommon.error({message});
                    }
                    else if (lodash.get(error.response, 'data.title') === 'source_exist') {
                        NotificationCommon.error({
                            message: 'Không thể duyệt phiếu vì đã tồn tại giao dịch'
                        })
                    }
                    else {
                        NotificationCommon.error({
                            message: message
                        })
                    }
                }
                else if (lodash.get(error.response,'status') === 403) {
                    NotificationCommon.error({
                        message: 'Bạn không có quyền thực hiện thao tác này'
                    })
                }
                else {
                    NotificationCommon.error({message});
                }
                this.setState({disableBtns: {
                    ...this.state.disableBtns,
                    [id]: false
                }})
            }).finally(() => {
                this.setState({loading: false})
            })
    }

    countWarning = (voucher: PaymentVoucherDomain) => {
        AccountApi.getPaymentVoucherWarnings(voucher.account.code, voucher.paymentReason.code, voucher.invoice)
            .then(response => {
                notification.warning({
                    message: <span>Đã có {response.data.length} lần chi cho hóa đơn <a className={'bold'} href={'/payment-vouchers?invoice=' + voucher.invoice} >{voucher.invoice}</a>, bạn vui lòng kiểm tra kỹ trước khi duyệt</span>,
                    key: "voucher_warning"
                })
            })
    }

    onReview = (code: string, id: string) => {
        this.setState({loading: true})
        PaymentVoucherApi.review(code)
            .then(() => {
                NotificationCommon.success({message: "Duyệt phiếu chi thành công!"})
                this.props.onApproveSuccess();
            })
            .catch((error: AxiosError) => {
                let message: any = "Duyệt phiếu chi thất bại!";
                if (lodash.get(error.response, 'status') === 400) {
                    if (lodash.get(error.response, 'data.title') === 'ref_exist') {
                        const transactionCode = lodash.get(error.response, 'data.transactionCode');
                        message = <span>Mã bút toán đã tồn tại trong giao dịch <b>{transactionCode}</b></span>;
                        NotificationCommon.error({
                            message: message
                        })
                    }
                    else if (get(error.response, 'data.title') === 'source_exist') {
                        NotificationCommon.error({
                            message: 'Không thể duyệt vì đã tồn tại giao dịch'
                        })
                    }
                    else {
                        NotificationCommon.error({
                            message: message
                        })
                    }
                }
                else if (lodash.get(error.response,'status') === 403) {
                    NotificationCommon.error({
                        message: 'Bạn không có quyền thực hiện thao tác này'
                    })
                }
                else {
                    NotificationCommon.error({message});
                }
            }).finally(() => {
            this.setState({loading: false})
        })
    }

    render() {
        const {loading, total, pageSize, items, currentPage} = this.props;
        const {columns} = this.state;
        return (
            <CommonTable
                className={'large-height-row'}
                columns={columns}
                dataSource={items}
                loading={loading}
                pagination={{
                    current: currentPage,
                    total: total,
                    pageSize: pageSize,
                    hideOnSinglePage: true,
                    onChange: this.props.onPageChange
                }}
                scroll={items.length >= 3 ? { y: 240, x: 1167 } : undefined}
            />
        );
    }
}

export default PaymentVoucherList;
