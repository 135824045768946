import React from "react";
import {PaymentReasonDomain} from "../../Domain/PaymentReasonDomain";
import {Button, Form, Input} from "antd";
import PaymentReasonApi from "../../Api/PaymentReasonApi";
import lodash from "lodash";
import { NotificationCommon } from "../../Component/Notification";


interface Props {
    onSuccess: () => void
    onCancel: () => void
    data: PaymentReasonDomain
}


export default function PaymentReasonForm(props:Props) {
    const [loading,isLoading] = React.useState<boolean>(false);
    const [form] = Form.useForm();
    const [,forceUpdateForm] = React.useState<PaymentReasonDomain>({});

    const {data,onSuccess,onCancel} = props;

    React.useEffect(()=> {
        forceUpdateForm({})
    },[])

    React.useEffect(()=> {
        if(data) {
            form.setFieldsValue({...data})
        }
    },[data,form])

    const handleOk = () => {
        form.validateFields()
            .then(values => {
                isLoading(true);
                if (data.code) {
                    return PaymentReasonApi.update(data.code, values)
                }
                else {
                    return PaymentReasonApi.create(values)
                }
            })
            .then(() => {
                if (data.code) {
                    NotificationCommon.success({
                        message: "Cập nhật thành công",
                        icon: <i className="fa-solid fa-circle-check"/>
                    })
                }
                else {
                    NotificationCommon.success({
                        message: "Tạo mục đích chi thành công",
                        icon: <i className="fa-solid fa-circle-check"/>
                    })
                }
                form.resetFields();
                onSuccess();
            })
            .catch(error => {
                if (lodash.get(error.response, 'status') === 400) {
                    if (error.response.data.title === 'payment_reason_existed') {
                        NotificationCommon.error({
                            message: "Mục đích chi đã tồn tại",
                            icon: <i className="fa-solid fa-triangle-exclamation"/>,
                        })
                    }
                    else {
                        NotificationCommon.error({
                            message: "Cập nhật thất bại",
                            icon: <i className="fa-solid fa-triangle-exclamation"/>,
                        })
                    }
                }
                else {
                    if (!error.hasOwnProperty('errorFields')) {
                        NotificationCommon.error({
                            message: "Cập nhật thất bại",
                            icon: <i className="fa-solid fa-triangle-exclamation"/>,
                        })
                    }
                }
            })
            .finally(() => {
                isLoading(false);
            })
    }

    const handleCancel = () => {
        form.resetFields();
        onCancel();
    }

    return(
        <div>
            <Form form={form} labelCol={{xs:24,sm:24,md:6,lg:24,xl:6,xxl:4}} labelAlign={'left'}>
                {!data.code && (
                    <Form.Item label={'Mã'} name={'code'} rules={[
                        {required: true, whitespace: true, message: 'Mã không được để trống'}
                    ]}>
                        <Input placeholder={'Vui lòng nhập mã mục đích chi'} />
                    </Form.Item>
                )}
                <Form.Item label={'Tên'} name={'name'} rules={[
                    {required: true, whitespace: true, message: 'Tên không được để trống'}
                ]}>
                    <Input placeholder={'Vui lòng nhập tên mục đích chi'} />
                </Form.Item>

                <Form.Item label={'Mô tả'} name={'description'}>
                    <Input.TextArea rows={1} placeholder={'Vui lòng nhập mô tả'} />
                </Form.Item>

                <Form.Item label={'Định dạng link hoá đơn'} name={'invoiceTemplate'}
                           rules={[
                               {
                                   pattern : new RegExp('https?:\\/\\/(www\\.)?(.*)\\.[\\w()]{1,6}\\b([-\\w()@:%_\\+.~#?&=]*\\/)'),
                                   message:"Sai định dạng đường dẫn"
                               }
                           ]}
                >
                    <Input placeholder={'https://invoice.com/invoices/{invoice}'} />
                </Form.Item>

                <Form.Item label={'Link hoá đơn mẫu'} name={'invoiceExample'}
                           rules={[
                               {
                                   pattern:new RegExp('https?:\\/\\/(www\\.)?(.*)\\.[\\w()]{1,6}\\b([-\\w()@:%_\\+.~#?&=]*\\/)'),
                                   message:"Sai định dạng đường dẫn"}
                           ]}
                >
                    <Input placeholder={'https://invoice.com/invoices/111-222-333'} />
                </Form.Item>
                <Form.Item shouldUpdate>
                    {()=> (
                        <div className={'mg-t-12 form-buttons'}>
                            <Button type={'ghost'} onClick={handleCancel}
                                    disabled={loading || lodash.isEmpty(form.getFieldsValue(true))}
                            >
                                <i className="fa-regular fa-xmark mg-r-8"/>Bỏ qua
                            </Button>
                            <Button type={'primary'} loading={loading} onClick={handleOk} className={'mg-l-12'}
                                    disabled={
                                        lodash.isEmpty(data) ?
                                        !form.isFieldTouched('name') || !form.isFieldTouched('code') ||
                                        !!form.getFieldsError().filter(({ errors }) => errors.length).length : !!form.getFieldsError().filter(({ errors }) => errors.length).length
                                    }
                            >
                                {data.code ? <span><i className="fa-regular fa-check mg-r-8"/>Cập nhật</span> : <span><i className="fa-regular fa-plus mg-r-8"/>Tạo mục đích chi</span>}
                            </Button>
                        </div>
                    )}
                </Form.Item>
            </Form>
        </div>
    )
}
