import {Tooltip} from 'antd';
import lodash from 'lodash';
import moment from 'moment';
import {Component} from "react";
import {Link} from "react-router-dom";
import PaymentVoucherDomain from "../../../Domain/PaymentVoucherDomain";
import Utils from "../../../Util/Utils";
import Paragraph from 'antd/lib/typography/Paragraph';
import CommonTable from '../../../Component/CommonTable';
import {STATUSES_CODE} from "../../../Util/Constants";
import ProjectDomain from "../../../Domain/ProjectDomain";

interface Props {
    loading?: boolean,
    items: Array<PaymentVoucherDomain>
    total: number,
    pageSize: number,
    currentPage: number,
    onChangePage: (page: number, pageSize: number) => void,
    projects:Array<ProjectDomain>
}

class DataList extends Component<Props, any> {

    state = {
        columns: [
            {
                title: "Mã phiếu/ Mã giao dịch",
                key: "transaction_code",
                render: (row: any) => {
                    const code = lodash.get(row, 'code');
                    const transaction = lodash.get(row, 'transaction');
                    return (
                        <div style={{minWidth: 233}}>
                            <div>
                                <span className="secondary2-color">Mã giao dịch:</span>{' '}
                                <Paragraph className={"italic transaction-code d-inline-block"}
                                           copyable={transaction && {
                                               icon: <i className="fa-regular fa-copy"
                                                        style={{color: '#7D7E7E', fontSize: 12}}/>, text: transaction
                                           }}>{transaction || '--'}</Paragraph>
                            </div>
                            <div>
                                <span className="secondary2-color">Mã phiếu:</span>{' '}
                                <Paragraph className={"text-blue d-inline-block"} copyable={code && {
                                    icon: <i className="fa-regular fa-copy" style={{color: '#7D7E7E', fontSize: 12}}/>,
                                    text: code
                                }}><Link to={`/payment-vouchers/${code}`}>{code}</Link></Paragraph>
                            </div>
                        </div>
                    )
                }
            },
            {
                title: "Thời gian giao dịch",
                key: "timestamp",
                render: (row: any) => {
                    const timestamp = row.timestamp ? moment(row.timestamp).format("DD/MM/YYYY HH:mm:ss") : "--";
                    return (
                        <div style={{width: 133}}>
                            {timestamp}
                        </div>
                    )
                }
            },
            {
                title: "Hệ thống",
                key: "project",
                width: '10%',
                dataIndex: 'project',
            },
            {
                title: "Người nhận tiền",
                key: "receiver",
                dataIndex: "receiver",
                render: (receiver: string, row: any) => {
                    const {projects} = this.props;
                    if (row.receiverRef) {
                        const cusUrl = Utils.getCustomerProjectUrl(projects,row.receiverRef.project,row.receiverRef.username);
                        return (
                            <Tooltip title={row.receiver}
                                     placement={'top'}>
                                {row.receiverRef
                                    ? (<a href={cusUrl}
                                       target={'_blank'} rel={'noreferrer'}>{lodash.get(row.receiverRef, 'username')}
                                    </a>)
                                    : lodash.get(row.receiverRef, 'username')}
                            </Tooltip>
                        )
                    }
                    return receiver;
                }
            },
            {
                title: "Nội dung giao dịch",
                key: "memo",
                dataIndex: "memo"
            },
            {
                title: "Số tiền",
                key: "amount",
                align: 'right',
                dataIndex: "amount",
                render: (amount: any, row: any) => {
                    const currency = lodash.get(row, 'currency.code');
                    return <span className={`bold fsz-16px negative-money`}>
                            {Utils.currencyFormatByCurrency(amount, currency)}
                    </span>
                }
            },
            {
                title: "Trạng thái",
                key: "status",
                align: 'right',
                render: (row: any) => {
                    let status = STATUSES_CODE.find((x) => x.code === lodash.get(row,'status.code'))
                    return <div>
                        <span className={`status-${status?.code}`}>{status?.name}</span></div>
                }
            },
            {
                title: "Trạng thái phiếu",
                key: 'reviewStatus',
                dataIndex: 'reviewStatus',
                align: 'center',
                render: (reviewStatus:string) => {
                    if (reviewStatus)
                        return (<Tooltip title={reviewStatus === 'PENDING' ? 'Chờ Duyệt' : 'Đã Duyệt'}>
                            <span className={'font-size-16'}
                                  style={{color: reviewStatus === 'PENDING' ? '#B1B1B1' : '#4DD69C'}}>
                                <i className="fa-solid fa-circle-check"/>
                            </span>
                        </Tooltip>)
                }
            }
        ]
    };

    handleChangePage = (page: number, pageSize: number) => {
        this.props.onChangePage(page, pageSize);
    };

    render() {
        const {loading, total, pageSize, items, currentPage} = this.props;
        const columns: any = this.state.columns;
        return (
            <>
                <CommonTable
                    columns={columns}
                    className="large-height-row"
                    dataSource={items}
                    loading={loading}
                    rowKey={() => lodash.uniqueId()}
                    pagination={{
                        current: currentPage,
                        pageSizeOptions: [10, 20, 25, 50, 100],
                        total: total,
                        pageSize: pageSize,
                        hideOnSinglePage: true,
                        onChange: this.handleChangePage,
                        onShowSizeChange: this.handleChangePage
                    }}
                />
            </>
        );
    }
}

export default DataList;
