import {Component} from "react";
import lodash from 'lodash';
import UserDomain from "../../../Domain/UserDomain";
import {Link} from "react-router-dom";
import CommonTable from "../../../Component/CommonTable";

interface Props {
    loading?: boolean,
    items: Array<UserDomain>
    total: number,
    pageSize: number,
    currentPage: number,
    onChangePage: (page: number, pageSize?: number) => void
}

interface State {
    loading: boolean,
    columns: Array<any>
}

class DataList extends Component<Props, State> {

    state: State = {
        loading: false,
        columns: [
            {
                title: "Username",
                key: "username",
                dataIndex: 'username',
                render: (username: any) => {
                    return <Link className={'blue-link'} to={`/staffs/${username}`}>{username}</Link>
                }
            },
            {
                title: "Email",
                key: "email",
                dataIndex: 'email'
            },
            {
                title: "Họ và tên",
                key: "name",
                dataIndex: 'name'
            },
            {
                title: "Số điện thoại",
                key: "mobile",
                dataIndex: 'mobile',
                render: (mobile:any) => {
                    return <span>{mobile ? mobile : '---'}</span>
                }
            }
        ]
    }

    handleChangePage = (page: number, pageSize ?: number) => {
        this.props.onChangePage(page, pageSize)
    }

    render() {
        const {loading, total, pageSize, items, currentPage} = this.props;
        const {columns} = this.state;
        return (
            <div>
                <CommonTable
                    className={'a-table'}
                    columns={columns}
                    dataSource={items}
                    loading={loading}
                    rowKey={() => lodash.uniqueId()}
                    pagination={{
                        current: currentPage,
                        total: total,
                        pageSize: pageSize,
                        // hideOnSinglePage: true,
                        onChange: this.handleChangePage,
                        showSizeChanger:true,
                        pageSizeOptions:[10,20,25,50,100],
                        showLessItems:true,
                    }}
                    rowClassName={'staff-record'}
                    scroll={{y:window.innerHeight - 300, x: 992}}
                />
            </div>
        );
    }
}

export default DataList;
