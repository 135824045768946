import {Badge, Card, Modal} from "antd";
import {AxiosError} from "axios";
import lodash, {get, identity, isEmpty} from "lodash";
import React, {createRef, RefObject} from 'react';
import AccountApi from "../../Api/AccountApi";
import MessageApi from "../../Api/MessageApi";
import PaymentVoucherCreateCommand from "../../Command/PaymentVoucherCreateCommand";
import ReceiptVoucherCreateCommand from "../../Command/ReceiptVoucherCreateCommand";
import DefaultComponent from "../../Component/DefaultComponent";
import DefaultLayout from "../../Component/Layout/Default";
import ModalPaymentVoucherCreate from "../../Component/ModalPaymentVoucherCreate";
import ModalReceiptVoucherCreate from "../../Component/ModalReceiptVoucherCreate";
import { NotificationCommon } from "../../Component/Notification";
import {AppContext} from "../../Context/AppContext";
import AccountDomain from "../../Domain/AccountDomain";
import MessageDomain from "../../Domain/MessageDomain";
import Utils from "../../Util/Utils";
import DataList from "./DataList";
import FilterForm from "./FilterForm";
import {ModalUpdateRemark} from "./ModalUpdateRemark";
import {DraggableModalRef} from "../../Component/DraggableModal";


interface State {
    account: AccountDomain,
    loading: boolean,
    messages: Array<MessageDomain>,
    total: number,
    pageSize: number,
    currentPage: number,
    showCreatePaymentVoucherModal: boolean,
    showCreateReceiptVoucherModal: boolean,
    showUpdateRemarkModal: boolean,
    lastSelectedMessageId: any,
    lastSelectedMessage: MessageDomain|undefined,
    collapsed:boolean,
}

interface Props {
}

class MessageList extends DefaultComponent<Props, State> {

    static contextType = AppContext;
    createPaymentVoucherRef: RefObject<DraggableModalRef> = createRef()
    createReceiptVoucherRef: RefObject<DraggableModalRef> = createRef()

    state: State = {
        account: Utils.getAppContext(this).state.account,
        loading: false,
        messages: [],
        pageSize: 25,
        total: 0,
        currentPage: 1,
        showCreatePaymentVoucherModal: false,
        showCreateReceiptVoucherModal: false,
        showUpdateRemarkModal: false,
        lastSelectedMessageId: undefined,
        lastSelectedMessage: undefined,
        collapsed:true,
    }

    componentDidMount() {
        this.fetchMessages({
            ...this.getQueryFromLocation()
        })
        if(!lodash.isEmpty(lodash.pickBy(Utils.getQueryFromLocation(this.props.location), (val: any) => !isEmpty(val) && identity(val)))) {
            this.setState({collapsed:false})
        }
    }

    componentDidUpdate(prevProps: any) {
        if (
            JSON.stringify(this.props.location.search) !== JSON.stringify(prevProps.location.search)
            || JSON.stringify(this.props.match.params) !== JSON.stringify(prevProps.match.params)
        ) {
            this.fetchMessages({
                ...this.getQueryFromLocation()
            });
            this.setState({
                ...this.state,
                pageSize:this.getQueryFromLocation().limit,
                currentPage:this.getQueryFromLocation().page,
            })
        }
    }

    fetchMessages = (filter: any = {}) => {
        const {account} = this.state;
        if (account.code) {
            this.setState({loading: true})
            filter = {
                offset: 0,
                limit: this.state.pageSize,
                sort: 'messageIncomingAt:desc',
                ...filter
            };

            AccountApi.getMessages(account.code, filter)
                .then(response => {
                    this.setState({
                        currentPage: parseInt(lodash.get(response, 'headers.x-page-number')) + 1,
                        pageSize: parseInt(lodash.get(response, 'headers.x-page-size')),
                        total: parseInt(lodash.get(response, 'headers.x-total-count')),
                        messages: response.data,
                    })
                })
                .finally(() => {
                    this.setState({loading: false});
                })
        }
    };

    onChangePage = (page: number, pageSize: any) => {
        this.pushCleanQueryToHistory({
            ...this.getQueryFromLocation(),
            page: page,
            limit: pageSize,
            offset: (page - 1) * pageSize
        })
    }

    handleRetryAnalyticMessage = async (message: MessageDomain) => {
        this.setState({loading: true});
        try {
            const response = await MessageApi.retryAnalytics(message.id);
            if (response.status === 204) {
                NotificationCommon.success({
                    message: 'Tin nhắn đã được phân tích lại thành công',
                    icon: <i className="fa-solid fa-circle-check"/>,
                });

                this.fetchMessages({
                    ...this.getQueryFromLocation()
                })
            }
            else {
                NotificationCommon.success({
                    message: 'Tin nhắn đã đẩy vào xử lý, vui lòng chờ vài phút sau xem lại',
                    icon: <i className="fa-solid fa-circle-check"/>,
                })
            }
        } catch (error: any) {
            if (get(error.response, 'status') === 400) {
                if (get(error.response, 'data.title') === 'message_processed') {
                    NotificationCommon.error({
                        message: 'Không thể phân tích tin nhắn vì tin nhắn đã được thực thi',
                        icon: <i className="fa-solid fa-triangle-exclamation"/>,
                    })
                }
                else {
                    NotificationCommon.error({
                        message: error.message,
                        icon: <i className="fa-solid fa-triangle-exclamation"/>,
                    })
                }
            }
            else {
                NotificationCommon.error({
                    message: error.message,
                    icon: <i className="fa-solid fa-triangle-exclamation"/>,
                })
            }
        }

        this.setState({loading: false});
    }

    handleModalCreatePaymentVoucherVisibleChange = (messageId: any, visible: boolean) => {
            if (this.createPaymentVoucherRef.current && visible){
                this.createPaymentVoucherRef.current?.setVisible(true)
                return
            }

        this.setState({
            lastSelectedMessageId: messageId,
            showCreatePaymentVoucherModal: visible
        })
    }

    handleModalCreateReceiptVoucherVisibleChange = (messageId: any, visible: boolean) => {
        if (this.createReceiptVoucherRef.current && visible){
            this.createReceiptVoucherRef.current?.setVisible(true)
            return
        }

        this.setState({
            lastSelectedMessageId: messageId,
            showCreateReceiptVoucherModal: visible
        })
    }

    handleModalUpdateRemarkVisibleChange = (message: MessageDomain|undefined, visible: boolean) => {
        this.setState({
            lastSelectedMessage: message,
            showUpdateRemarkModal: visible
        })
    }

    handleSubmitCreatePaymentVoucher = (data: PaymentVoucherCreateCommand) => {
        const {lastSelectedMessageId} = this.state;
        this.setState({loading: true});

        const confirmForceCreateVoucher = (form: PaymentVoucherCreateCommand, error: AxiosError) => {
            const voucherCodes = lodash.get(error.response, 'data.voucherCodes');
            let title = 'Mã bút toán đã tồn tại trong phiếu chi. Bạn có muốn tiếp tục tạo phiếu không?'
            if (voucherCodes && Array.isArray(voucherCodes)) {
                title = `Mã bút toán đã nằm trong phiếu chi ${voucherCodes.join(',')}. Bạn có muốn tiếp tục tạo phiếu không?`
            }

            Modal.confirm({
                title: title,
                okText: "Xác nhận",
                cancelText: "Bỏ qua",
                onOk: () => {
                    this.handleSubmitCreatePaymentVoucher({
                        ...form,
                        force: true
                    })
                }
            })
        }

        const newData = {...data, source: lastSelectedMessageId};
        MessageApi.createPaymentVoucher(lastSelectedMessageId, newData)
            .then((response) => {
                NotificationCommon.success({
                    message: "Tạo phiếu chi thành công",
                    icon: <i className="fa-solid fa-circle-check"/>,
                });

                this.setState({
                    showCreatePaymentVoucherModal: false,
                    lastSelectedMessageId: null
                });

                this.fetchMessages({
                    ...this.getQueryFromLocation()
                })
                window.open(`/payment-vouchers/${response.data.code}`)
            })
            .catch((error: AxiosError) => {
                if (lodash.get(error, 'response.status') === 400) {
                    if (lodash.get(error, 'response.data.title') === 'payment_ineligible') {
                        NotificationCommon.error({
                            message: 'Thời gian giao dịch không hợp lệ do đã chốt sổ',
                            icon: <i className="fa-solid fa-triangle-exclamation"/>,
                        })
                    } else if (lodash.get(error, 'response.data.title') === 'ref_exist') {
                        NotificationCommon.error({
                            message: `Mã bút toán đã tồn tại trong giao dịch ${lodash.get(error.response, 'data.transactionCode')}`,
                            icon: <i className="fa-solid fa-triangle-exclamation"/>,
                        })
                    } else if (lodash.get(error, 'response.data.title') === 'ref_exist_pending_voucher') {
                        confirmForceCreateVoucher(data, error);
                    } else if (lodash.get(error, 'response.data.title') === 'sms_exist') {
                        NotificationCommon.error({
                            message: `Tin nhắn đã tồn tại trong giao dịch ${lodash.get(error.response, 'data.transactionCode')}`,
                            icon: <i className="fa-solid fa-triangle-exclamation"/>,
                        })
                    }
                    else {
                        NotificationCommon.error({
                            message: "Có lỗi. Vui lòng kiểm tra lại thông tin trên form",
                            icon: <i className="fa-solid fa-triangle-exclamation"/>,
                        })
                    }
                }
                else {
                    NotificationCommon.error({
                        message: 'Lỗi: ' + error.message
                    })
                }
            })
            .finally(() => {
                this.setState({
                    loading: false
                });
            })
    }

    handleSubmitCreateReceiptVoucher = (form: ReceiptVoucherCreateCommand) => {
        const {lastSelectedMessageId} = this.state;
        this.setState({loading: true});

        const confirmForceCreateVoucher = (form: ReceiptVoucherCreateCommand, error: AxiosError) => {
            const voucherCodes = lodash.get(error.response, 'data.voucherCodes');
            let title = 'Mã bút toán đã tồn tại trong phiếu thu. Bạn có muốn tiếp tục tạo phiếu không?'
            if (voucherCodes && Array.isArray(voucherCodes)) {
                title = `Mã bút toán đã nằm trong phiếu thu ${voucherCodes.join(',')}. Bạn có muốn tiếp tục tạo phiếu không?`
            }

            Modal.confirm({
                title: title,
                okText: "Xác nhận",
                cancelText: "Bỏ qua",
                onOk: () => {
                    this.handleSubmitCreateReceiptVoucher({
                        ...form,
                        force: true
                    })
                }
            })
        }

        const newData = {...form, source: lastSelectedMessageId};
        MessageApi.createReceiptVoucher(lastSelectedMessageId, newData)
            .then((response) => {
                NotificationCommon.success({
                    message: "Tạo phiếu thu thành công",
                    icon: <i className="fa-solid fa-circle-check"/>,
                });
                this.setState({
                    showCreateReceiptVoucherModal: false,
                    lastSelectedMessageId: null
                });
                this.fetchMessages({
                    ...this.getQueryFromLocation()
                })
                window.open(`/receipt-vouchers/${response.data.code}`)
            })
            .catch((error: AxiosError) => {
                if (lodash.get(error, 'response.status') === 400) {
                    if (lodash.get(error, 'response.data.title') === 'payment_ineligible') {
                        NotificationCommon.error({
                            message: 'Thời gian giao dịch không hợp lệ do đã chốt sổ',
                            icon: <i className="fa-solid fa-triangle-exclamation"/>,
                        })
                    }
                    else if (lodash.get(error, 'response.data.title') === 'ref_exist') {
                        NotificationCommon.error({
                            message: `Mã bút toán đã tồn tại trong giao dịch ${lodash.get(error.response, 'data.transactionCode')}`,
                            icon: <i className="fa-solid fa-triangle-exclamation"/>,
                        })
                    }
                    else if (lodash.get(error, 'response.data.title') === 'ref_exist_pending_voucher') {
                        confirmForceCreateVoucher(form, error);
                    }
                    else if (lodash.get(error, 'response.data.title') === 'sms_exist') {
                        NotificationCommon.error({
                            message: `Tin nhắn đã tồn tại trong giao dịch ${lodash.get(error.response, 'data.transactionCode')}`,
                            icon: <i className="fa-solid fa-triangle-exclamation"/>,
                        })
                    }
                    else {
                        NotificationCommon.error({
                            message: "Có lỗi. Vui lòng kiểm tra lại thông tin trên form",
                            icon: <i className="fa-solid fa-triangle-exclamation"/>,
                        })
                    }
                }
                else {
                    NotificationCommon.error({
                        message: 'Lỗi: ' + error.message,
                        icon: <i className="fa-solid fa-triangle-exclamation"/>,
                    })
                }
            })
            .finally(() => {
                this.setState({
                    loading: false
                });
            })
    }

    handleUpdateRemarkSuccess = (message: MessageDomain) => {
        let {messages} = this.state;
        messages = messages.map(item => {
            if (item.id === message.id) {
                item.remark = message.remark
            }

            return item
        })

        this.setState({messages})
    }

    handleCollapseFilters = () => {
        this.setState({collapsed:!this.state.collapsed})
    }

    render() {
        const {
            messages,
            currentPage,
            pageSize,
            total,
            loading,
            account,
            showCreatePaymentVoucherModal,
            showCreateReceiptVoucherModal,
            showUpdateRemarkModal,
            lastSelectedMessage
        } = this.state;
        return (
            <DefaultLayout
                {...this.props}
                title={`Danh sách tin nhắn (${total})`}
                breadcrumb={[{title:'Danh Sách Tin Nhắn'}]}
            >
                <div className="main-content message-container">
                    <div className={`white-box message-container__filters message-container__filters${this.state.collapsed ? '-collapsed' : '-expanded'}`}>
                        <Card
                            title={
                                <div onClick={this.handleCollapseFilters}>
                                    <span className={'capitalize'}>Tìm kiếm</span>
                                    <span>{this.state.collapsed ? <i className="fa-solid fa-angle-down"/> : <i className="fa-solid fa-angle-up"/> }</span>
                                </div>
                            }
                        >
                            <FilterForm
                                {...this.props}
                            />
                        </Card>
                    </div>
                    <div className="white-box message-container__data-list">
                        <div className={'message-container__data-list-title'}>
                            <span className={'capitalize mg-r-5'}>Danh sách tin nhắn</span>
                            <Badge count={Utils.currencyFormat(total)} overflowCount={9999}/>
                        </div>
                        <DataList
                            loading={loading}
                            account={account}
                            items={messages}
                            total={total}
                            pageSize={pageSize}
                            currentPage={currentPage}
                            onChangePage={this.onChangePage}
                            onRetryAnalyticMessage={this.handleRetryAnalyticMessage}
                            onModalCreatePaymentVoucherVisibleChange={this.handleModalCreatePaymentVoucherVisibleChange}
                            onModalCreateReceiptVoucherVisibleChange={this.handleModalCreateReceiptVoucherVisibleChange}
                            onModalUpdateRemarkVisibleChange={this.handleModalUpdateRemarkVisibleChange}
                        />
                    </div>
                </div>

                {showCreatePaymentVoucherModal && (
                    <ModalPaymentVoucherCreate
                        modalRef={this.createPaymentVoucherRef}
                        loading={loading}
                        onVisibleChange={(visible) => this.handleModalCreatePaymentVoucherVisibleChange(undefined, visible)}
                        onSubmit={this.handleSubmitCreatePaymentVoucher}
                    />
                )}

                {showCreateReceiptVoucherModal && (
                    <ModalReceiptVoucherCreate
                        modalRef={this.createReceiptVoucherRef}
                        loading={loading}
                        onVisibleChange={(visible) => this.handleModalCreateReceiptVoucherVisibleChange(undefined, visible)}
                        onSubmit={this.handleSubmitCreateReceiptVoucher}
                    />
                )}

                {showUpdateRemarkModal && lastSelectedMessage && (
                    <ModalUpdateRemark
                        message={lastSelectedMessage}
                        onVisibleChange={(visible) => this.handleModalUpdateRemarkVisibleChange(undefined, visible)}
                        onSuccess={this.handleUpdateRemarkSuccess}
                    />
                )}
            </DefaultLayout>
        );
    }
}

export default MessageList;
