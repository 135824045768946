import queryString from "query-string"
import apiClient from "../Util/ApiClient"
import {AccountAddPrivilegeCommand} from "../Command/AccountAddPrivilegeCommand"
import {AccountUpdatePrivilegeCommand} from "../Command/AccountUpdatePrivilegeCommand"
import {WebhookCreateCommand} from "../Command/WebhookCreateCommand"
import {WebhookUpdateCommand} from "../Command/WebhookUpdateCommand"
import {WebhookQueryCriteria} from "../QueryCriteria/WebhookQueryCriteria"
import TransactionUpdateCommand from "../Command/TransactionUpdateCommand"
import TransactionUpdateRemarkCommand from "../Command/TransactionUpdateRemarkCommand";
import {DebitTransactionConfigure} from "../Command/DebitTransactionConfigure";

export default class AccountApi {
	static filter = (query = {}) => {
		return apiClient.get("/accounts", {
			params: query,
		})
	}

	static autocomplete = (query = {}) => {
		return apiClient.get("/accounts/autocomplete", {
			params: query,
		})
	}

	static create = (data: any) => {
		return apiClient.post("/accounts", data)
	}

	static findByCode = (code: string) => {
		return apiClient.get(`/accounts/${code}`)
	}

	static update = (code: string, data: any) => {
		return apiClient.patch(`/accounts/${code}`, data)
	}

	static getTransactions = (account: any, query = {}) => {
		return apiClient.get(`/accounts/${account}/transactions`, {
			params: query,
		})
	}

	static updateTransaction = (account: any, code: string, command: TransactionUpdateCommand) => {
		return apiClient.patch(`/accounts/${account}/transactions/${code}`, command)
	}

	static updateTransactionRemark = (account: any, code: string, command: TransactionUpdateRemarkCommand) => {
		return apiClient.patch(`/accounts/${account}/transactions/${code}/remark`, command)
	}

	static exportTransactions = (account: any, query = {}) => {
		return apiClient.post(`/accounts/${account}/transactions/export?${queryString.stringify(query)}`)
	}

	static settlement = (account: string, data: any) => {
		return apiClient.post(`/accounts/${account}/settlements`, data)
	}

	static enquireBalance = (account: string, timestamp: any) => {
		return apiClient.get(`/accounts/${account}/enquire-balance`, {
			params: {
				timestamp,
			},
		})
	}

	static getPrivileges = (account: any, query = {}) => {
		return apiClient.get(`/accounts/${account}/privileges`, {
			params: query,
		})
	}

	static addPrivilege = (account: any, data: AccountAddPrivilegeCommand) => {
		return apiClient.post(`/accounts/${account}/privileges`, data)
	}

	static removePrivilege = (account: any, privilegeId: any) => {
		return apiClient.delete(`/accounts/${account}/privileges/${privilegeId}`)
	}

	static updatePrivilege = (account: any, privilegeId: any, data: AccountUpdatePrivilegeCommand) => {
		return apiClient.patch(`/accounts/${account}/privileges/${privilegeId}`, data)
	}

	static getMessages = (account: any, params = {}) => {
		return apiClient.get(`/accounts/${account}/messages`, { params })
	}

	static getOwners = (account: string) => {
		return apiClient.get(`/accounts/${account}/owners`)
	}

	static addOwner = (account: string, owners: Array<string>) => {
		return apiClient.post(`/accounts/${account}/owners`, { owners })
	}

	static removeOwner = (account: string, owner: any) => {
		return apiClient.delete(`/accounts/${account}/owners/${owner}`)
	}

	static retrySynchronizeTransaction = (account: string, transactionCode: string) => {
		return apiClient.post(`/accounts/${account}/transactions/${transactionCode}/retry-synchronize`)
	}

	static createWebhook = (account: string, command: WebhookCreateCommand) => {
		return apiClient.post(`/accounts/${account}/webhooks`, command)
	}

	static updateWebhook = (account: string, webhookId: string, command: WebhookUpdateCommand) => {
		return apiClient.patch(`/accounts/${account}/webhooks/${webhookId}`, command)
	}

	static getWebhooks = (account: string, criteria: WebhookQueryCriteria = {}) => {
		return apiClient.get(`/accounts/${account}/webhooks`, {
			params: criteria,
		})
	}

	static addProjects = (account: string, projects: Array<string>) => {
		return apiClient.post(`/accounts/${account}/projects`, projects)
	}

	static removeProjects = (account: string, projects: Array<string>) => {
		return apiClient.patch(`/accounts/${account}/projects`, projects)
	}

	static getPaymentVoucherWarnings = (account: string, paymentReason: string, invoice: string) => {
		return apiClient.get(`/accounts/${account}/payment-vouchers/warnings`, {
			params: { paymentReason, invoice },
		})
	}

	static debitTransactionConfigure = (account: string, data: DebitTransactionConfigure) => {
		return apiClient.patch(`/accounts/${account}/debit-transaction-configure`, data)
	}

	static transactionExpireConfigure = (accountCode:string,int:number) => {
		return apiClient.patch(`/accounts/${accountCode}/transaction-expire-configure`,{expire:int})
	}

	static reviewTransaction = (accountCode:string,transactionCode:string) => {
		return apiClient.patch(`/accounts/${accountCode}/transactions/${transactionCode}/review`)
	}

	static markRechargedTransaction = (accountCode:string,transactionCode:string) => {
		return apiClient.patch(`/accounts/${accountCode}/transactions/${transactionCode}/recharge`,{
			recharge:true
		})
	}
}
