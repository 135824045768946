import React from 'react';
import AccountDomain from "../../../Domain/AccountDomain";
import {Button, Card, Col, Empty, Row, Tooltip} from "antd";
import UserDomain from "../../../Domain/UserDomain";
import AccountApi from "../../../Api/AccountApi";
import AddUserModal from "./AddUserModal";
import lodash from 'lodash';
import RoleSelector from "../../../Component/SelectRole";
import SecurityService from "../../../Util/SecurityService";
import {NotificationCommon} from '../../../Component/Notification';
import {ModalCommon} from "../../../Component/ModalCommon";

interface Props {
    account: AccountDomain,
    accountCode: any
}

interface State {
    users: Array<UserDomain>,
    loading: boolean,
    showAddUserModal: boolean
}
class AccountUser extends React.Component<Props, State> {

    state: State = {
        users: [],
        loading: false,
        showAddUserModal: false
    }

    componentDidMount() {
        this.fetchAccountUsers();
    }

    fetchAccountUsers = () => {
        this.setState({loading: true});
        AccountApi.getPrivileges(this.props.accountCode)
            .then(response => {
                this.setState({users: response.data});
            })
            .finally(() => {
                this.setState({loading: false});
            })
    }

    handleAddUserModalVisibleChange = (visible: boolean) => {
        this.setState({showAddUserModal: visible});
    }

    handleRemovePrivilege = (privilegeItem: any) => {
        const {accountCode} = this.props;
        ModalCommon.confirm({
            title: <span>Bạn có chắc chắn muốn xoá vai trò của <b>{`${lodash.get(privilegeItem, 'staff.name')} (${lodash.get(privilegeItem, 'staff.username')})`}</b> không?</span>,
            onOk: () => {
                this.setState({loading: true});
                AccountApi.removePrivilege(accountCode, privilegeItem.id)
                    .then(() => {
                        NotificationCommon.success({
                            message: 'Xoá quyền thành công'
                        });

                        this.fetchAccountUsers();
                    })
                    .catch(() => {
                        NotificationCommon.error({
                            message: 'Xoá quyền thất bại'
                        })
                    })
                    .finally(() => {
                        this.setState({loading: false});
                    })
            },
            okText: 'Đồng ý',
            cancelText: 'Hủy',
        })
    }

    handleChangePrivilege = (privilegeItem: any, role: any) => {
        const {accountCode} = this.props;
        this.setState({loading: true});
        AccountApi.updatePrivilege(accountCode, privilegeItem.id, {
            staff: lodash.get(privilegeItem, 'staff.username'),
            role: role
        })
            .then(() => {
                NotificationCommon.success({
                    message: 'Cập nhật thành công'
                });

                this.fetchAccountUsers();
            })
            .catch(() => {
                NotificationCommon.error({
                    message: 'Cập nhật thất bại'
                })
            })
            .finally(() => {
                this.setState({loading: false});
            })
    }

    render() {
        const {showAddUserModal, users, loading} = this.state;
        const {account} = this.props;
        const loggedUser = SecurityService.getUser();
        return (
            <Card
                className={'account-info'}
                title={`Nhân viên`}
                extra={<Button type={'link'} size={'small'} onClick={() => this.handleAddUserModalVisibleChange(true)} className={'font-size-12'}>
                    <span><i className="fa-solid fa-plus mg-r-6"/></span>
                    <span>Thêm nhân viên</span>
                </Button>}
            >
                {users.length ? users.map((user: any) => (
                    <Row gutter={20} key={lodash.get(user, 'id')} className={'user-item mg-bt-10'}>
                        <Col xs={8} md={10} lg={7} className={'d-inline-block'}>
                            <Tooltip title={lodash.get(user, 'staff.name')}><span>{lodash.get(user, 'staff.username')}</span></Tooltip>
                        </Col>
                        <Col xs={12} md={12} lg={13} className={'d-inline-block'}>
                            <RoleSelector
                                value={lodash.get(user, 'role.code')}
                                onChange={this.handleChangePrivilege.bind(this, user)}
                                selectProps={{
                                    loading: loading,
                                    disabled: loading,
                                    allowClear: false,
                                    className: 'width-100pc pointer'
                                }}
                            />
                        </Col>
                        <Col xs={4} md={2} lg={4} className={'d-inline-block'}>
                            {lodash.get(user, 'staff.username') !== lodash.get(loggedUser, 'username') && (
                                <span onClick={this.handleRemovePrivilege.bind(this, user)} className={'delete-btn'}>
                                    Xóa
                                </span>
                            )}
                        </Col>
                    </Row>
                )) : (
                    <Empty description={'Chưa có nhân viên'} />
                )}

                <AddUserModal
                    account={account}
                    visible={showAddUserModal}
                    onVisibleChange={this.handleAddUserModalVisibleChange}
                    fetchAccountUsers={this.fetchAccountUsers}
                />
            </Card>
        );
    }
}

export default AccountUser;
