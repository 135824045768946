import {FC, memo} from "react"
import {LogItemInterface} from "../../../Component/LogModal/type"
import TransactionsDomain from "../../../Domain/TransactionDomain"
import {ChangedValuesInterface, LogInterface, TransactionEvent} from "../../../Interface/LogInterface"
import Utils from "../../../Util/Utils";
import {LogChangedIcon} from "../../../Component/LogModal/ChangedIcon";

interface ListItemProps extends LogItemInterface<LogInterface<TransactionEvent, TransactionsDomain>> {
}

const ListItem: FC<ListItemProps> = memo(
    ({item, logQuery, newObject, changedValues}: ListItemProps) => {
        const oldValues: any = changedValues[0]?.left
        const newValues: any = changedValues[0]?.right
        const {activity} = item

        const renderUpdateParticipant = () => {
            if (activity !== TransactionEvent.UPDATE_PARTICIPANT) {
                return
            }

            const titleFieldsMapping: any = {
                project: 'Hệ thống',
                participant: 'Người nhận/nộp',
                participantRef: 'Người nhận/nộp'
            }
            const parseChangedValues: ChangedValuesInterface[] = handleParseChangedValues(changedValues)

            return (
                <>
                    <span>Cập nhật giao dịch</span>
                    {parseChangedValues.map(({key, left, right}: ChangedValuesInterface, index: number) => (
                        <span key={`value_${index}`}>
                            <br/>
                            {titleFieldsMapping[key] || key}{": "}<span className="font-medium">{left || '---'}</span><LogChangedIcon/>
                            <span className="font-medium">{right || "---"}</span>
                        </span>
                    ))}
                </>
            )
        }

        const renderDebitAndCreditTransaction = () => {
            const getResult = (createdBy: string, code: string) => {
                return (<>
                    Giao dịch <span
                    className="font-medium">#{newObject.code}</span> được tạo từ {createdBy} <span
                    className="font-medium">#{code}</span>
                </>)
            }

            if (newObject.receiptVoucher) {
                return getResult('phiếu thu', newObject.receiptVoucher)
            }

            if (newObject.paymentVoucher) {
                return getResult('phiếu chi', newObject.paymentVoucher)
            }

            return getResult('tin nhắn', newObject.source?.split(':').pop())
        }

        return (<div>{
            {
                [TransactionEvent.INITIAL]: (
                    <>
                        Khởi tạo giao dịch <span className="font-medium">#{logQuery?.resourceCode}</span>
                    </>
                ),

                [TransactionEvent.DEBIT]: renderDebitAndCreditTransaction(),

                [TransactionEvent.CREDIT]: renderDebitAndCreditTransaction(),

                [TransactionEvent.UPDATE_PARTICIPANT]: renderUpdateParticipant(),

                [TransactionEvent.UPDATE_REMARK]: <>Cập nhật ghi chú: <span className="font-medium">{oldValues || '---'}</span> <LogChangedIcon/> <span
                    className="font-medium">{newValues}</span></>,

                [TransactionEvent.SYNCHRONIZE]: <>Giao dịch <span
                    className="font-medium">#{newObject.tx}</span> {newObject.status === 'SUCCESS' ? '' : 'không'} phát
                    sinh tài chính cho khách hàng</>,
                [TransactionEvent.REVIEWED]: <>
                    Thay đổi trạng thái từ <span className="font-medium">Chờ duyệt</span> thành <span
                    className="font-medium">Đã duyệt</span>
                </>
            }[activity]
        }</div>)
    }
)

export {ListItem}

const handleParseChangedValues = (changedValues: ChangedValuesInterface[]) => {
    let participantIndex = -1
    let participantRefIndex = -1

    changedValues.forEach(({key, right}: ChangedValuesInterface, index: number) => {
        if (key === 'participant') {
            participantIndex = index
        }

        if (key === 'participantRef') {
            participantRefIndex = index
        }
    })

    return Utils.parseLogReceiverPayer(participantIndex, participantRefIndex, changedValues)
}
