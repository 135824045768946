import {Badge} from "antd";
import lodash from 'lodash';
import DefaultComponent from "../../Component/DefaultComponent";
import DefaultLayout from "../../Component/Layout/Default";
import AccountBoxStatisticConsumer from "../../Consumer/AccountBoxStatisticConsumer";
import {AppContext} from "../../Context/AppContext";
import PaymentVoucherList from "./PaymentVoucherList";
import ReceiptVoucherList from "./ReceiptVoucherList";
import Utils from "../../Util/Utils";
import AccountDomain from "../../Domain/AccountDomain";
import {FilterForm} from "./FilterForm";
import PaymentVoucherDomain from "../../Domain/PaymentVoucherDomain";
import ReceiptVoucherDomain from "../../Domain/ReceiptVoucherDomain";
import PaymentVoucherApi from "../../Api/PaymentVoucherApi";
import ReceiptVoucherApi from "../../Api/ReceiptVoucher";

interface IVoucherPagination {
    total: number
    currentPage: number
    pageSize: number
}

interface State {
    account: AccountDomain,
    paymentVouchers: PaymentVoucherDomain[]
    receiptVouchers: ReceiptVoucherDomain[]
    loadingPaymentVoucher: boolean
    loadingReceiptVoucher: boolean
    paymentVoucherPagination: IVoucherPagination
    receiptVoucherPagination: IVoucherPagination
}

class PendingVoucher extends DefaultComponent<any, State> {

    static contextType = AppContext;

    state: State = {
        account: this.context.state.account,
        paymentVouchers: [],
        receiptVouchers: [],
        loadingPaymentVoucher: false,
        loadingReceiptVoucher: false,
        paymentVoucherPagination: {
            total: 0,
            currentPage: 1,
            pageSize: 50
        },
        receiptVoucherPagination: {
            total: 0,
            currentPage: 1,
            pageSize: 50
        }
    };

    componentDidMount() {
        this.fetchReceiptVouchers()
        this.fetchPaymentVouchers()
    }

    componentDidUpdate(prevProps: any) {
        if (
            JSON.stringify(this.props.location.search) !== JSON.stringify(prevProps.location.search)
            || JSON.stringify(this.props.match.params) !== JSON.stringify(prevProps.match.params)
        ) {
            this.fetchReceiptVouchers({
                ...this.getQueryFromLocation()
            });

            this.fetchPaymentVouchers({
                ...this.getQueryFromLocation()
            });
        }
    }

    updateAccount = () => {
        const {account} = this.state;
        setTimeout(() => {
            Utils.getAppContext(this).func.fetchAccountAndUpdateState(account.code);
        }, 2000)
        this.pushCleanQueryToHistory({
            ...this.getQueryFromLocation(),
            t: Date.now()
        })
    }

    fetchPaymentVouchers = (filter: any = {}) => {
        const {account, paymentVoucherPagination} = this.state;
        if (account.code) {
            filter = {
                offset: 0,
                limit: paymentVoucherPagination.pageSize,
                sort: 'receiver:asc,preparedAt:asc',
                status: 'PENDING',
                ...filter
            };

            this.setState({loadingPaymentVoucher: true});
            PaymentVoucherApi.getPaymentVouchersByAccount(lodash.get(account, 'code'), filter)
                .then(response => {
                    this.setState({
                        paymentVouchers: response.data,
                        paymentVoucherPagination: {
                            currentPage: parseInt(lodash.get(response, 'headers.x-page-number')) + 1,
                            pageSize: parseInt(lodash.get(response, 'headers.x-page-size')),
                            total: parseInt(lodash.get(response, 'headers.x-total-count'))
                        }
                    })
                })
                .finally(() => {
                    this.setState({loadingPaymentVoucher: false});
                })
        }
    };

    fetchReceiptVouchers = (filter: any = {}) => {
        const {account, receiptVoucherPagination} = this.state;
        if (account.code) {
            this.setState({loadingReceiptVoucher: true})

            filter = {
                offset: 0,
                limit: receiptVoucherPagination.pageSize,
                status: 'PENDING',
                sort: 'payer:asc,preparedAt:asc',
                ...filter,
            }

            ReceiptVoucherApi.getReceiptVouchersByAccount(lodash.get(account, 'code'), filter)
                .then(response => {
                    this.setState({
                        receiptVouchers: response.data,
                        receiptVoucherPagination: {
                            currentPage: parseInt(lodash.get(response, 'headers.x-page-number')) + 1,
                            pageSize: parseInt(lodash.get(response, 'headers.x-page-size')),
                            total: parseInt(lodash.get(response, 'headers.x-total-count'))
                        }
                    })
                })
                .finally(() => {
                    this.setState({loadingReceiptVoucher: false});
                })
        }
    };

    handleReceiptVoucherPageChange = (page: number, pageSize: number) => {
        this.fetchReceiptVouchers({
            ...this.getQueryFromLocation(),
            offset: (page - 1) * pageSize,
            limit: pageSize
        })
    }

    handlePaymentVoucherPageChange = (page: number, pageSize: number) => {
        this.fetchPaymentVouchers({
            ...this.getQueryFromLocation(),
            offset: (page - 1) * pageSize,
            limit: pageSize
        })
    }

    getTotalAmount = (items: Array<PaymentVoucherDomain|ReceiptVoucherDomain>) => {
        return items.reduce(
            (prevValue, curItem) => prevValue + curItem.amount,
            0
        )
    }

    render() {
        const {account, receiptVouchers, paymentVouchers, receiptVoucherPagination, paymentVoucherPagination, loadingReceiptVoucher, loadingPaymentVoucher} = this.state;
        return (
            <DefaultLayout
                {...this.props}
                title={`${lodash.get(account, 'name')} - Danh sách phiếu thu/chi chờ duyệt`}
                breadcrumb={[{title: 'Phiếu chờ giao dịch'}]}
            >
                <div className="main-content pending-voucher-container">
                    <div className="white-box px-12 py-12">
                        <AccountBoxStatisticConsumer />
                    </div>

                    <FilterForm
                        location={this.props.location}
                        match={this.props.match}
                        history={this.props.history}
                    />

                    <div className="white-box  py-12 px-12">
                        <div className="table-container">
                            <div className="table-header space-between mg-bt-12">
                                <div className="align-center font-medium fsz-16px capitalize">
                                    Danh sách phiếu thu <Badge count={receiptVoucherPagination.total} showZero overflowCount={999999} className="pd-l-8 pd-bt-4" />
                                </div>

                                <div>
                                    Tổng: <span className={'positive-money font-medium fsz-24px'}>{Utils.currencyFormatByCurrency(this.getTotalAmount(receiptVouchers), account.currency)}</span>
                                </div>
                            </div>
                                
                            <ReceiptVoucherList
                                {...this.props}
                                account={account}
                                onApproveSuccess={this.updateAccount}
                                items={receiptVouchers}
                                loading={loadingReceiptVoucher}
                                currentPage={receiptVoucherPagination.currentPage}
                                pageSize={receiptVoucherPagination.pageSize}
                                total={receiptVoucherPagination.total}
                                onPageChange={this.handleReceiptVoucherPageChange}
                            />
                            </div>
                    </div>

                    <div className="white-box  py-12 px-12">
                    <div className="table-container">
                        <div className="table-header space-between mg-bt-12">
                            <div className="align-center font-medium fsz-16px capitalize">
                            Danh sách phiếu chi <Badge count={paymentVoucherPagination.total} showZero overflowCount={999999} className="pd-l-8 pd-bt-4" />
                            </div>

                            <div>
                                Tổng: <span className={'negative-money font-medium fsz-24px'}>{Utils.currencyFormatByCurrency(this.getTotalAmount(paymentVouchers), account.currency)}</span>
                            </div>
                        </div>

                        <PaymentVoucherList
                            {...this.props}
                            account={account}
                            onApproveSuccess={this.updateAccount}
                            items={paymentVouchers}
                            loading={loadingPaymentVoucher}
                            currentPage={paymentVoucherPagination.currentPage}
                            pageSize={paymentVoucherPagination.pageSize}
                            total={paymentVoucherPagination.total}
                            onPageChange={this.handlePaymentVoucherPageChange}
                        />
                        </div>
                    </div>
                </div>
            </DefaultLayout>
        );
    }
}

export default PendingVoucher;
