import {InfoCircleOutlined, WarningTwoTone} from '@ant-design/icons'
import {Button, Checkbox, Tooltip} from 'antd'
import lodash from 'lodash'
import moment from 'moment'
import React, {Component, createRef} from 'react'
import {Link} from 'react-router-dom'
import {AppContext} from '../../../Context/AppContext'
import AccountDomain from '../../../Domain/AccountDomain'
import TransactionsDomain from '../../../Domain/TransactionDomain'
import Utils from '../../../Util/Utils'
import ProjectDomain from '../../../Domain/ProjectDomain'
import Paragraph from 'antd/lib/typography/Paragraph'
import {ColumnsType} from 'antd/lib/table'
import CommonTable from '../../../Component/CommonTable'
import SecurityService from '../../../Util/SecurityService'
import {PERMISSIONS, REVIEW_STATUSES_CODE} from '../../../Util/Constants'
import {LogResrouceType} from '../../../Interface/LogInterface'
import {LogModal} from '../../../Component/LogModal'
import {ListItem} from './LogListItem'
import TransactionListByUser from "../../../Component/Drawer/TransactionListByUser";
import {LogModalRef} from "../../../Component/LogModal/type";
import {ExpandableConfig} from "rc-table/lib/interface";
import SubTransactionViewList from "../../../Component/SubTransactionViewList";
import PaymentReasonApi from "../../../Api/PaymentReasonApi";
import SubTransaction from "../../../Api/SubTransaction";
import {NotificationCommon} from "../../../Component/Notification";

interface Props {
    projects: Array<ProjectDomain>
    loading?: boolean
    items: Array<TransactionsDomain>
    total: number
    pageSize: number
    currentPage: number
    onChangePage: (page: number, pageSize?: number) => void
    onRetrySynchronize: (transactionCode: string) => void
    handleCurrentTransactionEdit: (record: TransactionsDomain) => void
    handleModalEditTransactionVisibleChange: (visible: boolean) => void
    handleModalEditTransactionNoteVisibleChange: (visible: boolean) => void
    handleReviewTransaction: (row: TransactionsDomain) => void;
    handleModalCreateSubTransactionVisibleChange: (visible: boolean) => void
    handleMarkRechargeTransaction: (row: TransactionsDomain) => void;
    fetchTransactions: (filter: any) => void;
}

interface State {
    account: AccountDomain
    showTransactionsByUser: boolean,
    selectedRow: TransactionsDomain | undefined,
    paymentReasons: any[],
    allowCreateSubs: boolean,
    defaultExpandedRow:string[],

}

class DataList extends Component<Props, State> {
    static contextType = AppContext
    logRef = createRef<LogModalRef>()

    state: State = {
        account: Utils.getAppContext(this).state.account,
        showTransactionsByUser: false,
        selectedRow: undefined,
        paymentReasons: [],
        allowCreateSubs: true,
        defaultExpandedRow:[],
    }

    componentDidMount() {
        this.getPaymentReasons();
    }

    getConfigColumns = () => {
        const {account} = this.state
        const {handleReviewTransaction, projects} = this.props
        let columns: ColumnsType<any> = [
            {
                title: 'Mã',
                key: 'code',
                width: '18.5%',
                render: (record: any) => {
                    const code = lodash.get(record, 'receiptVoucher') || lodash.get(record, 'paymentVoucher')
                    const ref = lodash.get(record, 'ref')
                    const referenceTx = lodash.get(record, 'referenceTx')
                    const voucherLink = lodash.get(record, 'receiptVoucher')
                        ? `/receipt-vouchers/${code}`
                        : `/payment-vouchers/${code}`

                    return (
                        <div style={{minWidth: 200}}>
                            <div style={{wordBreak:'break-all'}}>
                                <span className="secondary2-color">Mã giao dịch:</span>{' '}
                                <Paragraph
                                    className={'italic transaction-code d-inline-block'}
                                    copyable={{
                                        icon: (
                                            <i
                                                className="fa-regular fa-copy"
                                                style={{color: '#7D7E7E', fontSize: 12}}
                                            />
                                        ),
                                        text: record.code,
                                    }}
                                >
                                   {record.code}
                                </Paragraph>
                            </div>

                            <div>
                                <span className="secondary2-color">Mã phiếu: </span>
                                {code ? (
                                    <Paragraph
                                        className="d-inline-block"
                                        copyable={{
                                            icon: (
                                                <i
                                                    className="fa-regular fa-copy pd-bt-2"
                                                    style={{color: '#7D7E7E', fontSize: 12}}
                                                />
                                            ),
                                            text: code,
                                        }}
                                    >
                                        <Link title={'Mã phiếu'} to={voucherLink}>
                                            {code}
                                        </Link>
                                    </Paragraph>
                                ) : (
                                    '--'
                                )}
                            </div>

                            <div>
                                <span className="secondary2-color">Mã bút toán: </span>{' '}
                                {ref ? (
                                    <Paragraph
                                        className="d-inline-block"
                                        copyable={{
                                            icon: (
                                                <i
                                                    className="fa-regular fa-copy pd-bt-2"
                                                    style={{color: '#7D7E7E', fontSize: 12}}
                                                />
                                            ),
                                            text: ref,
                                        }}
                                    >
                                        {ref}
                                    </Paragraph>
                                ) : (
                                    '--'
                                )}
                            </div>

                            <div>
                                <span className="secondary2-color">Mã tham chiếu: </span>{' '}
                                {referenceTx ? (
                                    <Paragraph
                                        className="d-inline-block"
                                        copyable={{
                                            icon: (
                                                <i
                                                    className="fa-regular fa-copy pd-bt-2"
                                                    style={{color: '#7D7E7E', fontSize: 12}}
                                                />
                                            ),
                                            text: referenceTx,
                                        }}
                                    >
                                        {referenceTx}
                                    </Paragraph>
                                ) : (
                                    '--'
                                )}
                            </div>
                        </div>
                    )
                },
            },
            {
                title: 'Hệ thống',
                key: 'system',
                width: '8%',
                align: 'center',
                render: (record: any) => {
                    const {projects} = this.props
                    const projectCode = lodash.get(record, 'participantRef.project') || record.project
                    const project = projects.find((item) => item.code === projectCode)
                    if (project) {
                        return (
                            <>
                                <div>({project.code})</div>
                                <div>{project.name}</div>
                            </>
                        )
                    }
                    return '--'
                },
            },
            {
                title: 'Thời gian GD',
                key: 'timestamp',
                dataIndex: 'timestamp',
                width: '9%',
                align: 'right',
                render: (d: any) => (<span className={''}>{d ? moment(d).format('DD/MM/YYYY HH:mm:ss') : '--'}</span>),
            },
            {
                title: 'Người nhận/nộp',
                key: 'participant',
                dataIndex: 'participant',
                width: '10%',
                render: (d: any, record: any) => {
                    const renderCustomerUserName = () => {
                        if (lodash.has(record, 'participantRef')) {
                            const cusUrl = Utils.getCustomerProjectUrl(projects, record.participantRef.project, record.participantRef.username);
                            return (
                                <span>
                                    <a href={cusUrl}
                                       target={'_blank'} rel={'noreferrer'}
                                    >
                                        {lodash.get(record, 'participantRef.username')}
                                    </a>
                                </span>
                            )
                        }
                        return lodash.get(record, 'participantRef.username')
                    }
                    return (
                        <div className={'flex width-100pc'}>
                            {!record.participantRef ? (
                                <span className={'d-inline-block participant'}>{d || '---'}</span>
                            ) : (
                                <Tooltip
                                    title={
                                        lodash.get(record, 'participantRef.fullname')
                                            ? `${lodash.get(record, 'participantRef.fullname')} (${lodash.get(
                                                record,
                                                'participantRef.project'
                                            )})`
                                            : d
                                    }
                                    placement={'top'}
                                >
                                    <span className={`d-inline-block participant`}>{renderCustomerUserName()}</span>
                                </Tooltip>
                            )}
                            <div className={'mg-l-4'}>
                                <Tooltip title={'Lịch sử giao dịch khách hàng'}>
                                    {record.participant || record.participantRef ? <span
                                            onClick={() => this.openDrawerList(record)}
                                            className={''} style={{color: '#148F14'}}> |<i
                                            className="fa-solid fa-memo-circle-info pd-l-8"/></span>
                                        : null}
                                </Tooltip>
                            </div>
                        </div>
                    )
                },
            },
            {
                title: 'Vai trò',
                key: 'roles',
                align: 'center',
                width: '5%',
                render: (row: any) => {
                    return (
                        <Tooltip title={<div className={'flex-col'}>
                            <div>Người tạo GD: <span
                                className={'bold'}>{row.preparedBy.username ? lodash.get(row, 'preparedBy.username') : '---'}</span>
                            </div>
                            <div>Người xác nhận GD: <span
                                className={'bold'}>{row.approvedBy.username ? lodash.get(row, 'approvedBy.username') : '---'}</span>
                            </div>
                            <div>Người duyệt: <span
                                className={'bold'}>{row.reviewedBy.username ? lodash.get(row, 'reviewedBy.username') : '---'}</span>
                            </div>
                        </div>}>
                            <span><i className="fa-regular fa-users text-secondary-color-2"/></span>
                        </Tooltip>
                    )
                }
            },
            {
                title: 'Nội dung GD',
                key: 'memo',
                dataIndex: 'memo',
                width:'8%',
                render: (memo: any, row: any) => {
                    return (
                        <div style={{minWidth: 100}}>
                            <div>{memo}</div>
                            {row.remark && (
                                <div className={'transaction-remark'}>
                                    <InfoCircleOutlined/> {row.remark}
                                </div>
                            )}
                        </div>
                    )
                },
            },
            {
                title: 'Trạng thái',
                key: 'reviewStatus',
                dataIndex: 'reviewStatus',
                width: '8%',
                align: 'center',
                render: (reviewStatus: string, row: any) => {
                    let status = REVIEW_STATUSES_CODE.find((x) => x.code === reviewStatus)
                    return <div>
                        <span className={`status-${status?.code}`}>{status?.name}</span>
                        {reviewStatus === 'PENDING' && <div className={'underline text-blue font-size-12 pd-t-4'}
                                                            onClick={() => handleReviewTransaction(row)}>
                            Duyệt ngay
                        </div>}
                    </div>
                }
            },
            {
                title: 'Số tiền/Số dư/Số dư từ SMS',
                key: 'amount-balance-refBalance',
                align: 'right',
                width: '15%',
                render: (row: any) => {
                    const ACCOUNT = Utils.getAppContext(this).state.account;
                    const CURRENCY = lodash.get(ACCOUNT, 'currency.code');
                    const showWarningIcon = row.refBalance !== null && row.balance !== row.refBalance;

                    return (
                        <div className={'flex flex-col'}>
                            <Tooltip title={'Số tiền'} placement={'bottom'}>
                                <span
                                    className={`bold fsz-16px ${row.amount < 0 ? 'negative-money' : 'positive-money'}`}>{Utils.currencyFormatByCurrency(row.amount, CURRENCY)}</span>
                            </Tooltip>
                            <Tooltip title={'Số dư'} placement={'bottom'}>
                                <span
                                    className={`bold fsz-16px ${row.balance < 0 ? 'negative-money' : 'positive-money'}`}>{Utils.currencyFormatByCurrency(row.balance, CURRENCY)}</span>
                            </Tooltip>
                            {account.type === 'BANK' && <Tooltip title={'Số dư từ SMS'} placement={'bottom'}>
                                <div className={''}>
                                    {showWarningIcon && (<span className={'pd-l-36 pd-r-8'}>
                                    <WarningTwoTone twoToneColor={'#eb2f96'}/></span>)}
                                    <span
                                        className={`bold fsz-16px relative ${row.refBalance < 0 ? 'negative-money' : row.refBalance > 0 ? 'positive-money' : ''}`}>
                                        {row.refBalance !== null ? Utils.currencyFormatByCurrency(row.refBalance, CURRENCY) : '--'}
                                    </span>
                                </div>
                            </Tooltip>}
                        </div>
                    )
                }
            },
        ]

        columns.push(
            {
                title: 'GD đã xử lý',
                key: 'recharge',
                align: 'center',
                width: '7%',
                render: (row: any) => {
                    return (<>
                        <Checkbox
                            checked={row.recharged}
                            disabled={row.recharged}
                            onChange={() => this.props.handleMarkRechargeTransaction(row)}
                        />
                    </>)
                },
            },
            {
                title: '',
                key: 'log',
                width: '6%',
                align: 'center',
                render: (row: any) => {
                    return (
                        <Button
                            type="link"
                            className="text-blue font-size-12 underline"
                            onClick={() => this.openLogModal(row)}
                        >
                            <i className="fa-regular fa-file-lines pd-r-4"/> Log
                        </Button>
                    )
                },
            }, {
                title: '',
                key: 'actions',
                align: 'center',
                render: (row: any) => {
                    const showMenuRetry =
                        (row.amount > 0 && !row.referenceTx) ||
                        (!row.referenceTx && row.amount < 0 && (row.receiptVoucher || row.paymentVoucher))

                    const showMenuEdit = row.type !== 'INITIAL' && !row.referenceTx

                    return (
                        <div className="flex-col flex items-center actions" onClick={(e) => e.stopPropagation()}>
                            {showMenuRetry && (
                                <Tooltip title="Đồng bộ lại" placement="left" color={'#FDB924'}>
                                    <Button
                                        onClick={this.handleRetrySynchronize.bind(this, row.code)}
                                        type="primary"
                                        className="mg-bt-8 yellow-btn icon-btn"
                                    >
                                        <i className="fa-solid fa-rotate-left"/>
                                    </Button>
                                </Tooltip>
                            )}

                            {showMenuEdit && (
                                <Tooltip title="Cập nhật giao dịch" placement="left" color="#4DD69C">
                                    <Button
                                        className={'mg-bt-8 light-green-btn icon-btn'}
                                        onClick={() => this.handleEditTransactionModalVisible(row)}
                                        type="primary"
                                    >
                                        <i className="fa-solid fa-pen-to-square"/>
                                    </Button>
                                </Tooltip>
                            )}

                            <Tooltip title="Cập nhật ghi chú" placement="left" color="#3340B6">
                                <Button
                                    className={'blue-btn icon-btn mg-bt-8'}
                                    type="primary"
                                    onClick={() => this.handleEditTransactionNoteModalVisible(row)}
                                >
                                    <i className="fa-solid fa-note"/>
                                </Button>
                            </Tooltip>
                            {!row.hasSubTransaction &&
                            <Tooltip title="Tạo giao dịch con" placement="left" color="#339DFF">
                                <Button
                                    className={'blue-btn icon-btn'}
                                    type="primary"
                                    onClick={() => this.handleCreateSubTransactionModalVisible(row)}
                                >
                                    <i className="fa-solid fa-plus"/>
                                </Button>
                            </Tooltip>
                            }

                            {SecurityService.can(PERMISSIONS.TRANSACTION_LOG_VIEW) && (
                                <Tooltip title="Xem log" placement="left" color="#339dff">
                                    <Button
                                        type="primary"
                                        className={'light-blue-btn icon-btn mg-t-8'}
                                        onClick={() => this.openLogModal(row)}
                                    >
                                        <i className="fa-solid fa-file-lines"/>
                                    </Button>
                                </Tooltip>
                            )}
                        </div>
                    )
                },
            })

        return columns
    }

    handleRetrySynchronize = (transactionCode: string) => {
        this.props.onRetrySynchronize(transactionCode)
    }

    handleChangePage = (page: number, pageSize?: number) => {
        this.props.onChangePage(page, pageSize)
    }

    handleEditTransactionModalVisible = (record: TransactionsDomain) => {
        this.props.handleCurrentTransactionEdit(record)
        this.props.handleModalEditTransactionVisibleChange(true)
    }

    handleEditTransactionNoteModalVisible = (record: TransactionsDomain) => {
        this.props.handleCurrentTransactionEdit(record)
        this.props.handleModalEditTransactionNoteVisibleChange(true)
    }

    handleCreateSubTransactionModalVisible = (record: TransactionsDomain) => {
        SubTransaction.getSubTransactions(record.code).then((response) => {
            if (response.data && response.data.length === 0) {
                this.props.handleCurrentTransactionEdit(record)
                this.props.handleModalCreateSubTransactionVisibleChange(true)
            } else {
                NotificationCommon.warning({
                    message: 'Giao dịch đã tồn tại giao dịch con'
                })
            }
        }).catch(() => {})
    }

    openLogModal = (record: TransactionsDomain) => {
        const {account} = this.state

        this.logRef.current?.setLogQuery({
            account: account.code,
            resourceType: LogResrouceType.TRANSACTION,
            resourceCode: record.code,
        })
    }

    openDrawerList = (row: TransactionsDomain) => {
        this.setState({
            selectedRow: row,
            showTransactionsByUser: true
        })
    }

    onCloseDrawerList = () => {
        this.setState({
            selectedRow: undefined,
            showTransactionsByUser: false
        })
    }

    getPaymentReasons = () => {
        let query = {
            offset: 0,
            limit: 100,
        }
        PaymentReasonApi.filter(query)
            .then(response => {
                this.setState({paymentReasons: response.data});
            }).catch((err) => console.log(err.response))
    }


    render() {
        const {loading, total, pageSize, items, currentPage} = this.props
        const {showTransactionsByUser, selectedRow} = this.state
        const searchParams = Utils.getQueryFromLocation(window.location);
        let expandedListKey:any[] = [];
        if(items.length > 0 ) {
            if (searchParams.query || searchParams.paymentReason) {
                items.forEach((i) => {
                    if (i.hasSubTransaction) expandedListKey.push(i['code']);
                })

            }
        }
        let rowsExpanded:any = [];
        const configExpandableOption: ExpandableConfig<any> = {
            expandIcon: ({expanded, onExpand, record}) => (<>
                    {record.hasSubTransaction && <div onClick={(e) => onExpand(record, e)}>
                        {expanded ? <i className="fa-solid fa-angle-up font-size-12"/> : <i className="fa-solid fa-angle-right font-size-12"/>}
                    </div>}
                </>
            ),
            expandedRowRender: (record) => {
                return (<SubTransactionViewList record={record} paymentReasons={this.state.paymentReasons}
                                                fetchTransactions={this.props.fetchTransactions}/>)
            },
            onExpandedRowsChange: (expandedKeys) => {rowsExpanded = expandedKeys},
            expandedRowClassName:(() => 'active-row-key'),
            rowExpandable: (record => record.hasSubTransaction),
            columnWidth: '1%',
            defaultExpandedRowKeys:expandedListKey,
            expandRowByClick:true,
        }

        return (<>
                    <CommonTable
                        columns={this.getConfigColumns()}
                        dataSource={items}
                        loading={loading}
                        rowKey={(record) => record.code}
                        pagination={{
                            current: currentPage,
                            total: total,
                            pageSize: pageSize,
                            hideOnSinglePage: true,
                            pageSizeOptions: [10, 20, 25, 50, 100],
                            onChange: this.handleChangePage,
                            onShowSizeChange: this.handleChangePage,
                        }}
                        expandable={configExpandableOption}
                        scroll={{x:1024}}
                        rowClassName={(record) =>
                            lodash.includes(rowsExpanded, record.code) ? 'active-row-key' : 'data-row'
                        }
                    />
                <LogModal
                    ref={this.logRef}
                    title="Log lịch sử giao dịch"
                    ListItemContent={ListItem}
                    isShowResourceCodeOnTitle={true}
                />
                <TransactionListByUser
                    visible={showTransactionsByUser}
                    onClose={this.onCloseDrawerList}
                    record={selectedRow}
                />
            </>
        )
    }
}

export default DataList
