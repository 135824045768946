import {LogQueryCriteria} from "../../QueryCriteria/LogQueryCriteria";
import React, {FC} from "react";
import {ChangedValuesInterface} from "../../Interface/LogInterface";
import {get} from "lodash";
import {List} from "antd";
import moment from "moment/moment";
import {LogItemInterface} from "./type";

interface LogItemProps {
    item: any
    index: number
    logQuery?: LogQueryCriteria
    ListItemContent: FC<LogItemInterface>
}

export const LogItem: FC<LogItemProps> = ({item, index, logQuery, ListItemContent}) => {
    const changedValues: ChangedValuesInterface[] = get(item, "change.valueChange.changedValues", []) || []
    const newObject: any = get(item, 'change.newObject', []) || []

    if (item.change.type === 'PROPERTY_CHANGE' && changedValues.length < 1) {
        return null
    }

    return (
        <List.Item key={index}>
            <div className="width-100pc">
                <span>
                    <span className={"text-secondary-color-2"}>Thời gian:</span>{" "}
                    <span>{moment(item.timestamp).format("DD/MM/YYYY HH:mm:ss")}</span>
                    <span>
                        <span className={"pd-l-12 text-secondary-color-2"}>Username:</span>{" "}
                        <span className={"font-medium"}>{item.actor?.username}</span>
                    </span>
                </span>

                <div>
                    {logQuery && (
                        <ListItemContent item={item} index={index} logQuery={logQuery}
                                         changedValues={changedValues} newObject={newObject[0] || {}}/>
                    )}
                </div>
            </div>
        </List.Item>
    )
}