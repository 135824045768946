import {Badge, Button, Col, Empty, Pagination, Row, Skeleton, Tooltip} from "antd";
import queryString from "query-string";
import React from "react";
import AccountApi from "../../Api/AccountApi";
import DefaultComponent from "../../Component/DefaultComponent";
import DefaultLayout from "../../Component/Layout/Default";
import {PageProps, PageState} from "../../Interface/PageInterface";
import Utils from "../../Util/Utils";
import Filter from "./components/Filter";
import Item from "./components/Item";
import Create from "./Create";
import Update from "./Update";
import SecurityService from "../../Util/SecurityService";
import {PERMISSIONS} from "../../Util/Constants";
import {DataList} from "./components/DataList";
import localStore from "../../Util/LocalStore";

class Account extends DefaultComponent<PageProps, PageState> {

    state = {
        isShowModalUpdate: false,
        accountCodeSelected: "",
        accounts: [],
        loading: false,
        pagination: {
            pageCount: 0,
            current: 1,
            pageSize: 0,
            total: 0
        },
        isShowModalCreate: false,
        filter: {},
        viewType:'cards',
    };

    componentDidUpdate(prevProps: any) {
        if (
            JSON.stringify(this.props.location.search) !== JSON.stringify(prevProps.location.search)
            || JSON.stringify(this.props.match.params) !== JSON.stringify(prevProps.match.params)
        ) {
            this.getData({
                ...this.getQueryFromLocation()
            }).then();
            this.setState({filter:{...this.getQueryFromLocation()}})
        }
    }

    componentDidMount() {
        this.getData({...this.getQueryFromLocation()}).then();
        this.setState({filter:{...this.getQueryFromLocation()}})
        this.handleInitViewList();
    }

    showModalUpdate = (code: any) => {
        this.setState({
            accountCodeSelected: code,
            isShowModalUpdate: true
        });
    };

    handleModalUpdateVisibleChange = (visible: boolean) => {
        this.setState({
            isShowModalUpdate: visible
        });

        if (!visible) {
            this.setState({accountCodeSelected: ""});
        }
    };

    onModalCreateVisibleChange = (visible: boolean) => {
        this.setState({
            isShowModalCreate: visible
        })
    };

    showModalCreate = () => {
        this.setState({
            isShowModalCreate: true
        })
    };

    onCreateSuccess = () => {
        this.getData({}).then();
    }

    onUpdateSuccess = () => {
        this.getData({}).then();
        this.handleModalUpdateVisibleChange(false);
    }

    getData = async (filter: any) => {
        this.setState({loading: true})
        try {
            const defaultFilter = {
                sort: "name:ASC",
                limit: 24,
                offset: (filter.page || 0) * 24,
                ...filter,
            }
            const accounts = await AccountApi.filter({...defaultFilter, ...filter})
            this.setState({
                accounts: accounts.data,
                pagination: {
                    pageCount: accounts.headers["x-page-count"],
                    current: parseInt(accounts.headers["x-page-number"]) + 1,
                    pageSize: accounts.headers["x-page-size"],
                    total: accounts.headers["x-total-count"]
                }
            })
        } catch (e) {}
        this.setState({loading: false})
    }

    onChangeFilter = (filter: {}) => {
        this.getData(filter).then();
        let pathname = this.props.location.pathname ? this.props.location.pathname : this.props.history.location.pathname;
        this.props.history.push(pathname + "?" + queryString.stringify(Utils.getFilter(filter)));
    }

    onChangePage = (page: number, pageSize: number) => {
        this.onChangeFilter({
            ...this.getQueryFromLocation(),
            page: page - 1,
            pageSize,
            offset: (page - 1) * pageSize,
            limit: pageSize
        })
    }

    handleChangeViewType = () => {
        localStore.setItem('viewType',this.state.viewType);
        if(this.state.viewType === 'cards') {
            this.setState({viewType:'list'});
            localStore.setItem('viewType','list');
        } else {
            this.setState({viewType:'cards'});
            localStore.setItem('viewType','cards');
        }
    }

    handleInitViewList = () => {
        let initViewType = localStore.getItem('viewType')
        if(initViewType) {
            this.setState({viewType:initViewType})
        }
    }

    render(): React.ReactNode {
        const {isShowModalUpdate, accountCodeSelected, pagination, accounts, isShowModalCreate, filter, loading} = this.state;
        return <DefaultLayout
            {...this.props}
            title={`Danh sách tài khoản(${pagination.total})`}
            breadcrumb={[{title: 'Tài khoản quỹ'}]}
        >
            <div className={"h-100pc relative"}>
                <div className={"pd-12 pd-bt-0"}>
                    <Filter
                        filter={filter}
                        onFilter={this.onChangeFilter}
                    />
                    <div className="pd-12 account-list-header">
                        <div className={'left-side'}>
                             <span className={'font-medium fsz-16px capitalize'}>
                                Danh sách tài khoản <Badge count={pagination.total} showZero overflowCount={999999}/>
                            </span>
                            <span className={'switch'}>
                                <Tooltip title={'Danh sách dạng thẻ'}>
                                    <span className={this.state.viewType === 'cards' ? 'selected' : ''} onClick={this.handleChangeViewType}>
                                        <i className="fa-regular fa-table-cells"/>
                                    </span>
                                </Tooltip>
                                <Tooltip title={'Danh sách dạng bảng'}>
                                    <span className={this.state.viewType === 'list' ? 'selected' : ''} onClick={this.handleChangeViewType}>
                                        <i className="fa-regular fa-table-list"/>
                                    </span>
                                </Tooltip>
                            </span>
                        </div>

                        {SecurityService.can(PERMISSIONS.ACCOUNT_CREATE) && (
                            <Button
                                type={'primary'}
                                onClick={this.showModalCreate}
                                icon={<i className="fa-solid fa-plus pd-r-8"/>}
                            >
                                Tạo tài khoản quỹ
                            </Button>
                        )}
                    </div>
                        {this.state.viewType === "cards" && <>
                            <Row gutter={12} className="mg-t-8">
                                {
                                    accounts.length < 1 &&!loading && <Col span={24} className={'flex justify-content-center pd-t-12'}>
                                        <Empty/>
                                    </Col>
                                }
                                {loading ? [...Array(12)].map((_, index: number) => (
                                    <Col key={`ske-${index}`} sm={12} xs={24} md={12} lg={8} xl={6}>
                                        <Skeleton active />
                                    </Col>
                                )) : (
                                    accounts.map((item: any, index) => {
                                        return <Col key={index} sm={12} xs={24} md={12} lg={8} xl={6}>
                                            <Item
                                                {...item}
                                                showAccount={this.showModalUpdate}
                                            />
                                        </Col>
                                    })
                                )}
                            </Row>
                            <div className={"text-right pd-bt-24"}>
                                <Pagination
                                    {...pagination}
                                    pageSize={pagination.pageSize ? Number(pagination.pageSize) : 24}
                                    pageSizeOptions={[10, 20, 24, 50, 100]}
                                    hideOnSinglePage
                                    onChange={this.onChangePage}
                                    onShowSizeChange={this.onChangePage}
                                />
                            </div>
                        </>}
                        {this.state.viewType === 'list' && <>
                            <DataList dataSources={accounts} pagination={pagination} onChangePage={this.onChangePage} showAccount={this.showModalUpdate} loading={loading}/>
                        </>}
                </div>
            </div>
            {isShowModalCreate && (
                <Create
                    onVisibleChange={this.onModalCreateVisibleChange}
                    onCreateSuccess={this.onCreateSuccess}
                />
            )}
            {isShowModalUpdate && (
                <Update
                    code={accountCodeSelected}
                    onUpdateSuccess={this.onUpdateSuccess}
                    onVisibleChange={this.handleModalUpdateVisibleChange}
                />
            )}
        </DefaultLayout>
    }
}

export default Account;
