import {Badge} from "antd";
import lodash from "lodash";
import React from 'react';
import DefaultComponent from "../../../Component/DefaultComponent";
import DefaultLayout from "../../../Component/Layout/Default";
import Utils from "../../../Util/Utils";
import DataList from "./DataList";
import FilterForm from "./FilterForm";
import UserDomain from "../../../Domain/UserDomain";
import StaffApi from "../../../Api/StaffApi";

interface State {
    loading: boolean,
    staffs: Array<UserDomain>,
    total: number,
    pageSize: number,
    currentPage: number,
    collapsedFilter: boolean,
}

class StaffList extends DefaultComponent<any, State> {

    state: State = {
        loading: false,
        staffs: [],
        pageSize: 25,
        total: 0,
        currentPage: 1,
        collapsedFilter: true,
    }

    componentDidMount() {
        this.fetchStaffs({
            ...this.getQueryFromLocation()
        })
    }

    componentDidUpdate(prevProps: any) {
        if (
            JSON.stringify(this.props.location.search) !== JSON.stringify(prevProps.location.search)
            || JSON.stringify(this.props.match.params) !== JSON.stringify(prevProps.match.params)
        ) {
            this.fetchStaffs({
                ...this.getQueryFromLocation()
            });
            this.setState({
                pageSize:this.getQueryFromLocation().limit,
                currentPage:this.getQueryFromLocation().page,
            })
        }
    }

    fetchStaffs = (query = {}) => {
        this.setState({loading: true});
        const filter = {
            sort: 'createdAt:desc',
            ...query
        };
        StaffApi.getStaffs(filter)
            .then(response => {
                this.setState({
                    staffs: response.data,
                    currentPage: parseInt(lodash.get(response, 'headers.x-page-number')) + 1,
                    pageSize: parseInt(lodash.get(response, 'headers.x-page-size')),
                    total: parseInt(lodash.get(response, 'headers.x-total-count')),
                });
            })
            .finally(() => {
                this.setState({loading: false});
            })
    }

    onChangePage = (page: number, pageSize: any) => {
        this.pushCleanQueryToHistory({
            ...this.getQueryFromLocation(),
            page: page,
            limit: pageSize,
            offset: (page - 1) * pageSize
        })
    }

    handleCollapseFilter = () => {
        this.setState({collapsedFilter: !this.state.collapsedFilter})
    }

    render() {
        const {staffs, currentPage, pageSize, total, loading} = this.state;
        return (
            <DefaultLayout
                title={"Danh sách nhân viên"}
                {...this.props}
                breadcrumb={[{title:'Danh Sách Nhân Viên'}]}
            >
                <div className="main-content">
                    <div className="white-box staffs-container">
                        <div className="staffs-container__header" onClick={this.handleCollapseFilter}>
                            <span className={'capitalize'}>Tìm kiếm</span>
                            <span>{this.state.collapsedFilter ? <i className="fa-solid fa-angle-down"/> :
                                <i className="fa-solid fa-angle-up"/>}</span>
                        </div>
                        {!this.state.collapsedFilter && <div className={'staffs-container__filters'}>
                            <FilterForm
                                {...this.props}
                            />
                        </div>}
                    </div>
                    <div className="white-box staffs-container__data">
                        <div className={'mg-bt-12'}>
                             <span className={'capitalize font-size-16'}>Danh sách nhân viên</span>
                            <Badge className={'mg-l-6'} count={Utils.currencyFormat(total)} overflowCount={99}/>
                        </div>
                        <DataList
                            loading={loading}
                            items={staffs}
                            total={total}
                            pageSize={pageSize}
                            currentPage={currentPage}
                            onChangePage={this.onChangePage}
                        />
                    </div>
                </div>
            </DefaultLayout>
        );
    }
}

export default StaffList;
